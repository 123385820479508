@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.hiring-request-detail {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  padding: 20px;

  h2 {
    color:  var(--primary-color);
    margin-bottom: 20px;
  }

  .detail-section {
    background-color: lighten($accent-color, 15%);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;

    h3 {
      color:  var(--primary-color);
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;

      strong {
        font-weight: bold;
        color: darken($text-color, 20%);
      }
    }
  }

  @include mobile {
    padding: 10px;

    .detail-section {
      padding: 15px;
    }
  }

  .button-container {
    margin-top: 20px;
    text-align: center;

    .back-button {
      background-color: var(--primary-color);
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        opacity: 0.9;
      }

      @include mobile {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}

