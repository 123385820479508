@import "/src/components/Variables.scss";
@import "/src/styles/breakpoints.scss";


.ShareTrackingBoardModal {


  &__title {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 1px solid $border-color;
  }

  &__email-content {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    input {
      width: 50%;
      padding: 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid $border-color;
    }

    button {
      background-color: var(--primary-color);
      color: var(--background-color);
      width: 50%;
      margin: 0 auto;
      padding: 0.5rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  }

  &__share-peopleContent {
    padding: 1rem;
    margin-top: 1rem;
    height: 15rem;
    overflow-y: scroll;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &-title {
      font-size: 1.5rem;
      font-weight: bold;
      border-bottom: 1px solid $border-color;
    }
    
    &-people {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      margin-top: 1rem;


      &-item {
        display: flex;
        justify-content: space-around;
        gap: 1rem;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 0.5rem;

        .ShareTrackingBoardModal__share-peopleContent-people-item__name {
          margin-bottom: 0;
        }

        &__button {
          background-color: var(--primary-color);
          color: var(--background-color);
          padding: 0.3rem;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: background-color 0.3s;
          max-height: 90%;
        }
      }
    }
  }
}