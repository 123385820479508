.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .spinner-text {
    margin-top: 1rem;
    color: var(--text-primary);
    font-weight: 500;
  }

  &.small .spinner {
    width: 30px;
    height: 30px;
    border-width: 3px;
  }

  &.large .spinner {
    width: 70px;
    height: 70px;
    border-width: 6px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 