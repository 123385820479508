@import "../../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.ai-phone-interview-details {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;

  .interview-details-header {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;

    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin: 0;
    }

    .back-button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 20px;
      background-color: transparent;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--primary-color);
        color: white;
      }
    }

    button {
      background-color: var(--secondary-color);
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--primary-hover-color);
      }
    }
  }

  .interview-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .interview-info,
    .questions-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin-bottom: 30px;

      label {
        font-weight: 600;
        color: $text-color;
        margin-bottom: 8px;
      }

      input,
      textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        font-size: 1em;
        color: $text-color;
        background-color: lighten($background-color, 10%);
        transition: border-color 0.3s ease;

        &:focus {
          border-color: var(--secondary-color);
          outline: none;
        }
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }
    }

    .questions-section {
      grid-template-columns: 1fr;
      margin-top: 20px;

      h3 {
        color: var(--primary-color);
        margin-bottom: 20px;
      }

      .questions-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .question-item {
          display: flex;
          gap: 15px;
          padding: 15px;
          background-color: #f9f9f9;
          border-radius: 6px;

          .question-number {
            font-weight: 600;
            color: var(--primary-color);
            min-width: 40px;
          }

          .question-text {
            margin: 0;
            flex: 1;
          }
        }
      }
    }
  }

  .aiPhone-save-button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 15px 0;
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--secondary-color);
    }

    svg {
      font-size: 1.2em;
    }
  }

  .loading,
  .not-found {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
  }

  @include tablet {
    padding: 30px 15px;

    .interview-details-header h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .interview-details-header h2 {
      font-size: 1.6em;
    }
  }
}
