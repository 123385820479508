@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.candidate-pipeline-details {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 20px;

  .back-button {
    background-color: #f0f0f0;
    color:  var(--primary-color);
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  .candidate-header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;

    h2 {
      font-size: 2em;
      color:  var(--primary-color);
      margin: 0 0 8px 0;
    }

    .position-badge, .stage-badge {
      display: inline-block;
      padding: 4px 8px;
      margin-right: 8px;
      border-radius: 4px;
      font-size: 14px;
    }

    .position-badge {
      background-color: #e3f2fd;
      color: #1976d2;
    }

    .stage-badge {
      background-color: #e8f5e9;
      color: #2e7d32;
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 30px;

    .detail-section {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);

      &.full-width {
        grid-column: 1 / -1;
      }

      h3 {
        color:  var(--primary-color);
        margin-top: 0;
        margin-bottom: 16px;
      }
    }
  }

  .info-group {
    p {
      margin-bottom: 10px;
      
      strong {
        color:  var(--primary-color);
      }
    }

    .no-contact-info {
      color: darken($accent-color, 20%);
      font-style: italic;
    }

    .linkedin-link {
      color: var(--secondary-color); 
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .no-data {
    color: darken($accent-color, 20%);
    font-style: italic;
    padding: 10px 0;
  }

  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .skill-badge {
      background-color:  var(--primary-color);
      color: $background-color;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 0.9em;
    }
  }

  .documents-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .document-card {
      display: flex;
      align-items: center;
      background-color: $background-color;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .document-icon {
        font-size: 1.5em;
        margin-right: 10px;
      }

      .document-info {
        flex-grow: 1;

        .document-name {
          font-weight: bold;
          color:  var(--primary-color);
        }

        .document-type, .document-date {
          font-size: 0.8em;
          color: darken($accent-color, 20%);
        }
      }

      .download-btn {
        background-color: var(--secondary-color);
        color: $background-color;
        border: none;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #333333;
        }
      }
    }
  }

  .notes-container {
    background-color: $background-color;
    padding: 15px;
    border-radius: 5px;
    min-height: 100px;
  }

  .action-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      
      &.primary-btn {
        background-color: #1976d2;
        color: white;
      }
      
      &.secondary-btn {
        background-color: #f5f5f5;
        color: #333;
      }
      
      &.success-btn {
        background-color: #4CAF50;
        color: white;
        &:hover {
          background-color: #45a049;
        }
      }
      
      &.danger-btn {
        background-color: #f44336;
        color: white;
        &:hover {
          background-color: #da190b;
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .details-grid {
      grid-template-columns: 1fr;
    }

    .candidate-header {
      h2 {
        font-size: 1.8em;
      }
    }
  }

  @include mobile {
    padding: 20px 10px;

    .candidate-header {
      h2 {
        font-size: 1.6em;
      }
    }

    .action-buttons {
      flex-direction: column;
      
      button {
        width: 100%;
      }
    }
  }
}
