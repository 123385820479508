@import '../../Variables.scss';
@import '../../../styles/breakpoints.scss';

.dashboard-header {
  background-color: var(--primary-color);
  padding: 1rem;
  z-index: 99;


  .header-left {
    display: flex;
    align-items: center;
    gap: 25px;  
  }

  .logo-container {
    display: flex;
    align-items: center;
      
    
  }

  .company-logo-circle {
    width: 50px;
    height: 50px;
   
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
  }
  
  nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  .menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    .menu-item {
        color: $background-color;
      position: relative;
      margin-right: 1rem;

      @include mobile {
        margin-bottom: 0.5rem;
        width: 100%;
      }

      &.has-dropdown {
        .menu-link {
          color: $background-color;
          padding-right: 1.5rem;
          position: relative;
           

          &::after {
            content: '▼';
            
            font-size: 0.7em;
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .menu-link {
        color: $background-color;
        text-decoration: none;
        font-weight: 600;
        padding: 0.5rem 1rem;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 1rem;
        display: inline-block;
        font-family: montserrat;
        color: inherit;
        white-space: nowrap;

        &:hover {
          color: $accent-color;
        }
      }

      &.talexa .menu-link {
        color: var(--secondary-color);
        
        
        &:hover {
          color: #333333(#808080, 10%);
        }
      }

      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $background-color;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        display: none;
        z-index: 10;
        min-width: 200px;
        overflow: hidden; // This will prevent the hover effect from overflowing

        &.active {
          display: block;
        }

        .dropdown-item {
          color: $text-color;
          padding: 0.5rem 1rem;
          display: block;
          white-space: nowrap;
          width: 100%;
          text-align: left;
          text-decoration: none;
          border: none;
          background: none;
          cursor: pointer;
          font-size: 1rem;

          &:hover {
            background-color: $accent-color;
            color: $text-color; // Utilisation de $text-color pour la couleur du texte au survol
          }

          &.disabled {
            color: #6c757d;
            cursor: not-allowed;
            opacity: 0.65;
            text-decoration: none;
            background-color: transparent;
          }
        }
      }
    }

    .home-icon {
      margin-right: 1.5rem;
      
      .menu-link {
        padding: 0.5rem;
        display: flex;
        align-items: center;
      }

      .dashboard-home-icon {
        font-size: 1.5rem;
        color: $background-color;
        
        &:hover {
          color: $accent-color;
        }
      }
    }
  }
}


 