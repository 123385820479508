@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.scheduled-interviews {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  border-radius: 8px;
  h2 {
    font-size: 2em;
    color: var(--primary-color);
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.1em;
    color: var(--primary-color);
    margin: 20px 0px;
  }

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); // Effet d'ombre
    transform: translateY(-2px); // Légère remontée
    transition: all 0.3s ease-in-out; // Animation fluide
  }

  .interviews-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid $accent-color;

      &:last-child {
        text-align: center;
      }
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    a {
      color: var(--primary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .actions-column {
      text-align: center;

      .action-select {
        padding: 5px 10px;
        border: 1px solid $accent-color;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
        font-size: 0.9em;
        min-width: 150px;

        &:hover {
          border-color: var(--primary-color);
        }

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(#808080, 0.2);
        }

        option {
          padding: 5px;

          &[value="cancelled"] {
            color: $error-color;
          }

          &[value="completed"] {
            color: $success-color;
          }

          &[value="default"] {
            color: $text-color;
          }
        }
      }
    }
  }

  .scheduled-interviews-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .add-interview-button {
      padding: 10px 20px;
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 600;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgb(54, 53, 53);
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    .interviews-table {
      font-size: 0.9em;

      th,
      td {
        padding: 10px;
      }
    }
  }
}
