@import './Variables.scss';
@import '../styles/breakpoints.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');

.e-learning-videos {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background: linear-gradient(to bottom, $background-color, darken($background-color, 10%));
  padding: 50px 0;

  .header-section {
    margin-bottom: 50px;
    text-align: center;

    h2 {
      font-size: 2.5em;
      color: var(--primary-color);
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      font-family: 'Montserrat', sans-serif;
    }
  }

  .video-section {
    margin-bottom: 90px;
    position: relative;

    .subsection {
      margin-bottom: 40px;
      width: 100%;

      h4 {
        font-size: 1.5em;
        color: var(--primary-color);
        margin-bottom: 20px;
        padding-left: 20px;
        border-left: 4px solid var(--primary-color);
      }

      .subsection-videos {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 30px;
        
        @include tablet {
          grid-template-columns: repeat(2, minmax(250px, 1fr));
          gap: 15px;
        }

        @include mobile {
          grid-template-columns: 1fr;
          gap: 10px;
        }
      }
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 20px;
      background: rgba(240, 240, 240, 0.582);
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: -1;
    }

    .section-header {
      margin-bottom: 20px;
      text-align: center;

      h3 {
        font-size: 1.5em;
        color: rgb(15, 15, 15);
        margin-bottom: 10px;
      }

      p {
        color: $text-color-light;
      }
    }

    .videos-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 30px;
      margin: 0 auto;
      max-width: 1200px;

      @include tablet {
        grid-template-columns: repeat(2, minmax(250px, 1fr));  
        gap: 15px;  
      }

      @include mobile {
        grid-template-columns: 1fr;
        gap: 10px;  
      }
    }
  }

  .video-card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: lighten($accent-color, 15%);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(0.95);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }

    .video-thumbnail {
      position: relative;
      padding-top: 56%;
      background-color: darken($accent-color, 10%);
      border-radius: 5px;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .video-info {
      padding: 15px;

      h4 {
        font-size: 1.3em;
        font-weight: bold;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 15px;

        @include mobile {
          font-size: 1.2em;  
        }
      }

      p {
        font-size: 1em;
        color: lighten($text-color, 10%);
        text-align: center;

        @include mobile {
          font-size: 0.9em;  
        }
      }
    }
  }
}
