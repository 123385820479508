@import "../../../Variables.scss";
@import "../../../../styles/breakpoints.scss";

.mock-interview-page {
  background-color: $background-color;
  color: $text-color;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: "Montserrat", sans-serif;

  &__header {
    margin-bottom: 30px;
    text-align: center;

    h2 {
      font-size: 2.2em;
      color: $primary-color;
      margin: 0 0 10px 0;
      font-weight: 700;
    }

    .mock-interview-page__job-role {
      color: $secondary-color;
      font-size: 1.2em;
      margin: 0;
      font-weight: 500;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__qa-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &__question {
    margin-bottom: 20px;
    display: flex;
    gap: 12px;
    align-items: flex-start;

    .question-number {
      color: $primary-color;
      font-weight: 700;
      font-size: 1.1em;
      min-width: 35px;
    }

    .question-text {
      color: $text-color;
      font-size: 1.1em;
      font-weight: 600;
      line-height: 1.4;
    }
  }

  &__answer, &__analysis {
    margin-top: 15px;
    padding-left: 47px;

    .answer-label, .analysis-label {
      display: block;
      color: $secondary-color;
      font-weight: 600;
      margin-bottom: 8px;
      font-size: 0.9em;
      text-transform: uppercase;
    }

    .answer-text, .analysis-text {
      color: $text-color;
      line-height: 1.6;
      margin: 0;
      font-size: 1em;
      background-color: rgba($accent-color, 0.05);
      padding: 12px;
      border-radius: 8px;
    }
  }

  &__analysis {
    .analysis-text {
      background-color: rgba($primary-color, 0.05);
    }
  }

  &__summary-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    border-left: 4px solid $primary-color;

    .summary-title {
      color: $primary-color;
      font-size: 1.3em;
      margin: 0 0 20px 0;
      font-weight: 600;
    }

    .summary-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .analysis-item {
      background-color: rgba($primary-color, 0.05);
      padding: 15px;
      border-radius: 8px;
      
      .analysis-text {
        color: $text-color;
        line-height: 1.6;
        margin: 0;
        font-size: 1em;
      }
    }
  }

  &__no-content {
    text-align: center;
    color: $secondary-color;
    font-style: italic;
    padding: 40px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    border: 1px dashed rgba($secondary-color, 0.3);
  }

  &__back-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    background-color: $primary-color;
    border: none;
    color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $primary-color;
    }
  }

  // Message d'erreur (si tu veux styliser .error-message ou .not-found)
  .error-message,
  .not-found {
    font-weight: 500;
    text-align: center;
    margin: 20px 0;
    color: #d9534f; // rouge
  }

  /* Breakpoint - tablette */
  @include tablet {
    padding: 30px 15px;

    &__header {
      h2 {
        font-size: 1.8em;
      }
    }
  }

  /* Breakpoint - mobile */
  @include mobile {
    padding: 20px 10px;

    &__header {
      h2 {
        font-size: 1.6em;
      }
    }

    &__content {
      gap: 15px;

      &__qa-card {
        padding: 15px;
      }

      &__question {
        .question-text {
          font-size: 0.95em;
        }
      }
    }

    &__back-button {
      width: 100%;
      padding: 12px;
    }
  }
}
