@import "/src/components/Variables.scss";
@import "/src/styles/breakpoints.scss";

.job-application-details {
  max-height: 100vh;
  overflow-y: auto;
  z-index: 99999999999;

  &__header {
    background-color: $background-color;
    color: $text-color;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;

    &-close {
      background: none;
      border: none;
      color: $text-color;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0.5rem;
      line-height: 0.8;
      
      &:hover {
        color: $danger-color;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__section {
      padding: 1.5rem 2rem;
      max-width: 90%;

      /* Corriger : "color: text-color" => "color: $text-color" */
      &-title {
        margin-bottom: 1rem;
        color: $text-color;
      }

      &__content {
        color: $text-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__item {
          padding: 1rem 0;
          display: flex;
          justify-content: space-between;
          box-sizing: content-box;

          &-text {
            width: 50%;
            word-break: break-all;
          }
        }
      }

      &__buttons-container {
        grid-column: 1/3;
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
        padding: 1rem;

        &__button {
          &-refuse {
            border-radius: 5px;
            font-weight: bold;
            padding: 1rem;
            border: none;
            color: $danger-color;
            width: 50%;

            &:hover {
              background-color: $danger-color;
              color: $background-color;
              cursor: pointer;
            }
          }

          &-add {
            border-radius: 5px;
            font-weight: bold;
            padding: 1rem;
            border: none;
            color: $success-color;
            width: 50%;

            &:hover {
              background-color: $success-color;
              color: $background-color;
              cursor: pointer;
            }
          }

          &-view-interview {
            border-radius: 5px;
            font-weight: bold;
            padding: 1rem;
            border: none;
            width: 50%;
            color: white; /* Couleur du texte */
            background-color: $primary-color;

            &:hover {
              background-color: $primary-color;
              color: $background-color;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.modal-ReceivedApplications {
  &-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &-refuseCandidate {
    background-color: $background-color;
    padding: 2rem;
    border-radius: 8px;
    width: 95%;
    max-width: 800px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: $text-color;
    cursor: pointer;
    padding: 0.5rem;
    line-height: 0.8;
    
    &:hover {
      color: $danger-color;
    }
  }

  &-title {
    color: $text-color;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  &-text {
    color: $text-color;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &-button {
    border-radius: 5px;
    font-weight: bold;
    padding: 1rem 2rem;
    border: none;
    cursor: pointer;
    width: 40%;

    &--confirm {
      color: $background-color;
      background-color: $danger-color;

      &:hover {
        opacity: 0.9;
      }
    }

    &--cancel {
      color: $text-color;
      background-color: transparent;
      border: 1px solid $text-color;

      &:hover {
        background-color: rgba($text-color, 0.1);
      }
    }
  }
}
