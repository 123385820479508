$candidate-purple: #6920b0;
$hover-purple: #8757c7;
$light-gray: #f9f9f9;
$dark-gray: #444;
$white: #ffffff;

.all-emails-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: $light-gray;
  padding: 2rem;
  min-height: 100vh;

  .return-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--primary-color);
    color: $white;
    border: none;
    border-radius: 8px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgb(54, 53, 53);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      color: var(--primary-color);
      font-size: 1.8rem;
    }

    .generate-button {
      padding: 10px 20px;
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgb(54, 53, 53);
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .search-bar {
    width: 98.5%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
      border-color: var(--primary-color);
      box-shadow: 0 0 8px var(--primary-color);
    }
  }

  .email-table {
    width: 100%;
    border-collapse: collapse;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    th {
      background-color: var(--primary-color);
      color: $white;
      padding: 1rem;
      text-align: left;
      text-transform: uppercase;
      font-size: 0.9rem;
    }

    td {
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      color: $dark-gray;

      &:last-child {
        text-align: center;
      }
    }

    tr {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $light-gray;
      }
    }

    .action-select {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 8px;
      cursor: pointer;
      font-size: 0.9rem;
      background-color: $white;

      &:hover {
        border-color: var(--primary-color);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 5px rgba(105, 32, 176, 0.5);
      }
    }
  }
}


// Media Queries
@media (max-width: 1200px) {
  .all-emails-container {
    padding: 1rem;

    .email-table {
      th,
      td {
        padding: 0.8rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .all-emails-container {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      h2 {
        font-size: 1.5rem;
      }
    }

    .search-bar {
      font-size: 0.9rem;
    }

    .email-table {
      font-size: 0.9rem;

      th,
      td {
        padding: 0.6rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .all-emails-container {
    .header {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .email-table {
      display: block;
      overflow-x: auto;

      table {
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .all-emails-container {
    .return-button,
    .add-button {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
    }

    .email-table {
      th,
      td {
        font-size: 0.8rem;
        padding: 0.5rem;
      }
    }
  }
}
