@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.boardButton{
    padding: 10px 40px;
    display: flex;
    justify-content: end;

}
.boardButton button{
    padding: 10px 20px;
    color: #ffffff;
    background-color: #fa41cc;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    &:hover{
        background-color: rgb(248.6923076923, 15.3076923077, 190.6615384615);
    }

}
// CreateBoardForm.scss
.create-board-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    form {
      display: flex;
      flex-direction: column;
      width: 300px;
      padding: 20px;
      background-color: #f4f4f4;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      label {
        margin-bottom: 8px;
        font-weight: bold;
      }

      input {
        padding: 10px;
        margin-bottom: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      button {
        padding: 10px;
        background-color: var(--primary-color);
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    p {
      margin-top: 12px;
      color: green;
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    position: relative;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
  }
