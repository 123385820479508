@import './Variables.scss';
@import '/src/styles/breakpoints.scss';

$primary-color: #6920b0;
$secondary-color: #fa41cc;
$accent-color: #acdaff;
$background-color: #ffff;
$back-color: #787878;
$text-color: #000000;

.job-board-page {
  min-height: 100vh !important;
  font-family: 'Montserrat', sans-serif !important;
  color: $text-color !important;
  background-color: $background-color !important;
  display: flex !important;
  flex-direction: column !important;
}

.header-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    position: relative;

    .logo-container {
      display: flex;
      justify-content: center;
    }

    .logo-link {
      z-index: 2;
      .logo {
        height: 100px !important;
        width: auto !important;
      }
    }

    .nav-links {
      position: absolute;
      right: 1rem;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      .language-selector {
        position: relative;
        display: flex;
        align-items: center;

        .lang-toggle {
          display: flex;
          align-items: center;
          background: none;
          border: none;
          color: $primary-color;
          font-weight: 600;
          cursor: pointer;
          padding: 0.25rem 0.5rem;

          .flag-icon {
            width: 20px;
            height: auto;
            margin-right: 0.5rem;
          }

          &:hover {
            color: $secondary-color;
          }
        }

        .lang-dropdown {
          position: absolute;
          top: 100%;
          right: 0;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          display: none;
          list-style: none;
          padding: 0;
          margin: 0;

          &.open {
            display: block;
          }

          li {
            margin: 0;

            button {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 0.5rem 1rem;
              border: none;
              background: none;
              cursor: pointer;
              color: $primary-color;
              font-weight: 600;

              img {
                width: 20px;
                height: auto;
                margin-right: 0.5rem;
              }

              &:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }
      }
    }
  }

.job-board {
  flex: 1 !important;
  padding: 20px !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  width: 100% !important;

  h1 {
    font-size: 2em !important;
    margin-bottom: 20px !important;
    color: $primary-color !important;
  }

  .job-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  .job-card {
    background-color: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    padding: 20px !important;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    h2 {
      font-size: 1.2em !important;
      margin-bottom: 10px !important;
      color: $primary-color !important;
    }

    p {
      margin-bottom: 5px;
      color: $back-color;
    }

    .company {
      font-weight: bold;
      color: $accent-color;
    }

    .contract-type {
      font-style: italic;
    }

    .location {
      color: $accent-color;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 8px 0;
      
      i {
        color: $primary-color;
        font-size: 1rem;
      }
    }

    .created-at {
      font-size: 0.9em;
      color: $back-color;
    }
  }

  .loading, .error {
    text-align: center;
    font-size: 1.2em;
    margin-top: 50px;
  }

  .search-filters {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    input, select {
      flex: 1;
      min-width: 200px;
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 6px;
      font-size: 14px;
      transition: all 0.3s ease;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: white;
      position: relative;

      &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }

      &::-webkit-input-placeholder {
        color: #999;
      }
    }

    .search-input {
      flex: 2;
    }

    .contract-select {
      min-width: 150px;
      background-color: white;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23666' d='M6 8.825L1.175 4 2.238 2.938 6 6.7l3.763-3.762L10.825 4z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-right: 32px;
    }

    .location-input {
      flex: 1;
    }

    @media (max-width: 768px) {
      input, select {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  .no-jobs-message {
    text-align: center !important;
    font-size: 1.4em !important;
    margin-top: 50px !important;
    padding: 30px !important;
    background-color: #f8f9fa !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    color: $back-color !important;
    border: 1px solid #e9ecef !important;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    &::before {
      content: '\1F50D'; // Loupe emoji
      font-size: 2em;
      display: block;
      margin-bottom: 15px;
    }

    span {
      display: block;
      font-size: 0.9em;
      margin-top: 10px;
      color: $back-color;
    }
  }

  .loading-state,
  .error-state,
  .empty-state {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    padding: 2rem;
    max-width: 600px;
  }

  .loading-state {
    .loading-spinner {
      width: 50px;
      height: 50px;
      border: 4px solid #f3f3f3;
      border-top: 4px solid $primary-color;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin-bottom: 1rem;
    }

    p {
      color: $text-color;
      font-size: 1.1rem;
    }
  }

  .error-state {
    i {
      font-size: 3rem;
      color: $danger-color;
      margin-bottom: 1rem;
    }

    p {
      color: $text-color;
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
    }

    .retry-button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      font-size: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.2s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
        transform: translateY(-1px);
      }
    }
  }

  .empty-state {
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin: 2rem auto;
    padding: 3rem 2rem;

    .empty-state-content {
      max-width: 400px;
      text-align: center;

      .empty-state-icon {
        width: 80px;
        height: 80px;
        background-color: rgba($primary-color, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 1.5rem;

        i {
          font-size: 2.5rem;
          color: $primary-color;
        }
      }

      h2 {
        color: $text-color;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        font-weight: 600;
      }

      p {
        color: $text-color-light;
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
        line-height: 1.5;
      }

      .sub-message {
        font-size: 0.9rem;
        color: $text-color-light;
        margin-bottom: 1.5rem;
      }
    }
  }

  .loading-state,
  .error-state {
    @extend .empty-state;
    text-align: center;
  }

  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid $primary-color;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
