@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.hiring-request-list {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  padding: 20px;

  h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
  }

  .request-title-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .button-group {
      display: flex;
      gap: 1rem;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.3s ease;
        background-color: var(--secondary-color);
        color: white;

        svg {
          font-size: 1.2rem;
        }

        &:hover {
          background-color: var(--text-color);
          color: var(--background-color);
        }
      }

      // Make generate button styles more specific
      button.generate-button {
        background-color: var(--primary-color) !important;
        color: var(--background-color) !important;

        &:hover {
          background-color: var(--text-color) !important;
          color: var(--background-color) !important;
        }

        svg {
          color: #FFD700 !important;
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    background-color: $background-color;

    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid $accent-color;
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    a {
      color:  var(--primary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include mobile {
    padding: 10px;

    table {
      font-size: 0.9em;

      th, td {
        padding: 8px;
      }
    }
  }

  .model-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 1000 !important;
  }
  
  .model-content {
    background-color: #fff !important;
    border-radius: 8px !important;
    max-width: 90% !important;
    max-height: 80vh !important; /* Limit modal height */
    overflow: hidden !important; /* Prevent overflow */
    position: relative !important;
    width: 600px !important; /* Adjust modal width as needed */
  }
  
  .model-scrollable {
    padding: 20px !important;
    max-height: 70vh !important; /* Allow space for padding and close button */
    overflow-y: auto !important; /* Enable vertical scrolling */
  }
  
  .close-model {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    background-color: transparent !important;
    border: none !important;
    font-size: 1.5rem !important;
    cursor: pointer !important;
  }    
  
  button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
}

