@import '../../src/components/Variables.scss';
@import '../styles/breakpoints.scss';

.job-details-page {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  .job-details-container {
    max-width: 1200px;
    margin: 0 auto;

    .back-button {
      background-color: $secondary-color;
      color: $background-color;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 20px;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }
    }

    .job-header {
      margin-bottom: 30px;
      
      h1 {
        font-size: 2em;
        color: var(--primary-color);
        margin-bottom: 20px;
      }

      .job-meta {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;
        background-color: lighten($accent-color, 15%);
        padding: 25px;
        border-radius: 5px;

        .meta-item {
          display: flex;
          align-items: center;
          gap: 12px;
          min-width: 250px;
          white-space: nowrap;

          svg {
            color: var(--primary-color);
            font-size: 1.2em;
            flex-shrink: 0;
          }

          span {
            font-size: 0.95em;
          }
        }
      }
    }

    .job-content {
      section {
        background-color: white;
        padding: 24px;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        h2 {
          color: var(--primary-color);
          font-size: 1.5em;
          margin-bottom: 15px;
          padding-bottom: 10px;
          border-bottom: 1px solid $accent-color;
        }

        p {
          line-height: 1.6;
          margin-bottom: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .skills-list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 15px;

        .skill-tag {
          background-color: lighten($accent-color, 15%);
          color: $text-color;
          padding: 10px 20px;
          border-radius: 25px;
          font-size: 1em;
          font-weight: 500;
          min-width: 120px;
          text-align: center;
          transition: transform 0.2s ease;

          &:hover {
            transform: translateY(-1px);
          }
        }
      }

      .environment-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 15px;

        .environment-tag {
          color: $text-color;
          padding: 10px 25px;
          border-radius: 25px;
          font-size: 0.95em;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: $background-color;
          white-space: nowrap;

          svg {
            color: var(--primary-color);
            font-size: 1.1em;
            flex-shrink: 0;
          }
        }
      }

      .apply-section {
        text-align: center;
        margin-top: 30px;

        .apply-button {
          background-color: var(--primary-color);
          color: white;
          border: none;
          padding: 12px 24px;
          font-size: 1.1em;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken($primary-color, 10%);
          }
        }
      }
    }
  }

  .job-details-loading {
    text-align: center;
    padding: 40px;

    .loading-spinner {
      border: 4px solid lighten($accent-color, 15%);
      border-top: 4px solid var(--primary-color);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto 20px;
    }
  }

  .job-details-error {
    text-align: center;
    padding: 40px;
    
    h2 {
      color: $error-color;
      margin-bottom: 20px;
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @include tablet {
    padding: 30px 15px;

    .job-header h1 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .job-header h1 {
      font-size: 1.6em;
    }

    .job-meta {
      grid-template-columns: 1fr !important;
    }
  }
}
