/* AdminSidebar.scss */

/* Sidebar container styles */
.sidebar-layout {
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #2c3e50; /* Default background color */
  overflow-y: auto;
}

/* Sidebar header styles */
.sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}

.company-logo-circle {
  border-radius: 50%;
  font-size: 20px;
}

.user-icon-container {
  cursor: pointer;
}

.user-icon {
  font-size: 25px;
}

/* Sidebar item styles */
.sidebar-tabs {
  flex-grow: 1; /* Allow this part to fill the remaining space */
  padding: 30px 20px 0 20px;
}

.sidebar-icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar-item:hover {
  background-color: #34495e; /* Hover color */
  border-radius: 5px;
}
.sidebar-item span {
  font-size: 1rem;
  text-decoration: none !important;
}
.sidebar-link {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  cursor: pointer;
  color: white;
  text-decoration: none;

  .sidebar-item:active{
    background-color: #2c3e50;
  }

  &.active {
    background-color: #2c3e50; 
    color: #fff; 
  }
}

.sidebar-footer {
  position: sticky;
  bottom: 0;
  z-index: 100; 
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1); 
}

/* User modal styles */
.user-modal {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e74c3c;
  padding: 10px;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-modal span {
  margin-left: 5px;
}

/* Logo container styles */
.logo-container {
  margin: auto;
  color: #fff;
  display: flex;
}

.logo-container span {
  font-weight: 600;
  font-size: 18px;
}

.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
  background-color: #2c3e50;
}

.logout-button:hover {
  background-color: #243241;
}

.logout-button .logout{
  color: #fff !important;
  text-decoration: none;
}

.switch-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  color: #fff;
  cursor: pointer;
  background-color: #5e5e5e;
}

.switch-button:hover {
  background-color: #464646;
}

.sidebar-link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
}


