@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.job-opening-full-ai-gen {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: var(--primary-color);
    margin-bottom: 2rem;
  }

  .error-message {
    color: var(--error-color);
    background-color: var(--error-bg);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .initial-form,
  .preview-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: var(--text-color);
        font-weight: 500;
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid var(--border-color);
        border-radius: 8px;
        font-size: 1rem;
        background-color: white;
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
        }

        &:read-only {
          background-color: var(--background-color);
          cursor: not-allowed;
        }
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }
    }

    .form-row {
      display: flex;
      gap: 1rem;
      margin-bottom: 1.5rem;

      .form-group {
        flex: 1;
        margin-bottom: 0;
      }
    }

    .skills-section {
      margin-bottom: 1.5rem;

      .skills-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.75rem;

        label {
          margin: 0;
          font-weight: 500;
          color: var(--text-color);
        }

        .add-skill {
          width: auto;
          padding: 0.5rem 1rem;
          margin: 0;
          background: linear-gradient(
            135deg,
            var(--primary-color),
            var(--primary-color-dark)
          );
          color: #fff;
          border: none;
          border-radius: 8px;
          font-size: 0.875rem; // un peu plus petit que 1rem
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          &:hover {
            opacity: 0.9;
            transform: translateY(-2px);
          }
        }
      }

      .skill-input {
        display: flex;
        gap: 0.75rem;
        margin-bottom: 0.75rem;
        align-items: center;

        input {
          /* Héritera déjà des styles communs, mais on peut rappeler la flex: 1 pour que l'input prenne toute la place */
          flex: 1;
          margin: 0;
        }

        .remove-skill {
          min-width: 36px;
          height: 36px;
          background: white;
          border: 1px solid #e5e7eb;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

          i {
            font-size: 1.1rem;
            color: #ef4444;
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-color: #ef4444;

            i {
              color: #dc2626;
            }
          }
        }
      }
    }

    .generate-button {
      width: 100%;
      padding: 0.75rem;
      margin-top: 2rem;
      background: linear-gradient(
        135deg,
        var(--primary-color),
        var(--primary-color-dark)
      );
      color: var(--background-color);
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &:hover:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        opacity: 0.9;
        color: white;
      }

      &:disabled {
        background: var(--disabled-color);
        cursor: not-allowed;
      }
    }

    .action-buttons {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;

      button {
        flex: 1;
        padding: 0.75rem;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;

        &.back-button {
          background-color: var(--secondary-color);
          color: white;
        }

        &.save-button {
          background-color: var(--primary-color);
          color: white;
        }

        &:hover {
          opacity: 0.9;
          transform: translateY(-2px);
        }
      }
    }
  }

  @include mobile {
    padding: 1rem;

    .initial-form,
    .preview-form {
      padding: 1rem;
    }

    .form-row {
      flex-direction: column;
      gap: 1rem;
    }

    .action-buttons {
      flex-direction: column;
    }
  }
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .language-controls {
    position: relative;

    .language-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      background: var(--primary-color);
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1rem;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.9;
      }

      svg {
        font-size: 1.2rem;
      }
    }

    .language-dropdown {
      position: absolute;
      top: 100%;
      right: 0;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      min-width: 150px;
      margin-top: 0.5rem;

      .language-option {
        display: block;
        width: 100%;
        padding: 0.75rem 1rem;
        text-align: left;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 0.9rem;
        color: var(--text-color);

        &:hover {
          background: var(--background-color);
        }

        &.active {
          background: var(--primary-color);
          color: white;
        }
      }
    }
  }
}
