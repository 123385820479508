@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.add-candidate {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;

  .add-candidate-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin: 0;
    }
  }

  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &:has(textarea),
      &:has([name="address"]),
      &:has([name="experience"]),
      &:has([name="education"]),
      &:has([name="summary"]) {
        grid-column: 1 / -1;
      }

      label {
        font-weight: 600;
        color: var(--secondary-color);

        &::after {
          content: ' *';
          color: $error-color;
          display: none;
        }
      }

      &.required label::after {
        display: inline;
      }

      input, select, textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid $accent-color;
        border-radius: 5px;
        background-color: white;
        font-size: 0.95em;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(#808080, 0.1);
        }

        &[type="url"] {
          font-family: monospace;
        }

        &[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      textarea {
        min-height: 120px;
        resize: vertical;
        font-family: inherit;
      }

      .error-message {
        color: $error-color;
        font-size: 0.85em;
        margin-top: 4px;
      }

      input.error, 
      select.error, 
      textarea.error {
        border-color: $error-color;
        
        &:focus {
          box-shadow: 0 0 0 2px rgba($error-color, 0.1);
        }
      }
    }

    .submit-error {
      grid-column: 1 / -1;
      color: $error-color;
      text-align: center;
      margin-bottom: 15px;
      padding: 10px;
      background-color: rgba($error-color, 0.1);
      border-radius: 5px;
    }

    .form-actions {
      grid-column: 1 / -1;
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      margin-top: 15px;

      button {
        padding: 12px 24px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;
        min-width: 120px;

        &.cancel-button {
          background-color: transparent;
          border: 1px solid $accent-color;
          color: $text-color;

          &:hover {
            background-color: rgba($accent-color, 0.1);
          }
        }

        &.submit-button {
          background-color: var(--primary-color);
          border: none;
          color: white;

          &:hover {
            background-color: rgb(54, 53, 53);
          }

          &:disabled {
            background-color: #808080;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .add-candidate-header h2 {
      font-size: 1.8em;
    }

    form {
      grid-template-columns: 1fr;
      padding: 15px;
      gap: 20px;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .add-candidate-header h2 {
      font-size: 1.6em;
    }

    form {
      padding: 10px;
      gap: 15px;

      .form-group {
        input, select, textarea {
          font-size: 0.9em;
          padding: 10px;
        }
      }

      .form-actions {
        flex-direction: column;
        
        button {
          width: 100%;
          padding: 12px 15px;
        }
      }
    }
  }

  .error-message {
    color: $error-color;
    background-color: rgba($error-color, 0.1);
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-actions {
    button {
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &.submit-button {
        &:disabled {
          background-color: #808080;
        }
      }

      &.cancel-button {
        &:disabled {
          border-color: lighten($accent-color, 20%);
          color: lighten($text-color, 20%);
        }
      }
    }
  }
}

.modal-overlay {
  .modal-content {
    .candidate-details {
      margin: 20px 0;
      
      .details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin-top: 15px;
        
        .detail-item {
          strong {
            display: block;
            color: var(--secondary-color);
            margin-bottom: 5px;
          }
          
          span {
            color: var(--text-color);
          }
        }
      }
    }
  }
}