@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.candidate-details-pool {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;

  .candidate-details-header {
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color:  var(--primary-color);;
      margin: 0;
    }
  }

  .loading,
  .error,
  .not-found {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
  }

  .error {
    color: $error-color;
  }

  .success {
    background-color: #e6ffe6;
    color: #28a745;
    border: 1px solid #28a745;
  }



   

  




  form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .form-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;





      
      .form-group {
        &.full-width {
          grid-column: 1 / -1;
        }


         





        label {
          display: block;
          margin-bottom: 8px;
          font-weight: 500;
          color: $text-color;
        }

        input,
        textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid $accent-color;
          border-radius: 4px;
          font-size: 1em;
          transition: border-color 0.3s ease;

          &:focus {
            outline: none;
            border-color:  var(--primary-color);;
            box-shadow: 0 0 0 2px rgba( var(--primary-color), 0.1);
          }
        }

        textarea {
          min-height: 100px;
          resize: vertical;
        }
      }
    }



    
    .form-actions {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      gap: 15px;

      button {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: 12px 24px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;

        &.cancel-button {
          background-color: transparent;
          color: $error-color;
          border: 1px solid $error-color;

          &:hover {
            background-color: $error-color;
            color: #fff;
          }
        }

        &.save-button {
          background-color:  var(--primary-color);
          color: #fff;
          border: none;

          &:hover {
            background-color: #333333;
          }
        }
      }
    }
  }

  input:disabled,
  textarea:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.7;
  }

   
  .form-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;

    h3 {
      color:  var(--primary-color);
      margin-bottom: 20px;
    }

    .experience-item {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) auto;
      gap: 15px;
      margin-bottom: 15px;
      padding: 15px;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      input,
      select {
        width: 100%;
        padding: 8px;
        border: 1px solid $accent-color;
        border-radius: 4px;
        font-size: 0.9em;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba( var(--primary-color), 0.1);
        }
      }

      select {
        background-color: white;
        cursor: pointer;
      }

      .remove-button {
        padding: 8px 12px;
        background-color: $error-color;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
        white-space: nowrap;

        &:hover {
          background-color: darken($error-color, 10%);
        }
      }
    }

    .add-button {
      width: 100%;
      padding: 10px;
      background-color:  var(--primary-color);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-top: 10px;

      &:hover {
        background-color: #333333;
      }
    }
  }


  .job-application-container {
    background-color: #f5f5f5;   
           
    border-radius: 8px;          
     
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3),    
  0 6px 15px rgba(0, 0, 0, 0.2),   
  0 2px 5px rgba(0, 0, 0, 0.1);     

 
transition: transform 0.3s ease, box-shadow 0.3s ease;

&:hover {
transform: translateY(-5px);   
box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2), 
0 6px 15px rgba(0, 0, 0, 0.2),    
0 2px 5px rgba(0, 0, 0, 0.1);     
}
    margin-top: 20px;            
  
    
    @media (max-width: 768px) {
      padding: 15px;
    }
  
    // Style du contenu textuel
    .job-application-details__body__section__content__item-text {
      font-size: 16px;
      color: #333;
    }
  }


  .resume-section {
    display: flex;
     
    align-items: flex-start;  
    gap: 20px;  
    margin-bottom: 1rem;
    margin-left: 30px;
  
    .resume-label {
      font-size: 1rem;
      font-weight: bold;
      color: #333;
    }
  
    .download-button {
      
      display: inline-block;
      padding: 8px 16px;
      font-size: 0.9rem;
      font-weight: 500;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;
  
      &:hover {
        background-color: #0056b3;
        transform: translateY(-2px);
      }
  
      &:active {
        background-color: #003d80;
        transform: translateY(0);
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
      }
    }


    .disabled-button {
      background-color: #ccc !important; 
      color: #666 !important;  
      cursor: not-allowed !important;
      pointer-events: none;  
      border: 1px solid #aaa;
    }



  
    .no-resume-text {
      font-size: 0.9rem;
      color: #888;
    }
  }
}
