@import '../../components/Variables.scss';
@import '../../styles/_breakpoints.scss';

.modal-form {
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column; 
    gap: 5px; 
    width: 100%; 
    
    label {
      margin-bottom: 8px;
      font-weight: bold;
      width: 65%;
      text-align: left;

    }
    
    input, select, textarea {
      padding: 10px;
      margin-bottom: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }
    .form-group {
      display: flex;
      align-items: center; 
      justify-content: flex-start;
      gap: 5px; 
    }
    
  }

  button {
    padding: 10px 15px;
    background-color:var(--primary-color) ;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 50%;
   margin: auto;
  }

  button:hover {
    background-color: #2c3e50;
  }
}


.list {
  font-family: 'Montserrat', sans-serif;
  padding: 20px;
  color: $text-color;

  h2 {
    color: var(--primary-color);
  }

  .top-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .trash-icon{
    background-color: transparent;
    color: rgb(236, 44, 44);
    border: none;
    cursor: pointer;
  }

  .edit-icon{
    background-color: transparent;
    color: $primary-color;
    border: none;
    cursor: pointer;
  }

  .trash-icon:hover,
  .edit-icon:hover {
    background-color: transparent;
  }

  .top-header h2{
    font-size: 24px !important;
  }

  .top-header button {
    grid-column: 1 / -1;
    background-color: var(--primary-color);
    color: $background-color;
    border: none;
    padding: 8px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #333333;
    }
  }
  .add-icon{
    margin-left: 5px;
  }

  .login-btn {
    background-color: $primary-color;
    color: white;
    cursor: pointer;
  }

  .add-icon-padding {
    padding-left: 5px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    background-color: $background-color;

    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid $accent-color;
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    a {
      color:  var(--primary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include mobile {
    padding: 10px;

    table {
      font-size: 0.9em;

      th, td {
        padding: 8px;
      }
    }
  }

}


