@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.modalSubscription-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalSubscription-title {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.modalSubscription-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modalSubscription-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.modalSubscription-button-login {
  padding: 0.5rem 1rem;
  border: none;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.modalSubscription-button-register {
  padding: 0.5rem 1rem;
  border: none;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.modalSubscription-close-button {
  margin-top: 1rem;
  padding: 0.5rem;
  background: none;
  border: none;
  color: $primary-color;
  cursor: pointer;
}
