.assessment-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: #333;
      margin: 0;
    }

    .invite-btn {
      background-color: #6920b0;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: darken(#6920b0, 10%);
        transform: translateY(-2px);
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .content-section {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;

    h2 {
      color: #6920b0;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid #e0e0e0;
    }

    .question {
      background: #f8f9fa;
      border-radius: 8px;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      border: 1px solid #e0e0e0;

      p {
        font-size: 1.1rem;
        color: #333;
        margin-bottom: 1rem;
        line-height: 1.6;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 0.75rem;

          label {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 4px;
            transition: background-color 0.2s ease;

            &:hover {
              background-color: #f0f0f0;
            }

            input[type="radio"] {
              width: 18px;
              height: 18px;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }
    }

    .article-writing, .reading-comprehension, .scenario-task {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 2px solid #e0e0e0;

      h2 {
        color: #6920b0;
        margin-bottom: 1rem;
      }

      .task, .scenario {
        background: #f8f9fa;
        padding: 1.5rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        border: 1px solid #e0e0e0;

        p {
          margin-bottom: 1rem;
          line-height: 1.6;

          strong {
            color: #6920b0;
          }
        }
      }

      textarea {
        width: 100%;
        min-height: 200px;
        padding: 1rem;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        resize: vertical;
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.6;
        transition: border-color 0.3s ease;

        &:focus {
          outline: none;
          border-color: #6920b0;
          box-shadow: 0 0 0 2px rgba(105, 32, 176, 0.1);
        }
      }
    }
  }

  .participing-candidates {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-top: 2rem;

    h2 {
      color: #6920b0;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &__candidate-list {
      display: grid;
      gap: 1rem;
      
      &-candidate {
        background: #f8f9fa;
        border-radius: 8px;
        padding: 1.5rem;
        border: 1px solid #e0e0e0;

        &_candidate-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;

          h3 {
            color: #333;
            margin: 0;
          }

          .button {
            background-color: #6920b0;
            color: white;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 6px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              background-color: darken(#6920b0, 10%);
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }

  .loading-container,
  .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .error-message {
    color: #dc3545;
    text-align: center;
  }

  .assessment-header {
    margin-bottom: 2rem;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h1 {
        font-size: 1.8rem;
        color: #333;
      }

      .header-actions {
        display: flex;
        gap: 1rem;
      }
    }

    .search-container {
      position: relative;
      max-width: 400px;

      .search-icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: #666;
      }

      .search-input {
        width: 100%;
        padding: 0.8rem 1rem 0.8rem 2.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;

        &:focus {
          outline: none;
          border-color: #6920b0;
        }
      }
    }
  }

  .candidates-section {
    h2 {
      font-size: 1.4rem;
      color: #333;
      margin-bottom: 1.5rem;
    }

    .no-results {
      text-align: center;
      padding: 2rem;
      background: #f8f9fa;
      border-radius: 4px;
    }

    .candidates-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;
    }

    .candidate-card {
      background: white;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 1.5rem;
      transition: transform 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .candidate-info {
        h3 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }

        .email {
          color: #666;
          margin-bottom: 1rem;
        }

        .score-badge {
          display: inline-block;
          padding: 0.4rem 0.8rem;
          background: #6920b0;
          color: white;
          border-radius: 20px;
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
        }

        .submission-date {
          font-size: 0.9rem;
          color: #666;
        }
      }
    }
  }

  .invite-btn,
  .export-btn,
  .view-analysis-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;

    svg {
      font-size: 1.1rem;
    }
  }

  .invite-btn {
    background: #6920b0;
    color: white;

    &:hover {
      background: #551a8b;
    }
  }

  .export-btn {
    background: #28a745;
    color: white;

    &:hover {
      background: #218838;
    }
  }

  .view-analysis-btn {
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
    background: #f8f9fa;
    color: #6920b0;
    border: 1px solid #6920b0;

    &:hover {
      background: #6920b0;
      color: white;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-analysis {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.participing-candidates {
  &__candidate-list {
    &-candidate {
      &_candidate-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;

        h3 {
          flex: 1;
          margin: 0;
          color: #333;
          font-size: 1.1rem;
        }

        .score-info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin: 0 1rem;

          .score-label {
            color: #666;
          }

          .score-value {
            font-weight: bold;
            color: #6920b0;
          }
        }

        .analysis-button {
          background-color: #6920b0;
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.3s ease;
          font-size: 0.9rem;

          &:hover {
            background-color: darken(#6920b0, 10%);
            transform: translateY(-2px);
          }

          &:active {
            transform: translateY(0);
          }
        }
      }
    }
  }
}

.analysis-container {
  .analysis-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #eee;

    h2 {
      margin: 0;
      color: #6920b0;
    }

    .close-button {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #666;
      padding: 0.5rem;
      transition: color 0.3s ease;

      &:hover {
        color: #6920b0;
      }
    }
  }

  .analysis-content {
    .candidate-info {
      background: #f8f9fa;
      padding: 1rem;
      border-radius: 8px;
      margin-bottom: 2rem;

      h3 {
        margin: 0 0 0.5rem 0;
        color: #333;
      }

      p {
        margin: 0;
        color: #666;
      }

      .score {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #6920b0;
      }
    }

    .analysis-section {
      margin-bottom: 2rem;

      h4 {
        color: #6920b0;
        margin-bottom: 1rem;
      }

      p {
        line-height: 1.6;
        color: #444;
      }

      .analysis-item {
        background: #f8f9fa;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1rem;

        h5 {
          color: #6920b0;
          margin: 0 0 0.5rem 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

.error-banner {
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  
  p {
    color: #d32f2f;
    margin: 0;
  }
}