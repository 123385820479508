.waiting-message {
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 10000;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}