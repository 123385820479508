.add_column_btn{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background: var(--primary-color);
    color: white;
    border-radius: 5px;

}

.Kanban_main_container{
    display: flex;
    flex-wrap: nowrap; 
    gap: 20px;

    overflow-y: auto; 
    overflow-x: auto;
     justify-items: start;
    justify-content: start;
    padding:2px;

}

.create_board_btn{
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}

.create_board_btn h3 {
    display: inline-block;
}


.Kanban_main_container::-webkit-scrollbar {
    height: 10px; 
    width: 10px; 
}

.Kanban_main_container::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

.Kanban_main_container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}

.Kanban_main_container::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.Kanban_main_container {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}


// view model css

.view-modal-overlay {
    position: fixed;
    top: 10px;
    right: 0;
    width: 50%; /* Half of the screen width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Add transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .view-modal-content {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2); /* Adds a shadow on the left */
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .view-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  