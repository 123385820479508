@import '../../../Variables.scss';
@import '../../../../styles/breakpoints.scss';

.create-assessment {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;

  .create-assessment-header {
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color:  var(--primary-color);
      margin: 0;
    }
  }

  form {
    display: grid;
    gap: 30px;
    padding: 20px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-weight: 600;
        color: var(--secondary-color);
      }

      select {
        width: 100%;
        padding: 12px;
        border: 1px solid $accent-color;
        border-radius: 5px;
        background-color: white;
        font-size: 0.95em;
        
        &:focus {
          outline: none;
          border-color:  var(--primary-color);
          box-shadow: 0 0 0 2px rgba(#808080, 0.1);
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      .submit-button {
        padding: 12px 24px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        background-color:  var(--primary-color);
        border: none;
        color: white;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #333333;
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .create-assessment-header h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .create-assessment-header h2 {
      font-size: 1.6em;
    }

    form {
      padding: 10px;
      gap: 15px;

      .form-group {
        select {
          font-size: 0.9em;
          padding: 10px;
        }
      }

      .form-actions {
        button {
          width: 100%;
          padding: 12px 15px;
        }
      }
    }
  }
} 