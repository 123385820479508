@import '../../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.assessment-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #ffffff;

  .assessment-header {
    text-align: left;
    background: linear-gradient(to right, #4F46E5, #6366F1);
    margin: -2rem -2rem 3rem -2rem;
    padding: 3rem 2rem;
    color: white;
    border-bottom: none;

    h1 {
      color: white;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }

    .header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      .invite-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.5rem;
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: white;
        border-radius: 8px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.25);
          transform: translateY(-2px);
        }

        .icon {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }

  .content-section {
    background: white;
    border-radius: 12px;
    padding: 0;
    margin: 20px 0;
    line-height: 2;
    color: #444;
    border: none;
  }

  .section {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    h2 {
      color: #2d3748;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
  }

  .assessment-details {
    margin-bottom: 3rem;
    
    .purpose {
      color: #4a5568;
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
      line-height: 1.6;
    }

    .meta-info {
      display: flex;
      gap: 2rem;
      color: #718096;
    }
  }

  .question-block {
    background: #F8FAFC;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.1);
    }

    .question-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      h3 {
        color: #2d3748;
        margin: 0;
      }

      .skill-tag {
        background: #e9ecef;
        color: #495057;
        padding: 0.25rem 0.75rem;
        border-radius: 15px;
        font-size: 0.9rem;
      }
    }

    .question-text {
      color: #2d3748;
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }

    .options {
      display: grid;
      gap: 1rem;

      .option {
        background: white;
        border: 1px solid #E2E8F0;
        border-radius: 8px;
        padding: 1rem;
        display: flex;
        gap: 0.5rem;
        transition: all 0.2s;

        &:hover {
          background: #F1F5F9;
          border-color: #CBD5E1;
        }
      }
    }
  }

  // Loading Spinner
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  // Content Section
  .content-section {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 30px 30px;
    margin: 20px 0;
    line-height: 2;
    color: #444;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 12px;
      color: #333;
      border-bottom: 2px solid #d1c4e9;
      padding-bottom: 5px;
    }

    p {
       
      color: #555;
    }

    ul, ol {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;

      label {
        font-weight: bold;
        color: #333;
        display: block;
        margin-bottom: 8px;
      }

      input[type="text"],
      textarea {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 1rem;
        color: #555;
        background-color: #f9f9f9;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: #6a1b9a;
          background-color: #fff;
          box-shadow: 0 0 5px rgba(106, 27, 154, 0.5);
        }
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }
  }

  // Action Buttons
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 15px;

    button {
      padding: 10px 15px;
      font-size: 14px;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      gap: 8px;

      &.edit-btn {
        background-color: #ff9800;
        color: #fff;

        &:hover {
          background-color: #fb8c00;
          transform: scale(1.05);
        }
      }

      &.save-btn {
        background-color: #2196f3;
        color: #fff;

        &:hover {
          background-color: #1976d2;
          transform: scale(1.05);
        }
      }
    }
  }

  .section-title {
    color: #333;
    border-bottom: 2px solid #6920b0;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .introduction-section {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 2rem;

    .introduction-content {
      .purpose, .time-estimate {
        margin-bottom: 1rem;
        line-height: 1.6;
      }

      .skills-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 1rem;

        .skill-badge {
          background: #6920b0;
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 20px;
          font-size: 0.9rem;
        }
      }
    }
  }

  .questions-section {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 2rem;

    .section-description {
      color: #666;
      margin-bottom: 2rem;
    }

    .questions-list {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .question-card {
        background: #f8f9fa;
        border-radius: 8px;
        padding: 1.5rem;
        border: 1px solid #e9ecef;

        .question-header {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;

          .question-number {
            font-size: 1.2rem;
            color: #6920b0;
            margin: 0;
          }

          .skill-tag {
            background: #e9ecef;
            color: #495057;
            padding: 0.25rem 0.75rem;
            border-radius: 15px;
            font-size: 0.9rem;
          }
        }

        .question-text {
          font-size: 1.1rem;
          color: #212529;
          margin-bottom: 1.5rem;
          line-height: 1.6;
        }

        .options-grid {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .option-item {
            display: flex;
            align-items: flex-start;
            gap: 0.5rem;
            padding: 0.75rem;
            background-color: white;
            border: 1px solid #e5e7eb;
            border-radius: 4px;

            .option-letter {
              min-width: 25px;
              font-weight: 500;
            }

            .option-text {
              flex: 1;
            }
          }
        }
      }
    }
  }

  .closing-section {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;

    .closing-message {
      font-size: 1.1rem;
      color: #495057;
      line-height: 1.6;
    }
  }
}
