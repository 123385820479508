@import "../../../Variables.scss";
@import "../../../../styles/breakpoints.scss";

.assessments-results {
  padding: 2rem;
  background-color: $background-color;

  h2 {
    color: var(--primary-color);
    margin-bottom: 2rem;
    font-size: 2em;
  }

  .assessment-section {
    margin-bottom: 3rem;

    h3 {
      color: $text-color;
      margin-bottom: 1rem;
      font-size: 1.5em;
    }
  }

  .results-table-container {
    overflow-x: auto;
    border: 2px solid $border-color;
    border-radius: 8px;

    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        padding: 1rem;
        text-align: left;
        border-bottom: 1px solid $border-color;
      }

      th {
        background-color: #f8f9fa;
        font-weight: 600;
      }

      tr:hover {
        background-color: #f8f9fa;
      }

      .view-button {
        padding: 0.5rem 1rem;
        background-color: var(--primary-color);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          opacity: 0.9;
          background-color: var(--primary-color-dark, var(--primary-color));
        }
      }
    }
  }

  .loading-message,
  .error-message {
    text-align: center;
    padding: 2rem;
    color: $text-color;
  }

  .error-message {
    color: #dc2626;
  }
} 