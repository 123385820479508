@import "../../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.screen-app-onboarding {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;

  section {
    margin-bottom: 60px;
    
    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin-bottom: 20px;
    }

    h3 {
      font-size: 1.5em;
      color: var(--secondary-color);
      margin: 30px 0 20px;
    }

    p {
      font-size: 1.1em;
      line-height: 1.6;
      margin-bottom: 30px;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; // 16:9 aspect ratio
    height: 0;
    overflow: hidden;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .activate-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 15px 50px;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 30px auto;
    display: block;
    min-width: 300px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: darken($primary-color, 10%);
      transform: translateY(-2px);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .provider-subsection {
    margin-bottom: 40px;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 20px 15px;

    section {
      h2 {
        font-size: 1.75em;
      }

      h3 {
        font-size: 1.25em;
      }

      p {
        font-size: 1em;
      }
    }

    .activate-button {
      width: 90%;
      min-width: unset;
      padding: 15px 30px;
      margin: 20px auto;
    }
  }
} 