$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-mobile + 1}) and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}