@import "../../../Variables.scss";

.waiting-message {
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 10000;
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-hard-skills {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #f0f0f0;

    h2 {
      font-size: 1.8rem;
      color: $primary-color;
      margin: 0;
    }
  }

  &-content {
    padding: 1rem;
  }

  &-form {
    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        font-weight: 600;
        color: #333;
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }

      select {
        width: 100%;
        padding: 0.75rem;
        border: 2px solid #e0e0e0;
        border-radius: 8px;
        font-size: 1rem;
        color: #444;
        background-color: #fff;
        transition: all 0.3s ease;
        cursor: pointer;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;

        &:focus {
          outline: none;
          border-color: #6920b0;
          box-shadow: 0 0 0 3px rgba(105, 32, 176, 0.1);
        }

        &:hover {
          border-color: #6920b0;
        }

        option {
          padding: 0.75rem;
        }
      }
    }

    .form-actions {
      margin-top: 2rem;
      text-align: center;

      .submit-button {
        background-color: #6920b0;
        color: white;
        border: none;
        padding: 1rem 2rem;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        min-width: 200px;
        justify-content: center;

        &:hover:not(:disabled) {
          background-color: #5a1b96;
          transform: translateY(-2px);
        }

        &:disabled {
          background-color: #9e9e9e;
          cursor: not-allowed;
        }
      }
    }

    .focus-skills {
      .skills-description {
        color: #666;
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }

      .skills-inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 400px;
        margin: 0;

        input {
          width: 100%;
          padding: 0.75rem;
          border: 2px solid #e0e0e0;
          border-radius: 8px;
          font-size: 1rem;
          color: #444;
          transition: all 0.3s ease;

          &:focus {
            outline: none;
            border-color: #6920b0;
            box-shadow: 0 0 0 3px rgba(105, 32, 176, 0.1);
          }

          &:hover {
            border-color: #6920b0;
          }
        }
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .create-hard-skills {
    margin: 1rem;
    padding: 1rem;

    &-header {
      margin-bottom: 1.5rem;

      h2 {
        font-size: 1.5rem;
      }
    }

    &-form {
      .form-group {
        margin-bottom: 1rem;

        select {
          padding: 0.5rem;
        }
      }

      .form-actions {
        .submit-button {
          width: 100%;
          padding: 0.75rem 1.5rem;
        }
      }

      .focus-skills {
        .skills-inputs {
          grid-template-columns: 1fr;
        }
      }
    }
  }
} 