@import './Variables.scss';
@import '/src/styles/breakpoints.scss';

.dashboard-not-found-page {
  text-align: center;
  padding: 10px;
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-404-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -5px 0;
}

.logo-404 {
  max-width: 1000px;
  height: auto;
  border-radius: 10px;
}

.main-content {
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #6920b0;
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
  }

  p {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;
    color: #333333;
  }
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.dashboard-link, .back-link {
  font-size: 1.2rem;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.dashboard-link {
  color: #6920b0;
  border: 1px solid #6920b0;
  
  &:hover {
    background-color: #6920b0;
    color: white;
  }
}

.back-link {
  color: #fa41cc;
  border: 1px solid #fa41cc;
  background: none;
  
  &:hover {
    background-color: #fa41cc;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    padding: 20px;
  }

  .logo-404 {
    max-width: 350px;
  }

  .dashboard-link, .back-link {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-content {
    padding: 40px;
  }

  .logo-404 {
    max-width: 600px;
  }

  .dashboard-link, .back-link {
    font-size: 16px;
    padding: 12px 20px;
  }
}
