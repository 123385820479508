@import '../Variables.scss';
@import '/src/styles/breakpoints.scss';

.subscription-confirmation {
  width: 100%;
  min-height: 40rem;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  color: $text-color;

  opacity: 0;
  transform: translateY(-20px);
  animation: fadeIn 0.8s ease-out forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .error-message {
    color: red;
    font-size: 1,2rem;
    margin-bottom: 10px;
  }

  &__title-container {
    background-color: $primary-color;
    padding: 40px 0;
    text-align: center;
    margin-bottom: 2rem;

    .subscription-confirmation__title {
      font-size: 2.5rem;
      color: white;
      font-weight: 600;
    }
  }

  &__content {
    margin: 0 auto 2rem;
    width: 80%;
    max-width: 800px;
    
    display: grid;
    grid-template: 3fr 1fr / 3fr 1fr;
    gap: 1rem;

  }

  &__container {
    grid-row: 1 / 4;
    background-color: $background-color;
    padding: 1.5rem;
    border-radius: 10px;
    border: 1px solid $border-color;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  &__payment-term {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    gap: 1rem;

    label {
      display: flex;
      gap: 1rem;
      width: 100%;
    }

    select {
      text-align: center;
      padding: 0.4rem;
      font-size: 0.9rem;
      border-radius: 5px;
      border:none;
      background-color: white;
      cursor: pointer;
    }
  }

  &__addons {
    display: flex;
    flex-direction: column;

    gap: 1rem;
    font-size: 1.2rem;

    &-button {
      align-self: center;

      button {
        background-color: $primary-color;
        color: white;
        border: none;
        padding: 0.4rem 1.5rem;
        border-radius: 5px;
        font-size: 0.7rem;
        font-weight: bold;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($primary-color, 10%);
          transform: scale(1.02);
          cursor: pointer;
        }

      }
    }

    &-container-items {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border: 1px solid $border-color;
        border-radius: 10px;
        transition: all 0.3s ease;

        &:hover {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
          border-color: $primary-color;
        }

        p {
          flex: 1;
          font-size: 1rem;
          color: darken($text-color, 10%);
        }

        &-price_container {
          text-align: right;

          .price {
            font-weight: bold;
            font-size: 1rem;
            color: $primary-color;
          }

          .credit {
            font-size: 0.8rem;
            color: darken($text-color, 20%);
          }
        }
      }
    }
  }

  &__addons-container__modality {

    grid-row: 1 / 4;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2rem;
    gap: 1rem;
  }

  &__quantity {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;

    label {
      font-size: 1rem;
      font-weight: 600;
    }

    input {
      width: 2rem;
      text-align: center;
      padding: 0.5rem;
      font-size: 1rem;
      border-radius: 5px;
      border: 1px solid $border-color;
      text-align: center;
      cursor: pointer;
    }
  }

  &__promocode {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    label {
      font-size: 1rem;
      font-weight: 600;
      align-self: flex-start;
    }

    input {
      padding: 0.5rem;
      font-size: 0.7rem;
      border-radius: 5px;
      border: 1px solid $border-color;
    }

    button {
      align-self: flex-end;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 0.4rem 1.5rem;
      font-size: 0.7rem;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      &:disabled {
        background-color: lighten($primary-color, 30%);
        cursor: not-allowed;
      }
    }

    .error {
      color: $error-color;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  &__total-container {
    grid-row: 4 / 5;
    text-align: center;
    font-weight: bold;
    color: $text-color;
    border: none;
    padding: 1.2rem;
    border-radius: 10px;
    margin: 0.5rem auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &_group {
      display: flex;
      gap: 1rem;
      align-items: center;

      &-final_amount {
        font-size: 2.5rem;
        font-weight: 600;
        color: $primary-color;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    button {
      background-color: ($primary-color);
      color: white;
      padding: 0.8rem 1.5rem;
      border: 1px solid $primary-color;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: white;
        color: darken($success-color, 15%);
        border: none;
        box-shadow: 1px 1.5px 3px darken($success-color, 15%);
      }
    }
  }
}