@import '../../../Variables.scss';
@import '../../../../styles/breakpoints.scss';

.company-hiring-trends {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  
    .trends-header {
      margin-bottom: 2rem;
      text-align: center;
  
      h2 {
        font-size: 2.5rem;
        color: var(--primary-color);
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 1.1rem;
        color: var(--secondary-color);
      }
    }

  .trends-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 2rem;

    .trends-summary {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li.summary-item {
          margin-bottom: 1rem;
          line-height: 1.6;
          color: var(--text-primary);
          padding-left: 1.5rem;
          position: relative;

          &:before {
            content: "•";
            position: absolute;
            left: 0;
            color: var(--primary-color);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cost-analysis-content {
  .salary-item {
    font-size: 1.1rem;
    line-height: 1.8;
    padding: 0.75rem 1rem;
    margin: 0.5rem 0;
    background: var(--background-light);
    border-radius: 8px;
    
    .salary-amount {
      font-weight: 600;
      color: var(--primary-color);
      margin-left: 0.5rem;
      
      &:after {
        content: " €";
        font-weight: normal;
      }
    }
  }
}

.analysis-content {
  padding: 1rem;
  
  .analysis-item {
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 0.75rem 0;
    padding-left: 1.5rem;
    position: relative;
    
    &:before {
      content: "•";
      position: absolute;
      left: 0;
      color: var(--primary-color);
    }
  }
  
  .analysis-text {
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 1rem 0;
    color: var(--text-primary);
    
    &:first-child {
      margin-top: 0;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }

  .loading-text {
    color: var(--text-primary);
    font-size: 1.1rem;
    margin: 0;
  }
}

.error-message {
  padding: 1rem;
  margin: 1rem;
  background-color: #fee;
  border: 1px solid #fcc;
  border-radius: 4px;
  color: #c00;
  text-align: center;
  
  p {
    margin: 0;
    font-size: 1.1rem;
  }
} 