@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.billing {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  h2 {
    font-size: 2em;
    color:  var(--primary-color);;
    margin-bottom: 20px;
  }

  .payments-table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 15px;
      text-align: left;
      border-bottom: 1px solid $accent-color;
    }

    th {
      background-color:  var(--primary-color);;
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    a {
      color:  var(--primary-color);;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    .payments-table {
      font-size: 0.9em;

      th, td {
        padding: 10px;
      }
    }
  }
}

