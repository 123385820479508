@import './Variables.scss';
@import '/src/styles/breakpoints.scss';

.reset-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: whitesmoke;
    font-family: $font-family;


     


    form {
        background-color: $background-color;
        padding: 2rem;
        border-radius: $border-radius;
        box-shadow: 2px 2px 5px #0000008e;
        width: 100%;
        max-width: 400px;

        h2 {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
            text-align: center;
            color: $primary-color;
            font-weight: 600;
        }

        input[type="password"] {
            width: 100%;
            padding: 0.8rem;
            font-size: 1rem;
            color: $text-color;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            margin-bottom: 1.5rem;
            box-sizing: border-box;

            &:focus {
                border-color: $primary-color;
                outline: none;
                box-shadow: 0 0 4px rgba($primary-color, 0.5);
            }
        }

        button {
            width: 100%;
            padding: 0.8rem;
            font-size: 1rem;
            font-weight: 600;
            color: $background-color;
            background-color: $primary-color;
            border: none;
            border-radius: $border-radius;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: darken($primary-color, 10%);
                box-shadow: $box-shadow-hover;
            }
        }

        p {
            margin-top: 1rem;
            font-size: 1em;
            text-align: center;
            font-family: $font-family;

            &.success {
                color: $success-color;
            }

            &.error {
                color: $error-color;
            }
        }



       
    }

 
}

p.success-mdp {
    color: #28a745;  
    font-size: 1.3rem; 
     
    text-align: center; 
    margin: 40px auto;  
    padding: 15px;  
    border: 2px solid #28a745;  
    border-radius: 10px; 
    background-color: #e9f7ec;  
    width: 20%;  
  }