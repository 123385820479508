@import '../../Variables.scss';
@import '../../../styles/breakpoints.scss';

.candidate-pool {
  padding: 2rem;
  
  .pool-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 1.8rem;
      color: var(--primary-color);
      margin: 0;
    }

    .search-bar {
      flex: 0 1 300px;
      
      input {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid $border-color;
        border-radius: 8px;
        font-size: 0.9rem;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(#808080, 0.1);
        }
      }
    }
  }

  .candidates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;

    .candidate-card {
      background: white;
      border-radius: 12px;
      padding: 1.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      .candidate-info {
        h3 {
          margin: 0 0 0.5rem;
          color: $text-color;
          font-size: 1.1rem;
        }

        .email, .phone {
          color: $text-color-light;
          margin: 0.25rem 0;
          font-size: 0.9rem;
        }

        .linkedin-link {
          display: inline-block;
          margin-top: 0.5rem;
          color: var(--primary-color);
          text-decoration: none;
          font-size: 0.9rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .card-actions {
        margin-top: 1rem;
        display: flex;
        gap: 0.5rem;

        button {
          flex: 1;
          padding: 0.5rem;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.2s ease;
          font-weight: 500;

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }

        .view-details {
          background-color: var(--primary-color);
          color: white;

          &:hover:not(:disabled) {
            background-color: #333333;
          }
        }

        .add-to-pool {
          background-color: white;
          border: 1px solid var(--primary-color);
          color: var(--primary-color);

          &:hover:not(:disabled) {
            background-color: var(--secondary-color);
            color: white;
          }
        }
      }
    }
  }

  .loading, .error, .no-candidates {
    text-align: center;
    padding: 2rem;
    color: $text-color-light;
  }

  .error {
    color: $danger-color;
  }
}