@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.structured-interview-details {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .details-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;

    .back-button {
      padding: 0.5rem 1rem;
      background: none;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: var(--primary-color);
        color: white;
      }
    }

    h1 {
      color: var(--primary-color);
      margin: 0;
      font-size: 1.8rem;
    }
  }

  .details-content {
    .info-section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
      margin-bottom: 3rem;

      .info-card {
        background: white;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        h2 {
          color: var(--primary-color);
          margin: 0 0 1rem 0;
          font-size: 1.2rem;
        }

        .label {
          color: var(--text-secondary);
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
        }

        .value {
          font-size: 1.1rem;
          color: var(--text-primary);
          font-weight: 500;
        }
      }
    }

    .questionnaire-section {
      background: white;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h2 {
        color: var(--primary-color);
        margin: 0 0 2rem 0;
        font-size: 1.5rem;
        border-bottom: 2px solid var(--primary-color);
        padding-bottom: 0.5rem;
      }

      .question-section {
        margin-bottom: 2.5rem;

        h3 {
          color: var(--primary-color);
          margin: 0 0 1.5rem 0;
          font-size: 1.3rem;
          padding-left: 1rem;
          border-left: 4px solid var(--primary-color);
        }

        .questions-list {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .question-card {
            background: var(--background-light);
            padding: 1.5rem;
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            transition: all 0.3s ease;

            &:hover {
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              transform: translateY(-2px);
            }

            h4 {
              color: var(--primary-color);
              margin: 0 0 1rem 0;
              font-size: 1.1rem;
              display: inline-block;
              padding: 0.3rem 0.8rem;
              background: var(--primary-color-light);
              border-radius: 4px;
            }

            .question-text {
              color: var(--text-primary);
              margin-bottom: 1rem;
              line-height: 1.6;
              font-size: 1.1rem;
              font-weight: 500;
            }

            .question-type,
            .question-importance,
            .question-purpose {
              color: var(--text-secondary);
              margin-bottom: 0.8rem;
              font-size: 0.95rem;

              strong {
                color: var(--text-primary);
                margin-right: 0.5rem;
              }
            }

            .criteria-list {
              margin-top: 1rem;
              padding: 1rem;
              background: var(--background-secondary);
              border-radius: 6px;

              strong {
                display: block;
                margin-bottom: 0.8rem;
                color: var(--text-primary);
              }

              ul {
                margin: 0;
                padding-left: 1.5rem;

                li {
                  color: var(--text-secondary);
                  margin-bottom: 0.5rem;
                  line-height: 1.4;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .structured-interview-details {
    padding: 1rem;

    .details-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    .details-content {
      .info-section {
        grid-template-columns: 1fr;
      }

      .questionnaire-section {
        padding: 1rem;

        .question-section {
          margin-bottom: 2rem;

          .questions-list {
            gap: 1rem;

            .question-card {
              padding: 1rem;
            }
          }
        }
      }
    }
  }
} 