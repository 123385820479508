@import './Variables.scss';
@import '/src/styles/breakpoints.scss';

.footer {
  background-color: white !important; // Force white background
  color: $primary-color !important; // Force primary color for text
  padding: 3rem 1.5rem 2rem 1.5rem;
  font-family: 'Montserrat', sans-serif;
  width: 100%;  
  box-sizing: border-box;  
  overflow-x: hidden;  

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;


    @include mobile {
      flex-direction: column;
    }
  }

  .footer-left {
    flex: 1;
    display: flex;
    align-items: center;

    .logo-and-features {
      display: flex;
      align-items: center;

      .footer-logo {
        width: 100px;
        margin-right: 20px;
      }

      .footer-features {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin-left: 20px;
          margin-bottom: 5px;
          font-size: 0.9em;
          color: $primary-color !important; // Ensure primary color for list items
        }
      }
    }

    @include mobile {
      display: none; // Hide logo and features on mobile
    }
  }

  .footer-right {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;


    .footer-links {
       
      list-style-type: none;
     
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;


      li {

        margin-left: 20px;

        a {
          color: $primary-color !important; // Ensure primary color for links
          text-decoration: none;
          transition: color 0.3s ease;


          &:hover {
            color: darken($primary-color, 15%) !important;
          }
        }
      }

      @include mobile {
        margin: 5px 10px; // Marges ajustées pour mobile
      }
    }

    @include mobile {
      text-align: left; // Align links to the left on mobile
    }
  }

  .footer-copyright {
    text-align: center;
    margin: 1rem;
    
    p {
      font-size: 0.9em;
      color: $primary-color !important; // Ensure primary color for copyright text
    }
  }

  @include mobile {
    padding: 20px; // Reduce padding on mobile

    .footer-left, .footer-right {
      text-align: left;
      margin-bottom: 10px; // Reduce margin between sections
    }

    .footer-right .footer-links {
      li {
        margin-bottom: 8px; // Slightly reduce space between links
      }
    }

    
  }

  // Ajouté à partir du fichier 2
  flex-direction: column; // Si nécessaire
  align-items: center;
  text-align: center;

  p {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
  }
}

// Ajout de la classe footer-menu
.footer-menu {
  font-family: 'Montserrat', sans-serif;
  background-color: $primary-color;
  color: $background-color;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid $background-color;
}

