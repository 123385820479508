$candidate-purple: #6920b0;
$white: #ffffff;
$light-gray: #f5f5f5;
$dark-gray: #444;
$soft-purple: #d3b4f7;
$hover-purple: #8757c7;

.candidate-emails-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: $light-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  

  .email-box {
    background-color: $white;
    width: 90%;
    max-width: 800px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    margin-top: -300px;  
    transform: translateY(-50px);  

    .header {
      background: var(--primary-color);
      color: $white;
      padding: 1.5rem 2rem;
      text-align: center;
      font-size: 1.8rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .emails-list {
      flex: 1;
      padding: 1rem;
      overflow-y: auto;
      background-color: #fafafa;

      .email-item {
        background-color: $white;
        margin: 0.5rem 0;
        padding: 1.2rem;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: var(--primary-color);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          transform: translateY(-2px);
        }

        &.open {
          background-color: lighten var(--primary-color, 40%);
        }

        .email-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .email-subject {
            font-size: 1.2rem;
            font-weight: bold;
            color: var(--secondary-color);
            flex: 1;
          }

          .email-icon {
            font-size: 1.5rem;
            color: var(--primary-color);
            margin-left: 0.5rem;
            transition: color 0.3s ease;

            &:hover {
              color: var(--primary-color);
            }
          }
        }

        .email-preview {
          font-size: 1rem;
          color: $dark-gray;
          margin-top: 0.5rem;
          max-height: 200px;
          overflow-y: auto;
          line-height: 1.4;
        }
      }

      .no-emails {
        text-align: center;
        font-size: 1.2rem;
        color: $dark-gray;
      }
    }
  }
}

.return-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  background-color: #e9e4f2;  
  color: var(--primary-color); 
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  margin: 1rem;
  width: fit-content;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  .return-icon {
    font-size: 1.2rem;
  }

  &:hover {
    background-color: var(--primary-color);
    color: #ffffff;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
}

// Media Queries
@media (max-width: 1200px) {
  .candidate-emails-container {
    .email-box {
      width: 80%;
    }
  }
}

@media (max-width: 992px) {
  .candidate-emails-container {
    .email-box {
      width: 90%;
      padding: 1rem;

      .header {
        font-size: 1.5rem;
      }

      .emails-list {
        .email-item {
          padding: 0.8rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .candidate-emails-container {
    padding: 0.5rem;

    .email-box {
      width: 95%;

      .header {
        font-size: 1.3rem;
        padding: 1rem;
      }

      .emails-list {
        .email-item {
          font-size: 0.8rem;
          padding: 0.6rem;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .candidate-emails-container {
    .email-box {
      width: 100%;
      box-shadow: none;

      .header {
        font-size: 1.2rem;
        padding: 0.8rem;
      }

      .emails-list {
        .email-item {
          font-size: 0.8rem;
          padding: 0.5rem;
        }
      }
    }
  }
}
