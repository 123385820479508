@import '../../../Variables.scss';

.hiring-market-trends {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .trends-header {
    margin-bottom: 2rem;
    text-align: center;

    h2 {
      font-size: 2.5rem;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.1rem;
      color: var(--secondary-color);
    }
  }

  .country-selector {
    max-width: 400px;
    margin: 0 auto 3rem;
    
    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  .trends-content {
    display: grid;
    gap: 2rem;
  }

  .trends-section {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }

    .section-header {
      background: linear-gradient(
  to right,
  var(--primary-color),
  color-mix(in srgb, var(--primary-color) 90%, white 10%)
);
      color: white;
      padding: 1rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      svg {
        font-size: 1.25rem;
      }

      h3 {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    .content-box {
      padding: 1.5rem;
      background: white;

      p {
        font-size: 1.05rem;
        line-height: 1.7;
        color: $text-color;
        margin-bottom: 1.25rem;
        text-align: justify;

        &:last-child {
          margin-bottom: 0;
        }

        // Style pour les listes dans le texte
        ul, ol {
          margin: 1rem 0;
          padding-left: 1.5rem;

          li {
            margin-bottom: 0.5rem;
          }
        }

        // Style pour les données numériques
        strong {
          color: var(--primary-color);
          font-weight: 600;
        }
      }

      // Style pour les sections spécifiques
      &.market-overview {
        border-left: 4px solid #4CAF50;
      }

      &.top-industries {
        border-left: 4px solid #2196F3;
      }

      &.demanded-skills {
        border-left: 4px solid #9C27B0;
      }

      &.salary-trends {
        border-left: 4px solid #FF9800;
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin: 1rem 0;

        li {
          position: relative;
          padding-left: 1.5rem;
          margin-bottom: 0.75rem;
          line-height: 1.6;

          &:before {
            content: "•";
            color: var(--primary-color);
            position: absolute;
            left: 0;
            font-weight: bold;
          }
        }
      }

      strong {
        color: var(--primary-color);
        font-weight: 600;
      }

      .summary-section {
        margin-bottom: 2rem;

        h4 {
          color: var(--primary-color);
          font-size: 1.1rem;
          margin-bottom: 1rem;
          padding-bottom: 0.5rem;
          border-bottom: 2px solid rgba var(--primary-color, 0.1);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // États de chargement et d'erreur
  .loading-state {
    text-align: center;
    padding: 3rem;

    .loader {
      width: 50px;
      height: 50px;
      border: 3px solid #f3f3f3;
      border-top: 3px solid var(--primary-color);
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin: 0 auto 1rem;
    }
  }

  .error-state {
    background-color: #fff3f3;
    border-left: 4px solid #ff4444;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    margin: 1rem 0;

    p {
      color: #d32f2f;
      margin: 0;
    }
  }

  // Responsive design
  @media (max-width: 768px) {
    padding: 1rem;

    .trends-header {
      h2 {
        font-size: 2rem;
      }
    }

    .trends-section {
      .section-header {
        padding: 1rem;
      }

      .content-box {
        padding: 1rem;

        p {
          font-size: 1rem;
          line-height: 1.6;
        }
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}