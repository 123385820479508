@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.candidate-pool {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  h2 {
    font-size: 2em;
    color: var(--primary-color);
    margin-bottom: 20px;
  }

  .search-bar {
    margin-bottom: 20px;

    input {
      width: 100%;
      padding: 10px;
      font-size: 1em;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
    }
  }

  .modal-trackingBoard {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 8px;
      width: 90%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      text-align: center;

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h3 {
          font-size: 1.5em;
          color: var(--primary-color);
          text-align: center;
          flex-grow: 1;
        }

        button {
          background: none;
          border: none;
          font-size: 1.5em;
          cursor: pointer;
          color: $text-color;

          &:hover {
            color: darken($text-color, 10%);
          }
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .add-candidate-button-wrapper {
          button {
            background-color: var(--primary-color);
            color: var(--background-color);
            border: none;
            padding: 10px 20px;
            margin: 10px;
            font-size: 1em;
            cursor: pointer;
            border-radius: 5px;
            margin-bottom: 20px;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #333333;
            }
          }
        }

        .board-selector,
        .lists {
          background-color: #f9f9f9;
          border: 1px solid $accent-color;
          border-radius: 8px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;

          h4 {
            font-size: 1.3em;
            margin-bottom: 10px;
            color: var(--primary-color);
          }

          select {
            width: 100%;
            padding: 10px;
            font-size: 1em;
            border: 1px solid $accent-color;
            border-radius: 5px;
            background-color: white;
            cursor: pointer;
            margin-bottom: 15px;
            white-space: nowrap; // Empêche la troncature
            text-overflow: ellipsis; // Gestion de l'overflow (visuel uniquement)
            overflow: hidden;

            &:hover {
              border-color: var(--primary-color);
            }

            &:focus {
              outline: none;
              border-color: var(--primary-color);
              box-shadow: 0 0 0 2px rgba(#808080, 0.2);
            }
          }

          p {
            margin-top: 10px;
            color: $text-color;
            text-align: center;
            font-size: 1em;
          }
        }
      }
    }
  }

  .add-candidate-btn {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333333;
    }
  }

  .add-candidate-form {
    background-color: lighten($accent-color, 15%);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;

    input {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      font-size: 1em;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
    }

    button {
      background-color: var(--primary-color);
      color: $background-color;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }
    }
  }

  .candidates-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    word-break: break-all;

    th,
    td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid $accent-color;

      &:last-child {
        text-align: center;
      }
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    a {
      color:  var(--primary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .actions-column {
      text-align: center;

      .action-select {
        padding: 5px 10px;
        border: 1px solid $accent-color;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
        font-size: 0.9em;
        max-width: 100%;

        &:hover {
          border-color: var(--primary-color);
        }

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgbavar(--primary-color, 0.2);
        }

        option {
          padding: 5px;

          &[value="delete"] {
            color: $error-color;
          }

          &[value="update"] {
            color: var(--primary-color);
          }

          &[value="default"] {
            color: $text-color;
          }

          &[value="addToTrackingBoard"] {
            color: var(--secondary-color);
          }
        }
      }
    }
  }

  .see-more-candidates {
    .see-more-candidates-button {
      background-color: var(--primary-color);
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.3s ease;
      margin: 20px 0px;

      &:hover {
        background-color: #333333;
      }

      i {
        font-size: 0.9rem;
      }
    }
  }
  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    .candidates-table {
      font-size: 0.9em;

      th,
      td {
        padding: 10px;
      }
    }
  }

  .candidate-pool-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .add-candidate-button {
      padding: 10px 20px;
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 600;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }
    }
  }
}

.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .delete-confirmation-dialog {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;

    p {
      margin: 0 0 20px;
      text-align: center;
      font-size: 1.1em;
      color: $text-color;
    }

    .dialog-actions {
      display: flex;
      justify-content: center;
      gap: 12px;

      button {
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        font-weight: 500;
        transition: background-color 0.3s;

        &.cancel-button {
          background-color: #f0f0f0;
          color: $text-color;

          &:hover {
            background-color: #e0e0e0;
          }
        }

        &.delete-button {
          background-color: $error-color;
          color: white;

          &:hover {
            background-color: darken($error-color, 10%);
          }
        }
      }
    }
  }
}
