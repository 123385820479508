@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.subscriptionDashBoard {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0fr 1fr 0.5fr;


  &__title, &__subtitle {
    color:  var(--primary-color);
    margin-bottom: 2rem;
    grid-row-start:1 ;
    align-content: end;
  }

  &__title {
    font-size: 2em;
  }

  &__subtitle {
    font-size: 1.5em;
    margin-top: 30px;
  }

  &__subscription-details {
    background-color: lighten($accent-color, 15%);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 1.5rem;

    &-item {
      width: 100%;
      margin-bottom: 30px;

      display: flex;
      justify-content: space-between;

      &-text{
        font-size: 1.1rem;
        margin-bottom: 0.8rem;

      }
      &-content {
        color:  var(--primary-color);
        font-size: 1.1rem;
      }

    } 
  }

  &-addOns_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    color: var(--text-color);

    .add-on {
      background-color: var(--accent-color);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      border-radius: 5px;
      font-weight: 500;
      transition: background-color 0.3s ease;
      display: flex;
      justify-content: space-between;
      align-content: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: darken($accent-color, 10%);
        scale: 1.03;
        transition: scale 0.3s ease;
      }

      &.active {
        background-color: lighten($secondary-color, 15%);
        color: white;

        &:hover {
          background-color: $secondary-color;
        }
      }

      p {
        align-self: center;
        max-width: 4rem;
        word-wrap: break-word;
      }




      label {
        display: flex;
        align-items: center;
        justify-content: space-between;


        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
    }
  }

  .buttons-container_subscription {
    width: 100%;
    grid-column: 1/3;
    align-self: flex-start;


    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 20px;
  }

  .cancel-subscription {
    
    background-color: var(--secondary-color);
    color: $background-color;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($error-color, 10%);
    }
  }

  .update-subscription, .reactivate-subscription {
    background-color: var(--validate-back-color);
    color: $background-color;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($success-color, 10%);
      scale: 1.03;
      transition: scale 0.3s ease;
    }
  }

  .updatePaymentSubscription {
    background-color: var(--warning-color);
    color: $background-color;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($warning-color, 10%);
      scale: 1.03;
      transition: scale 0.3s ease;
    }
    
  }

  

  .cancellation-notice {
    background-color: lighten($accent-color, 15%);
    padding: 15px;
    border-radius: 5px;
    margin-top: 20px;
    font-weight: bold;
    color: var(--secondary-color);
  }

  &.loading, &.error {
    text-align: center;
    font-size: 1.2em;
    padding: 40px;
  }

  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }

    h3 {
      font-size: 1.3em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    h3 {
      font-size: 1.2em;
    }

    .add-ons {
      grid-template-columns: 1fr;
    }
  }
}

