@import "/src/components/Variables.scss";
@import "/src/styles/breakpoints.scss";

.assessment-page-analysis {
  font-family: "Arial", sans-serif;
  margin: 20px auto;
  overflow-y: scroll;
  height: 30rem;

  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 25px;

  .assessment-page-analysis {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  
    h1 {
      font-size: 24px;
      color: #333;
    }
  
    .close-btn {
      background: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #666;
  
      &:hover {
        color: #ff4d4d;
      }
    }
  }
  
  .loading-message,
  .error-message {
    text-align: center;
    font-size: 18px;
    color: #666;
  }
  
  .performance-summary {
    .summary-cards {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
  
      .card {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 30%;
  
        h4 {
          font-size: 18px;
          color: #333;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 20px;
          font-weight: bold;
          color: #28a745;
        }
      }
    }
  }
  
  .evaluation-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
  
    th, td {
      padding: 16px;
      text-align: left;
      border: none;
      border-bottom: 1px solid #eee;
    }
  
    th {
      background-color: #6920b0;
      color: white;
      font-weight: 600;
      font-size: 15px;
      white-space: nowrap;
  
      &:first-child {
        padding-left: 24px;
      }
  
      &:last-child {
        padding-right: 24px;
      }
    }
  
    tr.correct {
      background-color: rgba(75, 181, 67, 0.05);
      
      td {
        color: #2d8a25;
        font-weight: 500;
      }
  
      &:hover {
        background-color: rgba(75, 181, 67, 0.1);
      }
    }
  
    tr.incorrect {
      background-color: rgba(255, 76, 76, 0.05);
      
      td {
        color: #d32f2f;
        font-weight: 500;
      }
  
      &:hover {
        background-color: rgba(255, 76, 76, 0.1);
      }
    }
  
    td {
      font-size: 15px;
      line-height: 1.6;
      padding: 20px 16px;
  
      &:first-child {
        padding-left: 24px;
        font-weight: 600;
        color: #333;
        max-width: 300px;
      }
  
      &:last-child {
        padding-right: 24px;
        text-align: center;
        font-size: 18px;
      }
    }
  
    tbody tr {
      transition: all 0.2s ease;
  
      &:last-child td {
        border-bottom: none;
      }
  
      &:hover {
        background-color: rgba(105, 32, 176, 0.05);
      }
    }
  }
  
  .global-analysis {
    margin-top: 20px;
    padding: 20px;
    background: #f1f1f1;
    border-radius: 10px;
  
    h3 {
      font-size: 20px;
      color: #333;
    }
  
    .analysis-item {
      margin-bottom: 15px;
      padding: 10px;
      background: #fff;
      border-left: 5px solid #4caf50;
      border-radius: 5px;
    }
  
    .analysis-item h4 {
      font-size: 18px;
      color: #333;
    }
  
    .analysis-item p {
      font-size: 16px;
      color: #666;
    }
  }
  
  .analysis-body {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    .question-block {
      margin-bottom: 25px;
      padding: 15px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      background: #f8f9fa;

      .question-text {
        font-size: 16px;
        font-weight: 600;
        color: #2c3e50;
        margin-bottom: 15px;
      }

      .answer-block {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        border-radius: 6px;
        background: white;

        &.candidate-answer {
          border-left: 4px solid #3498db;
          margin-top: 15px;
        }

        &.correct-answer {
          color: #2ecc71;
        }

        &.incorrect-answer {
          color: #e74c3c;
        }

        .answer-icon {
          margin-right: 10px;
          font-size: 18px;
        }

        .answer-text {
          flex-grow: 1;
        }
      }

      .explanation {
        margin-top: 10px;
        padding: 10px;
        background: #f1f8ff;
        border-radius: 6px;
        color: #546e7a;
        font-style: italic;
      }
    }
  }
}

.analysis-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .analysis-content {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 2rem;
    position: relative;

    &.loading,
    &.error {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
    }

    .analysis-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      border-bottom: 2px solid #eee;

      h2 {
        font-size: 1.5rem;
        color: #333;
        margin: 0;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #666;
        padding: 0.5rem;

        &:hover {
          color: #333;
        }
      }

      .back-button {
        background: #6920b0;
        color: white;
        border: none;
        padding: 12px 24px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 8px;

        &:before {
          content: "←";
          font-size: 18px;
        }

        &:hover {
          background: #551990;
          transform: translateX(-3px);
          box-shadow: 0 4px 8px rgba(105, 32, 176, 0.2);
        }

        &:active {
          transform: translateX(-1px);
        }
      }
    }

    .analysis-body {
      .candidate-info {
        margin-bottom: 2rem;
        
        h3 {
          font-size: 1.3rem;
          margin-bottom: 0.5rem;
        }

        .email {
          color: #666;
          margin-bottom: 1rem;
        }

        .score {
          display: inline-block;
          padding: 0.5rem 1rem;
          background: #6920b0;
          color: white;
          border-radius: 20px;
          font-weight: 500;
        }
      }

      .analysis-sections {
        section {
          margin-bottom: 2rem;

          h4 {
            color: #333;
            font-size: 1.1rem;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            border-bottom: 2px solid #6920b0;
          }

          .detail-item {
            margin-bottom: 1.5rem;

            h5 {
              color: #6920b0;
              margin-bottom: 0.5rem;
            }

            p {
              color: #666;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}

.candidate-answers {
  margin-top: 2.5rem;
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  
  h4 {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #6920b0;
    font-weight: 600;
  }
}

// Add smooth scrolling for the content
.analysis-content {
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #6920b0 #f0f0f0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6920b0;
    border-radius: 4px;
    
    &:hover {
      background-color: #551990;
    }
  }
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.language-selector {
  position: relative;

  .translate-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.5rem;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .language-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .language-option {
      display: block;
      width: 100%;
      padding: 8px 16px;
      text-align: left;
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }

      &.active {
        background: #6920b0;
        color: white;
      }
    }
  }
}
