@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.hiring-request-form {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 100px;

  h2 {
    font-size: 2em;
    color:  var(--primary-color);
    margin-bottom: 20px;
    margin-left: 20px;
  }

  form {
    background-color: lighten($accent-color, 15%);
    padding: 20px;
    border-radius: 5px;

    input[type="text"],
    input[type="email"],
    input[type="number"],
    select,
    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      font-size: 1em;
      border: 1px solid  var(--primary-color);
      border-radius: 5px;
      background-color: $background-color;
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }

    select {
      appearance: none;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: right 10px top 50%;
      background-size: 12px auto;
    }

    div {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
      }

      input[type="radio"] {
        margin-right: 5px;
      }
    }

    button[type="submit"] {
      background-color:  var(--primary-color);
      color: $background-color;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }

      &:disabled {
        background-color: #808080;
        cursor: not-allowed;
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    form {
      padding: 15px;

      input[type="text"],
      input[type="email"],
      input[type="number"],
      select,
      textarea {
        font-size: 0.9em;
        padding: 8px;
      }

      button[type="submit"] {
        font-size: 0.9em;
        padding: 8px 16px;
      }
    }
  }
}
