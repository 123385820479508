@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.received-applications {
  background-color: $background-color;
  padding: 2rem;

  display: grid;
  grid-template-columns: repeat(8, 1fr);

  .modal-ReceivedApplications {

    &-modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100000;
    }

    &-refuseCandidate {
      background-color: white;
      border-radius: 5px;
      width: 50%;
      padding: 2rem;
    }

    &-title {
      color: $text-color;
      margin-bottom: 2rem;

    }

    &-textName {
      color: $text-color;
      margin-bottom: 1rem;
      padding: 0 1rem;
    }

    &-candidateName {
      font-weight: bold;
      color: var(--primary-color);
      padding: 0 1rem;
      line-height: 1.5;
    }

    &-text {
      color: $text-color;
      margin-bottom: 1rem;
      padding: 0 1rem;
    }

    &-email {
      max-width: 90%;
      margin: 2rem auto;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
      padding: 2rem 1rem;

      &-content {
        line-height: 1.5;
        margin-bottom: 1rem;
      }

      &-end {
        margin-top: 2rem;
      }
    }

    &-greating {
      color: $text-color;
      margin-bottom: 2rem;
    }

    &-actions {
      display: flex;
      justify-content: space-around;
      
      &-buttons {
        color: white;
        background-color: var(--primary-color);
        padding: 0.5rem 1.5rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
        font-size: 1rem;
        text-align: center;
        display: inline-block;

        &:hover {
          background-color: #333333;
          transform: translateY(-2px);
        }

        &:active {
          background-color: #333333;
          transform: translateY(0);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 3px #808080;
        }
      }
    }
  }

  &-title-midle {
    grid-column: 1 / span 8;
    margin-top: 1rem;
    color: $text-color;

  }

  &__middle-title {
    margin-top: 1rem;
    grid-column: span 2;
  }

  &__header {

    grid-column: 1 / span 8;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    top: 0;
    z-index: 60;
    position: sticky;
    background-color: $background-color;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-color;

    &-title {
      font-size: 2rem;
      font-weight: bold;
      color: var(--primary-color);
      margin-bottom: 1rem;
    }

    &-filters {
      display: flex;
      width: 100%;
      justify-content: center;


    }

    &-filter {

      // &--jobs {
      //   width: 20%;
      // }

      &--candidates {
        width: 80%;

        .received-applications__header-filter-candidate-search {
          width: 98%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          outline: none;
          font-size: 1rem;

          &:focus {
            border-color: #2684ff;
          }
        }

        .received-applications__header-filter-candidate-results {
          list-style: none;
          margin: 0;
          padding: 0;
          border: 1px solid #ccc;
          border-radius: 4px;
          max-height: 150px;
          overflow-y: auto;
          position: absolute;
          top: 100%;
          background: white;
          width: 100%;
          z-index: 10;

          .candidate-result {
            padding: 0.5rem;
            cursor: pointer;
            transition: background 0.2s;

            &:hover {
              background: #f0f0f0;
            }
          }

          .no-candidate-results {
            padding: 0.5rem;
            text-align: center;
            color: #888;
          }
        }
      }



      &-dropdown {
        display: inline-block;
        cursor: pointer;
        user-select: none;
        color: $text-color;
        background-color: $background-color;
        border-radius: 5px;
        position: relative;
        border: 1px solid #ccc; // Default border color

        &.has-selection {
          border-color: #2684FF; // Persistent border color when selected
        }

        &-search {
          padding: 0.5rem 1rem;
          border: none; // Remove inner border since the parent handles it
          border-radius: 4px;
          background-color: $background-color;
          color: $text-color;
          width: 100%;
          font-size: 1rem;
          box-sizing: border-box;
          outline: none;

          &::placeholder {
            color: lighten($text-color, 40%);
          }

          &:focus {
            border-color: #2684FF;
            background-color: white;
            color: darken($text-color, 5%);
          }
        }

        .chevron-icon {
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.2rem;
          color: $border-color;
          transition: transform 0.3s ease;

          &.rotated {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        &-options {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: $background-color;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          opacity: 0;
          visibility: hidden;
          transform: translateY(-10px);
          transition: opacity 0.3s ease, transform 0.3s ease;
          max-height: 10rem;
          overflow-y: auto;
          z-index: 10;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #333333;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #808080;
          }

          &-option {
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: background-color 0.2s ease;
            color: $text-color;

            &:hover {
              background-color: lighten(#2684FF, 10%);
              color: white;
            }

            &.is-selected {
              background-color: lighten(#2684FF, 10%);
              color: white;
            }
          }

          .dropdown-no-options {
            padding: 0.5rem 1rem;
            color: lighten(white, 30%);
            text-align: center;
          }
        }

        // Active state : afficher le menu avec transition
        &.is-active &-options {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
          z-index: 50;

          &:focus {
            background-color: lighten(#2684FF, 10%);
            color: white;
          }
        }
      }
    }
  }

  &__content {
    grid-column: 1 / span 8;

    margin: 1rem auto 0 auto;
    border: 2px solid $border-color;
    height: 25rem;
    overflow-y: auto;
    display: grid;
    grid-template-rows: 1fr 5fr;

    // Personnalisation de la barre de scroll
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1; // Couleur de fond du track
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color); // Couleur de la poignée de défilement
      border-radius: 10px;
      border: 2px solid #f1f1f1; // Espacement autour de la poignée
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #333333; // Changement de couleur au survol
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent; // Pour les coins des barres de défilement
    }


    &-candidate {

      &-list {
        grid-column: 1 / span 8;
        padding: 0.5rem 1rem;

        &-header {
          grid-column: 1 / span 8;
          background-color: var(--primary-color);
          padding: 0.5rem 1rem;
          color: white;

          display: grid;
          grid-template-columns: repeat(8, 1fr);
          align-items: center;
          text-align: center;

          position: sticky;
          top: 0;
          z-index: 10;


          div {
            overflow-wrap: break-word;
            word-wrap: break-word;
            padding: 0.5rem;
          }

        }

        &-row {

          position: relative;

          display: grid; // Active grid sur le parent
          grid-template-columns: repeat(8, 1fr); // Chaque item occupe 1fr sur 7 colonnes

          align-items: center;
          border-bottom: 1px solid $border-color;

          &--item {
            margin: 1rem 0;
            grid-column: span 1; // Chaque enfant occupe exactement 1 colonne (1fr)
            text-align: center;
            white-space: normal; // Empêche le texte de passer à la ligne
            overflow-wrap: break-word; // Casse les mots si besoin
            word-wrap: break-word; // Compatibilité pour d'anciens navigateurs

            p {
              display: block;
              margin: 0; // Évite les marges par défaut des paragraphes
              word-break: break-all;
            }

            &-buttonContainer {
              margin: 0;

              button {
                padding: 5px 10px;
                border: 1px solid#acdaff;
                border-radius: 4px;
                background-color: $background-color;
                cursor: pointer;
                font-size: 0.9rem;
                min-width: 150px;
                display: flex;
                justify-content: space-between;

              }
            }

          }
        }
      }

      &-action-dropdown-menu {
        position: absolute;
        /* Superpose le menu par rapport au parent */
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* Ombre pour distinguer le menu */
        z-index: 100;
        padding: 0.5rem 0;
        width: max-content;
        min-width: 150px;


        &_action-item {
          padding: 0.5rem 1rem;
          cursor: pointer;
          transition: background-color 0.2s;

          &:hover {
            background-color: #f0f0f0;
            cursor: pointer;
          }
        }
      }

      &-no-candidates {
        color: $text-color;
        font-size: 1.2rem;
        margin-top: 1rem;
        text-align: center;
        width: 100%;
      }
    }
  }
}