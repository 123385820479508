@import '../../Variables.scss'; 
@import '/src/styles/breakpoints.scss'; 

.generate-email-container {
  font-family: $font-family;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;

  .generate-email-box {
    background-color: #ffffff;
    width: 90%;
    max-width: 600px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 2rem;

    h1 {
      color:  var(--primary-color);
      margin-bottom: 2rem;
      text-align: center;
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #444;
        font-weight: 500;
      }

      select {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
      }
    }

    .generate-button {
      width: 100%;
      padding: 1rem;
      background-color:  var(--primary-color);
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #dedbe2;
        color:  var(--primary-color);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }

    .return-button {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color:  var(--primary-color);
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 768px) {
  .generate-email-container {
    padding: 0.5rem;

    .generate-email-box {
      width: 95%;
      padding: 1.5rem;
    }
  }
}

.generated-email-container {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;

  .generated-email-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h3 {
      color:  var(--primary-color);
      margin: 0;
    }

    .email-actions {
      display: flex;
      gap: 0.5rem;

      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
          font-size: 1.1rem;
        }
      }

      .copy-button {
        background-color: #e9ecef;
        color: $text-color;

        &:hover {
          background-color: #dee2e6;
        }

        &.success {
          background-color: #28a745;
          color: white;
        }
      }

      .save-button {
        background-color:  var(--primary-color);
        color: white;

        &:hover {
          background-color: $accent-color;
        }

        &.success {
          background-color: #28a745;
          color: white;
        }
      }

      .delete-button {
        background-color: #dc3545;
        color: white;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }

  .email-content {
    h4 {
      color: $text-color;
      margin-bottom: 1rem;
    }

    .email-body {
      background-color: white;
      padding: 1.5rem;
      border-radius: 4px;
      border: 1px solid #dee2e6;
      white-space: pre-wrap;
      line-height: 1.6;
    }
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
  }

  span {
    color: white;
    font-size: 1rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.generate-button {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;

    &:hover {
      background-color: #6920b0;
    }
  }
}