.assessment-page-analysis {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      h1 {
        color: #333;
        margin: 0;
      }
  
      .close-btn {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: #666;
        cursor: pointer;
        padding: 0.5rem;
        
        &:hover {
          color: #333;
        }
      }
    }
  
    .loading-message, .error-message {
      text-align: center;
      padding: 2rem;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
  
    .error-message {
      color: #dc3545;
    }
  
    .analysis-report {
      background: white;
      border-radius: 8px;
      padding: 2rem;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
      h2 {
        color: #333;
        margin-bottom: 2rem;
        padding-bottom: 0.5rem;
        border-bottom: 2px solid #6920b0;
      }
  
      section {
        margin-bottom: 3rem;
  
        h3 {
          color: #495057;
          margin-bottom: 1.5rem;
        }
      }
  
      .performance-summary {
        .summary-cards {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 1.5rem;
  
          .card {
            background: #f8f9fa;
            padding: 1.5rem;
            border-radius: 8px;
            text-align: center;
  
            h4 {
              color: #6920b0;
              margin-bottom: 0.5rem;
            }
  
            p {
              font-size: 1.5rem;
              font-weight: bold;
              color: #333;
            }
          }
        }
      }
  
      .skills-analysis {
        .skills-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 1.5rem;
  
          .skill-card {
            background: #f8f9fa;
            padding: 1.5rem;
            border-radius: 8px;
  
            h4 {
              color: #495057;
              margin-bottom: 1rem;
            }
  
            .skill-score-bar {
              background: #e9ecef;
              border-radius: 20px;
              height: 24px;
              overflow: hidden;
  
              .skill-score-progress {
                background: #6920b0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 1rem;
                color: white;
                font-weight: 500;
                transition: width 0.3s ease;
              }
            }
          }
        }
      }
  
      .global-analysis {
        .analysis-item {
          background: #f8f9fa;
          padding: 1.5rem;
          border-radius: 8px;
          margin-bottom: 1rem;
  
          h4 {
            color: #6920b0;
            margin-bottom: 0.5rem;
          }
  
          p {
            color: #495057;
            line-height: 1.6;
          }
        }
      }
  
      .detailed-evaluation {
        .evaluation-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 1rem;
  
          th, td {
            padding: 1rem;
            text-align: left;
            border-bottom: 1px solid #dee2e6;
          }
  
          th {
            background: #f8f9fa;
            color: #495057;
            font-weight: 600;
          }
  
          tr {
            &.correct {
              background-color: #f8fff9;
              td:first-child { border-left: 3px solid #28a745; }
            }
  
            &.incorrect {
              background-color: #fff8f8;
              td:first-child { border-left: 3px solid #dc3545; }
            }
          }
        }
      }
    }
  } 