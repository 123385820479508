 @import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.talexa-page {
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
}

.chat-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  visibility: hidden;
  width: 250px;
  background-color: white;
  border-right: 1px solid $accent-color;
  padding: 1rem;

  .new-chat-button {
    width: 100%;
    padding: 12px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
      background-color: #333333;
    }

    .plus-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
}

.title-banner {
  background-color: var(--primary-color);
  padding: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.talexa-title {
  font-size: 2.5rem;
  color: white !important;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.chat-interface {
  flex: 1;
  margin: 0 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  scroll-behavior: smooth;
}

.message {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 12px;
}

.user {
  align-self: flex-end;
  background-color: var(--primary-color);
  color: white;
}

.assistant {
  align-self: flex-start;
  background-color: $accent-color;
  color: $text-color;
}

.message-content {
  line-height: 1.4;
}

.typing {
  display: flex;
  gap: 4px;
  padding: 8px;
}

.typing-dot {
  width: 6px;
  height: 6px;
  background: var(--secondary-color);
  border-radius: 50%;
  animation: typing 1s infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

.chat-input {
  padding: 20px;
  border-top: 1px solid $accent-color;
  display: flex;
  gap: 12px;

  input {
    flex: 1;
    padding: 12px 16px;
    border: 2px solid $accent-color;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: var(--primary-color);
    }

    &:disabled {
      background-color: lighten($accent-color, 15%);
      cursor: not-allowed;
    }
  }

  button {
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover:not(:disabled) {
      background-color: #333333;
    }

    &:disabled {
      background-color: #808080;
      cursor: not-allowed;
    }
  }
}

@keyframes typing {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-4px);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include mobile {
  .talexa-page {
    .title-banner {
      padding: 1rem;
    }

    .chat-interface {
      .chat-messages {
        .message {
          max-width: 85%;
        }
      }

      .chat-input {
        padding: 15px;

        input {
          padding: 10px 14px;
        }

        button {
          padding: 10px 20px;
        }
      }
    }

    .sidebar {
      display: none;
    }
  }
}

.conversations-list {
  margin-top: 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 200px);

  .conversation-item {
    padding: 0.75rem;
    margin: 0.5rem 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &.active {
      background-color: var(--primary-color);
      color: white;
    }
  }
}

.file-upload-section {
  padding: 20px;
  border-top: 1px solid $accent-color;
  background-color: #f8f9fa;
  display: flex;
  gap: 15px;
  align-items: center;

  .file-input-container {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;

    .select-files-button {
      padding: 12px 24px;
      background-color: var(--secondary-color);
      color: white;
      border: none;
      border-radius: 8px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      transition: all 0.3s ease;

      &:hover {
        background-color: #333333;
        transform: translateY(-1px);
      }

      i {
        font-size: 16px;
      }
    }

    .file-info {
      flex: 1;
      color: $text-color;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        color: #28a745;
      }

      .selected-files {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .file-item {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          background-color: #f8f9fa;
          border-radius: 4px;

          i {
            color: var(--secondary-color);
          }

          span {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .remove-file {
            background: none;
            border: none;
            color: $text-color;
            cursor: pointer;
            padding: 4px;

            &:hover {
              color: #dc3545;
            }
          }
        }
      }
    }

    .file-input.hidden {
      display: none;
    }
  }

  .analyze-button {
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 150px;
    justify-content: center;
    transition: all 0.3s ease;

    &:hover:not(:disabled) {
      background-color: #333333;
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      background-color: #808080;
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }

    i {
      font-size: 16px;
    }
  }
}

@include mobile {
  .file-upload-section {
    flex-direction: column;
    padding: 15px;

    .file-input-container {
      width: 100%;
      flex-direction: column;

      .select-files-button {
        width: 100%;
      }

      .file-info {
        padding: 8px 0;
      }
    }

    .analyze-button {
      width: 100%;
    }
  }
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  color: #495057;

  &:hover {
    background: #e9ecef;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
}

.action-icon {
  font-size: 1.2rem;
}

.chat-messages {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  padding: 20px;
}

.message {
  &.assistant {
    .message-content {
      background-color: #f5f7f9;
      border-radius: 12px;
      padding: 16px;
      margin: 8px 0;
      max-width: 85%;
      line-height: 1.6;

      .message-paragraph {
        margin: 12px 0;
      }

      .message-list {
        margin: 12px 0;
        padding-left: 20px;
      }

      .message-list-item {
        margin: 8px 0;
      }

      .message-bold {
        color: #2c5282;
        font-weight: 600;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 16px 0 8px;
        color: #2d3748;
      }

      ul,
      ol {
        margin: 8px 0;
        padding-left: 24px;
      }

      code {
        background-color: #edf2f7;
        padding: 2px 6px;
        border-radius: 4px;
        font-family: monospace;
      }

      blockquote {
        border-left: 4px solid #cbd5e0;
        padding-left: 16px;
        margin: 16px 0;
        color: #4a5568;
      }

      table {
        border-collapse: collapse;
        width: 100%;
        margin: 16px 0;

        th,
        td {
          border: 1px solid #e2e8f0;
          padding: 8px;
          text-align: left;
        }

        th {
          background-color: #f7fafc;
        }
      }
    }
  }
}

.candidate-dropdown-container {
  width: 100%;
  margin: 15px 0;
  position: relative;

  .candidate-select {
    width: 100%;
    padding: 12px 35px 12px 12px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    transition: all 0.2s ease;

    &:hover {
      border-color: #cbd5e0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:focus {
      outline: none;
      border-color: #4299e1;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
    }

    option {
      padding: 12px;
      
      &:first-child {
        color: #718096;
        font-style: italic;
      }
    }
  }
}

.add-pipeline-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004085;
  }
}
