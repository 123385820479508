@import "../../../Variables.scss";
@import "../../../../styles/breakpoints.scss";

.ai-phone-interview-form {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;

  .form-header {
    margin-bottom: 30px;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 2em;
        color: var(--primary-color);
        margin: 0;
      }

      .back-button {
        padding: 10px 20px;
        background-color: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        transition: all 0.3s ease;

        &:hover {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-weight: 600;
        color: var(--secondary-color);
      }

      input,
      select {
        width: 100%;
        padding: 12px;
        border: 1px solid $accent-color;
        border-radius: 5px;
        background-color: white;
        font-size: 0.95em;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba var(--primary-color, 0.1);
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      .submit-button {
        padding: 12px 24px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        background-color: var(--primary-color);
        border: none;
        color: white;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #333333;
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .form-header h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .form-header h2 {
      font-size: 1.6em;
    }

    form {
      gap: 15px;

      .form-group {
        input,
        select {
          font-size: 0.9em;
          padding: 10px;
        }
      }

      .form-actions {
        button {
          width: 100%;
          padding: 12px 15px;
        }
      }
    }
  }

  .bottom-navigation {
    margin-top: 20px;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    .back-button {
      padding: 12px 24px;
      background-color: transparent;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 5px;
      cursor: pointer;
      font-weight: 600;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--primary-color);
        color: white;
      }
    }
  }
}
