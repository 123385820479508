@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.edit-job-opening {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;

  .edit-job-opening-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin: 0;
    }
  }

  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px;

    .hour-container {
      grid-column: 1 / -1;
      display: flex;
      gap: 2rem;

      .form-group {
        width: 50%;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      &:has(textarea),
      &:has([name="company_description"]),
      &:has([name="mission"]),
      &:has([name="responsibilities"]),
      &:has([name="requirements"]),
      &:has([name="offer_content"]),
      &:has([name="hiring_process"]) {
        grid-column: 1 / -1;
      }

      label {
        font-weight: 600;
        color: var(--secondary-color);
      }

      input, select, textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid $accent-color;
        border-radius: 5px;
        background-color: white;
        font-size: 0.95em;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(#808080, 0.1);
        }
      }

      textarea {
        min-height: 120px;
        resize: vertical;
      }

      &.checkbox-group {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        input[type="checkbox"] {
          width: auto;
          margin: 0;
        }
      }
    }

    button[type="submit"] {
      grid-column: 1 / -1;
      padding: 15px;
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .edit-job-opening-header h2 {
      font-size: 1.8em;
    }

    form {
      grid-template-columns: 1fr;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .edit-job-opening-header h2 {
      font-size: 1.6em;
    }

    form {
      .form-group {
        input, select, textarea {
          font-size: 0.9em;
        }
      }

      button[type="submit"] {
        font-size: 1em;
        padding: 12px 15px;
      }
    }
  }

  .form-actions {
    grid-column: 1 / -1;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;

    button {
      padding: 12px 24px;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;

      &.cancel-button {
        background-color: transparent;
        border: 1px solid $accent-color;
        color: $text-color;

        &:hover {
          background-color: $accent-color;
          color: white;
        }
      }

      &.submit-button {
        background-color: var(--primary-color);
        border: none;
        color: white;

        &:hover {
          background-color: #333333;
        }
      }
    }
  }

  .checkbox-group {
    display: flex;
    gap: 20px;

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: normal;
      cursor: pointer;

      input[type="checkbox"] {
        width: auto;
        margin: 0;
      }
    }
  }
}
