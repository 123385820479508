@import '../../Variables.scss';

.addons-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.visible {
    opacity: 1;
    visibility: visible;
    animation: addonsFadeIn 0.3s ease forwards;
  }

  &.hidden {
    animation: addonsFadeOut 0.3s ease forwards;
  }
}

.addons-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &.visible {
    transform: translateY(0);
    animation: addonsFadeIn 0.3s ease forwards;
  }

  &.hidden {
    animation: addonsFadeOut 0.3s ease forwards;
  }
}

.addons-modal-actions {
  margin-top: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.addons-modal-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid $border-color;
  border-radius: 10px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-color: $primary-color;
  }

  p {
    flex: 1;
    font-size: 1rem;
    color: darken($text-color, 10%);
  }

  &-price_container {
    text-align: right;

    .price {
      font-weight: bold;
      font-size: 1rem;
      color: $primary-color;
    }

    .credit {
      font-size: 0.8rem;
      color: darken($text-color, 20%);
    }
  }
}

.addons-modal-price_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 0.5rem;
}

.addons-modal-footer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #4c2387; /* Exemple de couleur */
    color: white;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #32165c; /* Plus foncé au survol */
    }
  }
}

@keyframes addonsFadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes addonsFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
