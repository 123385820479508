@import '../../../Variables.scss';
@import '/src/styles/breakpoints.scss';


.updateSubscriptionModal {

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .button-disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }
  
  
  &-container {
    width: max-content;
    background-color: $background-color;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;


    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 30px;

      &__quantity {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
      }


      &-addOn-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        &__item {
          display: flex;
          justify-content: space-between;
          background-color: var(--accent-color);
          padding: 2rem;
          border-radius: 5px;
          border: 1px solid transparent;
          transition: background-color 0.3s ease;
          align-content: center;
          align-items: center;
          cursor: pointer;


          &:hover {
            background-color: lighten($accent-color, 10%);
          }



          &-input {
            width: 20%;
            height: 1rem;
          }

          &-text {
            width: 80%;
            word-break: break-all;
          }
        }


      }
    }

    &__buttons-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;


      &-button--cancel {
        background-color: var(--secondary-color);
        color: $background-color;
        border: none;
        padding: 10px 20px;
        font-size: 1em;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($error-color, 10%);
        }
      }

      &-button--confirm {
        background-color: var(--primary-color);
        color: $background-color;
        border: none;
        padding: 10px 20px;
        font-size: 1em;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($success-color, 10%);
        }
      }
    }
  }

  &-confirmationModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      width: max-content;
      background-color: $background-color;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      &-text {
        font-size: 1rem;
        line-height: 2;
      }

      &-buttons {
        width: 100%;
        align-self: flex-start;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-top: 20px;

        &-cancel {
          background-color: var(--secondary-color);
          color: $background-color;
          border: none;
          padding: 10px 20px;
          font-size: 1em;
          cursor: pointer;
          border-radius: 5px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken($error-color, 10%);
          }
        }

        &-confirm {
          background-color: var(--primary-color);
          color: $background-color;
          border: none;
          padding: 10px 20px;
          font-size: 1em;
          cursor: pointer;
          border-radius: 5px;
          transition: background-color 0.3s ease;
      
          &:hover {
            background-color: darken($success-color, 10%);
          }
        }
      }
    }
  }
}