@import './Variables.scss';

.thank-you-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;

  .thank-you-container {
    text-align: center;
    background: white;
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    color: $text-color;

    .success-icon {
      font-size: 4rem;
      color: #4CAF50;
      margin-bottom: 1.5rem;
    }

    h1 {
      color: #333;
      margin-bottom: 1rem;
    }

    p {
      color: #666;
      margin-bottom: 2rem;
      line-height: 1.6;
    }

    .back-button {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #1976D2;
      }
    }
  }
  .footer {
    width: 100%;
    padding: 3rem 0 2rem 0;
  }

  .nav-links {
    top:3rem;
  }
  .logo-container {
    margin-top: 1rem;
  }
} 