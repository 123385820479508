@import '../../Variables.scss';
@import '../../../styles/breakpoints.scss';

.dashboard-header {
  background-color: $primary-color;
  padding: 1rem;

  nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include mobile {
      flex-direction: column;
    }

    .menu-item {
      position: relative;
      margin-right: 1rem;

      @include mobile {
        margin-bottom: 0.5rem;
      }

      a, button {
        color: $background-color;
        text-decoration: none;
        font-weight: 600;
        padding: 0.5rem 1rem;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 1rem;

        &:hover {
          color: $accent-color;
        }
      }

      .dropdown {
        position: relative;

        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: $background-color;
          border-radius: 4px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          display: none;
          z-index: 10;

          &.active {
            display: block;
          }

          li {
            a {
              color: $text-color;
              padding: 0.5rem 1rem;
              display: block;
              white-space: nowrap;

              &:hover {
                background-color: $accent-color;
              }
            }
          }
        }
      }
    }
  }
}
