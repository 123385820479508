@import "../../../Variables.scss";
@import "../../../../styles/breakpoints.scss";

.ai-phone-interview-list {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  .ai-phone-interview-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
    }

    p {
      color: $text-color;
      font-size: 1rem;
    }

    .create-interview-button {
      padding: 10px 20px;
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }

      svg {
        font-size: 14px;
      }
    }
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: $text-color;
  }

  .no-interviews {
    text-align: center;
    padding: 2rem;
    background-color: lighten($accent-color, 15%);
    border-radius: 8px;
    color: $text-color;
  }

  .interview-table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid $accent-color;

      &:last-child {
        text-align: center;
      }
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    .job-link {
      color: var(--primary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      gap: 0.5rem;

      .view-button,
      .update-button,
      .delete-button {
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        text-decoration: none;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: background-color 0.3s ease;
        cursor: pointer;
        min-width: 120px;
        justify-content: center;
        white-space: nowrap;

        i {
          font-size: 0.9rem;
        }
      }

      .view-button {
        background-color: var(--primary-color);
        color: white;

        &:hover {
          background-color: #333333;
        }
      }

      .update-button {
        background-color: var(--secondary-color);
        color: white;

        &:hover {
          background-color: #333333;
        }
      }

      .delete-button {
        background-color: $error-color;
        color: white;

        &:hover {
          background-color: darken($error-color, 10%);
        }
      }
    }
  }

  .actions-column {
    text-align: center;

    .action-select {
      padding: 5px 10px;
      border: 1px solid $accent-color;
      border-radius: 4px;
      background-color: white;
      cursor: pointer;
      font-size: 0.9em;
      min-width: 150px;

      &:hover {
        border-color: var(--primary-color);
      }

      &:focus {
        outline: none;
        border-color: var(--primary-color);
        box-shadow: 0 0 0 2px rgba(#808080, 0.2);
      }

      option {
        padding: 5px;

        &[value="default"] {
          color: $text-color;
        }

        &[value="view"],
        &[value="details"] {
          color: var(--primary-color);
        }

        &[value="edit"],
        &[value="update"] {
          color: var(--secondary-color);
        }

        &[value="delete"] {
          color: $error-color;
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    .interview-table {
      font-size: 0.9em;

      th, td {
        padding: 10px;
      }
    }
  }
}