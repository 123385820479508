@import "/src/components/Variables.scss";
@import "/src/styles/breakpoints.scss";


.send-assessment-modal {

  height: 20rem;

  &__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    &-candidate-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

    }
    &-candidate-subtitle {
      font-size: 1.2rem;
    }
    &-candidate-container {
      width: 100%;
      max-height: 10rem;
      overflow-y: auto;
      padding: 1rem;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
        padding: 1rem;

        &:hover {
          background-color: var(--background-color-hover);
          scale: 1.02;
          transition: all 0.3s;
        }

        &-name {
          display: block;
          margin-bottom: 0;
        }

        p{
          margin-bottom: 0;
        }

        &-button {
          background-color: var(--primary-color);
          color: var(--background-color);
          border-radius: 5px;
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          font-weight: 600;
          transition: all 0.3s;

          &:hover {
            // background-color: var(--primary-color-hover);
            // color: var(--primary-color);
            // border: none;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
            transition: all 0.3s;
            // font-size: 0.9rem;
            // font-weight: 600;
            scale: 1.02;
          }
        }
      }
    }
  }

  &__content-search-input {

    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-size: 1rem;
    transition: all 0.3s;

    &:focus {
      outline: none;
      border-color: var(--primary-color);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

  }
}