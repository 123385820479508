 
$primary-color: #6920b0;
$secondary-color: #fa41cc;
$accent-color: #acdaff;
$background-color: #ffffff;
$back-color: #787878;
$text-color: #333333;
$border-color: #dee2e6;
$font-family: 'Montserrat', sans-serif;
$danger-color: #dc3545;
$text-color-light: #64748b;
$success-color: #34c759;
$warning-color: #ff9500;
$error-color: #dc3545;
$validate-back-color: #009916;

 
:root {
  // Main colors
  --primary-color: #{$primary-color};
  --primary-color-dark: #{darken($primary-color, 10%)};
  --secondary-color: #{$secondary-color};
  --secondary-color-dark: #{darken($secondary-color, 10%)};
  
  // Other colors
  --accent-color: #{$accent-color};
  --background-color: #{$background-color};
  --back-color: #{$back-color};
  --text-color: #{$text-color};
  --border-color: #{$border-color};
  --danger-color: #{$danger-color};
  --text-color-light: #{$text-color-light};
  --success-color: #{$success-color};
  --warning-color: #{$warning-color};
  --error-color: #{$error-color};
  --validate-back-color: #{$validate-back-color};
}










// Shadows
$box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 4px 6px rgba(0, 0, 0, 0.1);

// Border Radius
$border-radius: 8px;
$border-radius-lg: 12px;
