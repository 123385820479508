.assessment-page-analysis {
    font-family: "Arial", sans-serif;
    margin: 20px auto;
    overflow-y: scroll;
    height: 30rem;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 25px;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #ddd;
      padding-bottom: 10px;
      margin-bottom: 20px;
  
      h1 {
        font-size: 24px;
        color: #333;
      }
  
      .close-btn {
        background: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #666;
  
        &:hover {
          color: #ff4d4d;
        }
      }
    }
  
    .loading-message,
    .error-message {
      text-align: center;
      font-size: 18px;
      color: #666;
    }
  
    .performance-summary {
      .summary-cards {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
  
        .card {
          background: #fff;
          padding: 15px;
          border-radius: 10px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
          text-align: center;
          width: 30%;
  
          h4 {
            font-size: 18px;
            color: #333;
            margin-bottom: 10px;
          }
  
          p {
            font-size: 20px;
            font-weight: bold;
            color: #28a745;
          }
        }
      }
    }
  
    .global-analysis {
      margin-top: 20px;
      padding: 20px;
      background: #f1f1f1;
      border-radius: 10px;
  
      .analysis-item {
        margin-bottom: 15px;
        padding: 10px;
        background: #fff;
        border-left: 5px solid #4caf50;
        border-radius: 5px;
  
        h4 {
          font-size: 18px;
          color: #333;
        }
  
        p {
          font-size: 16px;
          color: #666;
        }
      }
    }
  
    .evaluation-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
  
      th, td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: left;
      }
  
      th {
        background: #f1f1f1;
        font-weight: bold;
      }
  
      tr.correct {
        background-color: #e6ffe6;
      }
  
      tr.incorrect {
        background-color: #ffe6e6;
      }
    }
  } 