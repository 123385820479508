@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.support-ticket-list {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin: 0;
    }

    .create-ticket-btn {
      background-color: var(--secondary-color);
      color: $background-color;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgb(54, 53, 53);
      }
    }
  }

  .search-bar {
    margin-bottom: 20px;

    input {
      width: 100%;
      padding: 10px;
      font-size: 1em;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
    }
  }

  .tickets-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th, td {
      padding: 15px;
      text-align: left;
      border-bottom: 1px solid $accent-color;
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    .priority-badge {
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 0.9em;

      &.high {
        background-color: #dc3545;
        color: white;
      }

      &.medium {
        background-color: #ffc107;
        color: black;
      }

      &.low {
        background-color: #28a745;
        color: white;
      }
    }

    .status-badge {
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 0.9em;

      &.open {
        background-color: #17a2b8;
        color: white;
      }

      &.closed {
        background-color: #6c757d;
        color: white;
      }
    }

    .view-btn {
      background-color: var(--primary-color);
      color: $background-color;
      border: none;
      padding: 5px 10px;
      font-size: 0.9em;
      cursor: pointer;
      border-radius: 3px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgb(54, 53, 53);
      }
    }

    .no-tickets {
      text-align: center;
      padding: 30px;
      color: darken($accent-color, 20%);
      font-style: italic;
    }
  }

  @include tablet {
    padding: 30px 15px;

    .header-section {
      flex-direction: column;
      gap: 15px;
      text-align: center;

      h2 {
        font-size: 1.8em;
      }
    }
  }

  @include mobile {
    padding: 20px 10px;

    .header-section {
      h2 {
        font-size: 1.6em;
      }
    }

    .tickets-table {
      font-size: 0.9em;

      th, td {
        padding: 10px;
      }

      .priority-badge,
      .status-badge {
        padding: 3px 8px;
      }
    }
  }
} 