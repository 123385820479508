@import "../components/Variables.scss";
@import "/src/styles/breakpoints.scss";

body {
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  margin:0;

}

* {
  &::-webkit-scrollbar {
    width: 10px; // Largeur de la barre verticale
    height: 10px; // Hauteur pour une barre horizontale (si applicable)
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; // Couleur de fond du track
    border-radius: 10px; // Coins arrondis
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); // Couleur de la poignée de défilement
    border-radius: 10px; // Coins arrondis
    border: 2px solid #f1f1f1; // Espacement autour de la poignée
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #333333; // Changement de couleur au survol
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent; // Pour les coins des barres de défilement
  }
}