@use "sass:color";
@import './Variables.scss';
@import '../styles/breakpoints.scss';

.job-application-page {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  min-height: 100vh;

  .error-message {
    color: $error-color;
    font-size: 0.9em;
    margin-top: 5px;
    display: block;
  }

  .job-application-container {
    max-width: 800px;
    margin: 0 auto;

    .back-button {
      background-color: $secondary-color;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 20px;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: color.adjust($secondary-color, $lightness: -10%);
      }
    }

    .application-header {
      margin-bottom: 30px;
      
      h1 {
        font-size: 2em;
        color: var(--primary-color);
        margin-bottom: 15px;
      }

      .job-info {
        background-color: color.adjust($accent-color, $lightness: 15%);
        padding: 20px;
        border-radius: 5px;

        h2 {
          color: var(--primary-color);
          font-size: 1.2em;
          margin-bottom: 5px;
        }

        p {
          color: $text-color;
          font-size: 1em;
        }
      }
    }

    .application-formulary {
      background-color: white;
      padding: 30px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .form-group {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 8px;
          color: $text-color;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 12px;
          border: 1px solid  color.adjust($accent-color, $lightness: 10%);
          border-radius: 5px;
          font-size: 1em;
          transition: border-color 0.3s ease;

          &:focus {
            outline: none;
            border-color: var(--primary-color);
          }

          &.error {
            border-color: $error-color;
          }
        }

        .file-upload-container {
          position: relative;
          margin-bottom: 10px;

          .file-input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 2;
          }

          .file-upload-button {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 12px;
            background-color: color.adjust($accent-color, $lightness: 15%);
            border: 2px dashed var(--primary-color);
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            svg {
              color: var(--primary-color);
              font-size: 1.2em;
            }

            &:hover {
              background-color: color.adjust($accent-color, $lightness: 10%);
            }
          }

          .file-name {
            margin-top: 10px;
            font-size: 0.9em;
            color: $text-color;
          }
        }

        .file-info {
          font-size: 0.8em;
          color: $back-color;
          margin-top: 5px;
        }
      }

      .submit-button {
        width: 100%;
        padding: 15px;
        background-color: var(--primary-color);
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 1.1em;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 20px;

        &:hover:not(:disabled) {
          background-color: color.adjust($secondary-color, $lightness: -10%);
        }

        &:disabled {
          background-color: color.adjust($primary-color, $lightness: 15%);
          cursor: not-allowed;
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .application-header h1 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .application-header h1 {
      font-size: 1.6em;
    }

    .application-form {
      padding: 20px;
    }
  }
}
