
@import './Variables.scss';
@import '/src/styles/breakpoints.scss';

.forgot-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: whitesmoke;
    font-family: $font-family;
    
  
    form {
      background-color: $background-color;
      padding: 2rem;
      border-radius: $border-radius;
      box-shadow: 2px 2px 5px #0000008e;
      width: 100%;
      max-width: 400px;
  
      h1 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        text-align: center;
        color: $primary-color;
        font-weight: 600;
      }
  
      label {
        display: block;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: $text-color-light;
        font-weight: 400;
      }
  
      input[type="email"] {
        width: 100%;
        padding: 0.8rem;
        font-size: 1rem;
        color: $text-color;
        border: 0 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin-bottom: 1.5rem;
        box-sizing: border-box;
  
        &:focus {
          border-color: $primary-color;
          outline: none;
          box-shadow: 0 0 4px rgba($primary-color, 0.5);
        }
      }
  
      button {
        width: 100%;
        padding: 0.8rem;
        font-size: 1rem;
        font-weight: 600;
        color: $background-color;
        background-color: $primary-color;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: darken($primary-color, 10%);
          box-shadow: $box-shadow-hover;
        }
      }
  
      p {
        margin-top: 1rem;
        font-size: 1em;
        text-align: center;
        font-family: $font-family;
  
        &.success {
          color: $success-color;
        }
  
        &.error {
          color: $error-color;
        }
      }
    }
  }

  