@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.dashboardcompany-layout {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .dashboardcompany-content {
    flex: 1;
    padding: 40px 20px;

    .welcome-message {
      font-size: 2.5em;
      margin-bottom: 30px;
      color: var(--secondary-color);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    .switch-button {
      font-size: 12px;
      color: white;
      font-weight: 700;
      border-radius: 6px;
      padding: 10px 10px;
      background-color: var(--primary-color);
      border: none;
      padding: 12px;
      cursor: pointer;
    }

    .dashboard-summary {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px;
      margin-bottom: 40px;

      .job-openings,
      .candidate-pool {
        background-color: $background-color;
        border-radius: 10px;
        padding: 25px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        h2 {
          font-size: 1.4em;
          color: var(--secondary-color);
          margin-bottom: 15px;
        }

        p {
          font-size: 1.2em;
          color: $text-color;
        }

        ul {
          list-style-type: none;
          padding: 0;

          li {
            margin-bottom: 10px;
            font-size: 1.1em;
          }
        }
      }
    }

    .dashboard-secondary {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px;
      margin-bottom: 40px;
    }

    .tracking-boards {
      background-color: $background-color;
      border-radius: 10px;
      padding: 25px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      margin-bottom: 40px;
      height: 100vh;
      max-height: auto;

      //   &:hover {
      //     transform: translateY(-5px);
      //   }

      h2 {
        font-size: 1.4em;
        color: var(--primary-color);
        margin-bottom: 15px;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          font-size: 1.1em;
        }
      }
    }

    .scheduled-interviews {
      background-color: $background-color;
      border-radius: 10px;
      padding: 30px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

      h2 {
        font-size: 1.8em;
        color: var(--primary-color);
        margin-bottom: 20px;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          background-color: lighten($accent-color, 15%);
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 20px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;

          &:hover {
            transform: translateY(-3px);
          }

          p {
            margin: 8px 0;
            font-size: 1.1em;

            &:first-child {
              font-weight: bold;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }

  @include tablet {
    .dashboard-content {
      padding: 30px 15px;

      .welcome-message {
        font-size: 2.2em;
      }

      .dashboard-summary {
        gap: 20px;
      }
    }
  }

  @include mobile {
    .dashboard-content {
      padding: 20px 10px;

      .welcome-message {
        font-size: 2em;
      }

      .dashboard-summary {
        grid-template-columns: 1fr;
      }

      .scheduled-interviews {
        padding: 20px;

        h2 {
          font-size: 1.6em;
        }

        ul li {
          padding: 15px;
        }
      }
    }
  }

  .no-data {
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    text-align: center;
    color: #666;
    font-style: italic;
  }
}
