@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.support-ticket-create {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  .header-section {
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color:  var(--primary-color);
    }
  }

  .ticket-form {
    max-width: 800px;
    margin: 0 auto;
    background-color: lighten($accent-color, 15%);
    padding: 30px;
    border-radius: 8px;

    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color:  var(--primary-color);
      }

      input[type="text"],
      select,
      textarea {
        width: 100%;
        padding: 10px;
        font-size: 1em;
        border: 1px solid  var(--primary-color);
        border-radius: 5px;
        background-color: $background-color;

        &:focus {
          outline: none;
          border-color: var(--secondary-color);
        }
      }

      textarea {
        resize: vertical;
        min-height: 120px;
      }

      .file-upload {
        position: relative;
        margin-bottom: 15px;
        
        input[type="file"] {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        .file-select-btn {
          display: inline-block;
          padding: 10px 20px;
          background:  var(--primary-color);
          color: $background-color;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 1em;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #333333;
          }
        }

        .file-hint {
          display: inline-block;
          font-size: 0.9em;
          color: darken($accent-color, 20%);
          margin-top: 10px;
          margin-left: 15px;
          white-space: nowrap;
          line-height: 1.6;
          
          @include mobile {
            display: block;
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }

      .attached-files {
        margin-top: 15px;

        .file-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          background-color: $background-color;
          border-radius: 3px;
          margin-bottom: 5px;

          .remove-file {
            background: none;
            border: none;
            color:  var(--primary-color);
            cursor: pointer;
            font-size: 1.2em;
            padding: 0 5px;

            &:hover {
              color: #333333;
            }
          }
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      margin-top: 30px;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        transition: background-color 0.3s ease;

        &.cancel-btn {
          background-color: $accent-color;
          color: $text-color;

          &:hover {
            background-color: darken($accent-color, 10%);
          }
        }

        &.submit-btn {
          background-color: var(--secondary-color);
          color: $background-color;

          &:hover {
            background-color: #333333;
          }
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .header-section {
      h2 {
        font-size: 1.8em;
      }
    }

    .ticket-form {
      padding: 20px;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .header-section {
      h2 {
        font-size: 1.6em;
      }
    }

    .ticket-form {
      padding: 15px;

      .form-actions {
        flex-direction: column;
        
        button {
          width: 100%;
        }
      }
    }
  }
} 