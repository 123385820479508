@import "/src/components/Variables.scss";
@import "/src/styles/breakpoints.scss";

.AddToTrackingBoardModal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  background-color: $background-color;
  padding: 1rem;



  &__content {
    padding: 1rem;
    grid-column: 1/3;

    &-title {
      margin-bottom: 1rem;
      color: text-color;
    }

    &-indication {
      margin-bottom: 1rem;
      color: text-color;
    }

    &-container {
      width: 90%;
      margin: 0 auto 1rem auto;
      
      display:flex;
      justify-content: space-around;
      flex-direction: column;
      gap: 1rem;

      &-item {
        background-color: $background-color;
        border: 1px solid $border-color;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        &-title {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: $primary-color;
        }
      }

      &_select {
        width: 100%;
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px solid $border-color;
        background-color: $background-color;
        margin-bottom: 1rem;
      }
    }

    &-buttons_container {
      display: flex;
      justify-content: center;
      gap: 1rem;

      &-buttonAdd {
        border-radius: 5px;
        font-weight: bold;
        padding: 1rem;
        border: none;
        color: $success-color;
        width: 50%;

        &:hover {
          background-color: $success-color;
          color: $background-color;
          cursor: pointer;
        }
      }

      &-buttonCancel {
        border-radius: 5px;
        font-weight: bold;
        padding: 1rem;
        border: none;
        color: $danger-color;
        width: 50%;

        &:hover {
          background-color: $danger-color;
          color: $background-color;
          cursor: pointer;
        }
      }
    }
  }
}