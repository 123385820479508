@import '../../../Variables.scss';
@import '../../../../styles/breakpoints.scss';

.assessments-list {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;

  .assessments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color: var(--primary-color);
      margin: 0;
    }

    .assessments-actions {
      display: flex;
      gap: 1rem;
      align-items: center;

      .assessment-type-select {
        padding: 10px;
        border: 1px solid $accent-color;
        border-radius: 5px;
        background-color: white;
        font-size: 0.95em;
        cursor: pointer;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(var(--primary-color), 0.1);
        }
      }

      .view-results-button {
        padding: 10px 20px;
        background-color: var(--primary-color);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.95em;
        transition: background-color 0.3s ease;
        white-space: nowrap;

        &:hover {
          opacity: 0.9;
          background-color: var(--primary-color-dark, var(--primary-color));
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(var(--primary-color), 0.2);
        }
      }
    }
  }

  .assessments-table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid $accent-color;

      &:last-child {
        text-align: center;
      }
    }

    th {
      background-color: var(--primary-color);
      color: $background-color;
    }

    tr:nth-child(even) {
      background-color: lighten($accent-color, 15%);
    }

    .actions-column {
      .action-select {
        padding: 5px 10px;
        border: 1px solid $accent-color;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
        font-size: 0.9em;
        min-width: 150px;

        &:hover {
          border-color: var(--primary-color);
        }

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba var(--primary-color, 0.2);
        }

        option {
          padding: 5px;

          &[value="delete"] {
            color: $error-color;
          }

          &[value="edit"] {
            color: var(--primary-color);
          }

          &[value="default"] {
            color: $text-color;
          }
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .assessments-header {
      flex-direction: column;
      gap: 1rem;
      align-items: stretch;

      .assessments-actions {
        flex-direction: column;
      }
    }

    .assessments-header h2 {
      font-size: 1.8em;
    }
  }

  @include mobile {
    padding: 20px 10px;

    .assessments-header {
      h2 {
        font-size: 1.6em;
      }

      .assessments-actions {
        .assessment-type-select,
        .view-results-button {
          width: 100%;
          text-align: center;
        }
      }
    }

    .assessments-table {
      font-size: 0.9em;

      th, td {
        padding: 10px;
      }
    }
  }
}

.error-message {
  color: #dc2626;
  padding: 1rem;
  margin: 1rem 0;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  border-radius: 0.375rem;
}

.loading-message {
  text-align: center;
  padding: 2rem;
  color: #6b7280;
}

.no-assessments {
  text-align: center;
  padding: 2rem;
  color: #6b7280;
  font-style: italic;
} 