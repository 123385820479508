@import '../../Variables.scss';

.job-opening-ai-generator {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    color: var(--primary-color);
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }

  .error-message {
    background-color: rgba($error-color, 0.1);
    color: var(--error-color);
    padding: 1rem;
    border-radius: $border-radius;
    margin-bottom: 1rem;
  }

  .hiring-requests-list {
    background: var(--background-color);
    padding: 1.5rem;
    border-radius: $border-radius;
    margin-bottom: 2rem;
    box-shadow: $box-shadow;

    h3 {
      margin-bottom: 1.5rem;
      color: var(--text-color);
      font-size: 1.4rem;
    }

    .hiring-request {
      padding: 1.5rem;
      border: 1px solid var(--border-color);
      border-radius: $border-radius;
      margin-bottom: 1rem;
      cursor: pointer;
      transition: all 0.2s ease;
      background: var(--background-color);

      &:hover {
        transform: translateY(-2px);
        box-shadow: $box-shadow-hover;
      }

      &.selected {
        border: 2px solid var(--primary-color);
        background: rgba($primary-color, 0.1);
      }

      h4 {
        margin: 0 0 0.5rem;
        color: var(--text-color);
        font-size: 1.2rem;
      }

      p {
        margin: 0.25rem 0;
        color: var(--text-color-light);
        font-size: 0.9rem;
      }
    }
  }

  .generated-job-offer {
    background: var(--background-color);
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    h3 {
      margin-bottom: 2rem;
      color: var(--text-color);
      font-size: 1.4rem;
    }

    .edit-form {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1.5rem;

      .form-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &.full-width {
          grid-column: 1 / -1;
        }

        &.hiring-process {
          grid-column: 1 / -1;
        }

        label {
          font-weight: 600;
          color: var(--text-color);
          font-size: 0.9rem;
        }

        input, select, textarea {
          padding: 0.75rem;
          border: 1px solid var(--border-color);
          border-radius: $border-radius;
          font-size: 1rem;
          background: var(--background-color);
          font-family: $font-family;

          &:focus {
            outline: none;
            border-color: var(--primary-color);
            box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
          }
        }

        textarea {
          min-height: 120px;
          resize: vertical;
        }

        .checkbox-group {
          display: flex;
          gap: 1.5rem;
          padding: 0.5rem 0;

          label {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            font-weight: normal;
          }
        }

        .skills-display {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin-bottom: 0.5rem;

          .skill-tag {
            background: rgba($primary-color, 0.1);
            color: var(--primary-color);
            padding: 0.25rem 0.75rem;
            border-radius: 16px;
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            button {
              background: none;
              border: none;
              color: var(--primary-color);
              cursor: pointer;
              padding: 0;
              font-size: 1.2rem;
              line-height: 1;

              &:hover {
                color: var(--error-color);
              }
            }
          }
        }

        .salary-inputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
        }

        .work-type-inputs {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .work-type-item {
            display: flex;
            align-items: center;
            gap: 1rem;

            span {
              min-width: 80px;
              font-weight: 500;
            }

            input {
              width: 120px;
              padding: 0.5rem;
              border: 1px solid var(--border-color);
              border-radius: 4px;
              font-size: 0.9rem;

              &::placeholder {
                color: var(--text-color-light);
                font-style: italic;
              }

              &:focus {
                outline: none;
                border-color: var(--primary-color);
                box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
              }
            }
          }
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid var(--border-color);

      button {
        padding: 0.75rem 1.5rem;
        border-radius: $border-radius;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s;
        font-size: 1rem;
        font-family: $font-family;

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        &.back-button {
          background: var(--background-color);
          border: 1px solid var(--border-color);
          color: var(--text-color);

          &:hover:not(:disabled) {
            background: var(--back-color);
            color: var(--background-color);
          }
        }

        &.save-button {
          background: var(--primary-color);
          border: none;
          color: var(--background-color);

          &:hover:not(:disabled) {
            background: var(--primary-color-dark);
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .job-opening-ai-generator-button {
      background: var(--primary-color);
      color: var(--background-color);
      border: none;
      padding: 1rem 2rem;
      border-radius: $border-radius;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s;
      font-family: $font-family;

      &:hover:not(:disabled) {
        background: var(--primary-color-dark);
        transform: translateY(-1px);
      }

      &:disabled {
        background: var(--back-color);
        cursor: not-allowed;
      }
    }
  }

  .preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .language-controls {
      position: relative;

      .language-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background: var(--primary-color);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9rem;

        &:hover {
          background: var(--primary-color-dark);
        }

        svg {
          font-size: 1.2rem;
        }
      }

      .language-dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        min-width: 150px;
        margin-top: 0.5rem;

        .language-option {
          display: block;
          width: 100%;
          padding: 0.75rem 1rem;
          text-align: left;
          border: none;
          background: none;
          cursor: pointer;
          font-size: 0.9rem;
          color: var(--text-color);

          &:hover {
            background: var(--background-color);
          }

          &.active {
            background: var(--primary-color);
            color: white;
          }
        }
      }
    }
  }
} 