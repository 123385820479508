.settings-page {
  display: flex;
  align-items: center;
  justify-content: center;
 
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
  color: #333;
 

  .settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vh;
    max-width: 90%;
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    transition: transform 0.2s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    h2 {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #444;
    }

    .settings-section {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: #555;
      }

      p {
        margin-bottom: 20px;
        font-size: 0.95rem;
        color: var(--secondary-color);
      }

      .color-picker {
        display: flex;
        flex-direction: column;
        gap: 20px;

        > div {
          text-align: center;

          h4 {
            font-size: 1rem;
            margin-bottom: 10px;
            font-weight: 600;
            color: #444;
          }

          .palette {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: center;
            align-items: center;

            button {
              width: 40px;
              height: 40px;
              border: 2px solid #e0e0e0;
              border-radius: 8px;
              cursor: pointer;
              transition: transform 0.2s ease, box-shadow 0.2s ease;

              &:hover {
                transform: scale(1.1);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
              }

              &[style*="background-color: #FFFFFF"] {
                border: 2px solid #ccc;
              }

              &[style*="background-color: #000000"] {
                border: 2px solid #666;
              }
            }
          }
        }
      }
    }
  }

  .logo-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .upload-label {
      display: inline-block;
      background-color: var(--primary-color, #3885CC);
      color: white;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #2a6ba8;
      }

      input[type="file"] {
        display: none;
      }
    }

    .logo-preview {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid var(--primary-color, #3885CC);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .save-button {
    display: block;
    margin: 20px;
    padding: 10px 20px;
    background-color: var(--primary-color, #6920b0);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}
