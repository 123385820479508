@import '../Variables.scss';
@import '/src/styles/breakpoints.scss';

.subscription-B2B {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transform: translateY(-20px);
  animation: fadeIn 0.8s ease-out forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .hero {
    width: 100%;
    background: linear-gradient(135deg, $primary-color, darken($primary-color, 15%));
    // background-color: $primary-color;
    padding: 40px 0;
    text-align: center;

    h1 {
      font-size: 3em;
      color: white;
    }
  }

  &-form-container {
    display: flex;
    justify-content: center;
    padding: 0.3rem;
    margin: 0.8rem 0;
  }

  &-form {
    width: 100%;
    min-width: 320px;
    max-width: 1600px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 5fr 0.5fr 0.5fr;
    gap: 0.5rem;
  }

  &-form-group-container {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  &-form-group-container,
  &-addon-block,
  &-group-payment_modality,
  &-group-amount-group {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  &-group-title {
    margin-bottom: 1rem;

    h2 {
      color: $primary-color;
      font-size: 1.7rem;
    }
  }

  &-group-coreFeatures {
    color: $primary-color;
    padding: 1.5rem;
    border-radius: 15px;
    border: 1px solid $primary-color;
    color: darken($primary-color, 15%);

    &-title {
      width: 50%;
      font-size: 1.5rem;
      border-bottom: 2px solid darken($border-color, 10%);
      margin-bottom: 1rem;

    }

    .features-list {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;

      li {
        list-style: none;
        padding: 0.42rem 0;
        font-size: 1rem;
        color: darken($text-color, 10%);
        border-bottom: 1px solid $border-color;

        &:last-child {
          border-bottom: none;
        }

        h3 {
          font-weight: 400;
        }
      }
    }
  }

  &-addon-block {
    grid-column: 3 / 4;
    grid-row: 1 / 2;

    &_container {
      background: lighten($primary-color, 60%);
      border-radius: 10px;
      padding: 0.8rem;
      height: 80%;
    }

    &-title {
      width: 40%;
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
      border-bottom: 2px solid darken($border-color, 10%);
      color: darken($primary-color, 15%);
    }
  }

  &-addon-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border: 1px solid $border-color;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        border-color: $primary-color;
      }

      &.selected {
        background-color: lighten($primary-color, 40%);
        border-color: $primary-color;
      }

      input {
        margin-right: 1rem;
        pointer-events: none; // Empêche l'interaction directe avec la case
      }

      label {
        flex: 1;
        font-size: 1rem;
        color: darken($text-color, 10%);
      }

      .price-container {
        text-align: right;

        .price {
          font-weight: bold;
          font-size: 1rem;
          color: $primary-color;
        }

        .credit {
          font-size: 0.8rem;
          color: darken($text-color, 20%);
        }
      }

    }
  }

  &-group-payment_modality {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &-group-quantity {

    display: flex;
    justify-content: space-around;
    align-items: center;

    .quantity-controls {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .quantity-btn {
        background-color: $primary-color;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($primary-color, 10%);
        }

        &:disabled {
          background-color: lighten($primary-color, 30%);
          cursor: not-allowed;
        }

      }
    }

    input {
      width: 40px;
      text-align: center;
      border: 1px solid $border-color;
      border-radius: 5px;
      padding: 0.3rem;
      font-size: 1rem;
      background-color: #f9f9f9;
    }
  }

  &-group-payment_term {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    label {
      font-weight: 600;
      text-align: center;
      word-wrap: break-word;
      width: 50%;
    }

    input,
    select {
      padding: 0.5rem;
      border-radius: 5px;
      border: none;
      font-size: 1rem;
    }
  }

  &-group-amount-group {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    display: flex;
    justify-content: center;
    align-items: center;


    label {
      font-weight: 600;
      text-align: center;
      word-wrap: break-word;
      width: 50%;
      color: $text-color;
    }

    input {
      padding: 0.5rem;
      border-radius: 5px;
      border: none;
      font-size: 1rem;
    }

    &-final_amount {
      font-size: 2.5rem;
      font-weight: 600;
      color: $primary-color;
    }

    &-text_container {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  &-button-container {
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 0.8rem 1.5rem;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }



  @include tablet {
    .subscription-B2B {
      width: 100%;
    }
    .subscription-B2B-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      width: 100%;
    }
  }

  @include mobile {
    form {
      width: 90%;

      .subscription-B2B-group-coreFeatures,
      .subscription-B2B-addon-container {
        padding: 1rem;
      }
    }
    .subscription-B2B-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 1rem;
    }
  }


}