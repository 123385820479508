@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.body-ai-interview {
  background-color: #f0f0f0;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.structured-interview-questionnaire {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: var(--primary-color);
    margin-bottom: 2rem;
  }

  .error-message {
    color: var(--error-color);
    background-color: var(--error-bg);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .ai-interview-questionnaire-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .ai-interview-form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: var(--text-color);
        font-weight: 500;
      }

      select, input {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid var(--border-color);
        border-radius: 8px;
        font-size: 1rem;
        background-color: white;
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
        }
      }

      .select-job-option-ai-interview {
        color: var(--text-color) !important;
        background-color: white !important;
        
        & > option {
          color: var(--text-color) !important;
          background: white !important;
          padding: 10px !important;
        }
      }
    }

    .generate-button {
      width: 100%;
      padding: 0.75rem;
      margin-top: 2rem;
      background: linear-gradient(135deg, var(--primary-color), var(--primary-color-dark));
      color: var(--background-color);
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &:hover:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        opacity: 0.9;
        color: white;
      }

      &:disabled {
        background: var(--disabled-color);
        cursor: not-allowed;
      }
    }
  }

  .questionnaire-result {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;

    h3 {
      color: var(--primary-color);
      margin-bottom: 1.5rem;
    }

    .questions-section {
      margin-bottom: 2rem;

      h4 {
        color: var(--text-color);
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 2px solid var(--border-color);
      }

      .question-card {
        background: var(--background-secondary);
        padding: 1.5rem;
        border-radius: 8px;
        margin-bottom: 1.25rem;
        border: 1px solid var(--border-color);
        position: relative;
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .importance-badge {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          background: var(--primary-color);
          color: white;
          padding: 0.35rem 0.75rem;
          border-radius: 4px;
          font-size: 0.875rem;
          font-weight: 500;
        }

        .question-text {
          font-size: 1.1rem;
          color: var(--text-color);
          margin-bottom: 1rem;
          padding-right: 4rem;
        }

        .evaluation-criteria {
          background: var(--background-tertiary);
          padding: 1.25rem;
          border-radius: 8px;
          margin-top: 1rem;
          border: 1px solid var(--border-color-light);

          h5 {
            color: var(--text-color);
            margin-bottom: 0.5rem;
            font-weight: 500;
          }

          ul {
            list-style-type: disc;
            margin-left: 1.5rem;

            li {
              color: var(--text-secondary);
              margin-bottom: 0.5rem;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }

  @include mobile {
    padding: 1rem;

    .ai-interview-questionnaire-form {
      padding: 1rem;
    }

    .questionnaire-result {
      padding: 1rem;

      .question-card {
        padding: 1rem;

        .importance-badge {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }

        .question-text {
          padding-right: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }

  // Specific override for Firefox
  @-moz-document url-prefix() {
    .questionnaire-form .form-group select {
      color: black !important;
      background: white !important;
      
      option {
        color: black !important;
        background: white !important;
      }
    }
  }

  // Specific override for Webkit browsers (Chrome, Safari)
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .questionnaire-form .form-group select {
      color: black !important;
      background: white !important;
      
      option {
        color: black !important;
        background: white !important;
      }
    }
  }
}

.save-questionnaire-container {
  margin-top: 2rem;
  text-align: center;

  .save-button {
    padding: 0.75rem 2rem;
    background: var(--success-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover:not(:disabled) {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      opacity: 0.9;
    }

    &:disabled {
      background: var(--disabled-color);
      cursor: not-allowed;
    }
  }
} 