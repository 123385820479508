@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.success-modal {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-50px);
    transition: transform 0.3s ease;

    h2 {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    &-text-container {
      margin: 0 auto 1rem auto;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $text-color;

      p {
        margin: 0.21rem 0 ;
      }

      &_message {
        font-size: 0.8rem;
      }
    }

    button {
      padding: 0.5rem 1rem;
      border: none;
      background-color: #4c2387; /* Exemple de couleur */
      color: white;
      cursor: pointer;
      border-radius: 4px;
  
      &:hover {
        background-color: #32165c; /* Plus foncé au survol */
      }
    }
  }
}