@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.card_main {
  background: #fff;
  width: 350px !important;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 500px;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 8px;
}
.card_header {
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  color: #ffff;
  align-items: center;

  height: 40px;
  cursor: grabbing;
  background-color: var(--primary-color);
}
.card_content {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  padding: 7px;
  flex-grow: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.delete_task_icon {
  position: absolute;
  display: flex;
  gap: 10px;
  right: 5px;
  display: flex;
  justify-content: end;
}

.task-container-content1 {
  position: relative;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #85858538;
  flex-shrink: 0;

  overflow-x: hidden;
  overflow-y: auto;
}
.task_Description {
  overflow-x: hidden;
  overflow-y: auto;
}
.content {
  height: 100%;
  all: unset;
  background-color: transparent;
}
.task_Description {
  width: 20rem;
}
.task-container {
  position: relative;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  border: 1px solid transparent !important;
  height: unset;
  min-height: 80px;
  flex-direction: column;
  background-color: #b3b0b028;
  flex-shrink: 0;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid var(--primary-color) !important;
  }
  cursor: grab;
}

.view-details-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  &:hover {
    background-color: rgb(54, 53, 53);
       
  }

  &:active {
     
  }
}

.candidate-task * {
  text-decoration: none !important;
}

.candidate-task {
  background-color: #acdaff !important;
  border: 1px solid darken($accent-color, 10%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: darken($accent-color, 40%);

  &:hover {
    text-decoration: none !important;
  }

  a {
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: underline !important;
  }
}

.task-icon-edit {
  color: var(--primary-color);
}
.add_task {
  margin-left: auto !important;
  display: flex !important;
  all: unset;
  width: 15%;
  justify-content: end;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  color: white;
  font-weight: 700;
  border-radius: 6px;
  padding: 10px 10px;
  background-color: var(--primary-color);
}
.delete_card_icon {
  font-size: 24px !important;
  color: rgb(236, 44, 44);
}
.card_Btn {
  border-radius: 50%;

  all: unset;
}

.Edit_input_title {
  all: unset;

  background-color: #e9d3ff63 !important;
  padding: 5px 10px;
}

.task-container-content:-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.task-container-content:-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.task-container-content:-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.task-container-content:-webkit-scrollbar-thumb:hover {
  background: #555;
}

.task_Description :-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.task_Description :-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.task_Description :-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.task_Description :-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card-data {
  list-style: none;
  padding: 0%;
  margin: 5px 2px;
  flex-direction: column;
  display: flex;
  gap: 10px;
}
.task-title {
  margin: 0;
}
.task-title:hover {
  cursor: pointer;
  text-decoration: underline;
}
.task-description {
  margin-top: 10px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.task-description:hover {
  cursor: pointer;
  text-decoration: underline;
}

.edit-fields {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 10px;

  .edit-input {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    width: 90%;
  }

  .edit-textarea {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
    width: 90%;
  }

  .save-button {
    padding: 8px 12px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 95%;
    &:hover {
      background-color: #218838;
    }
  }

  .cancel-button {
    padding: 8px 12px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 95%;
    margin-right: 10px;
    &:hover {
      background-color: #218838;
    }
  }

  .edit-task-btns {
    display: flex;
    gap: 8;
  }
}

.task-icons-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-icons {
  display: flex;
  justify-content: flex-end;
}

.task-icon-edit {
  font-size: 20px !important;
  margin-right: 5px;
}

.task-icon-delete {
  font-size: 20px !important;
  color: rgb(236, 44, 44);
}

.comment {
  width: 96%;
  padding: 10px;
  margin-right: 20px;
  margin-top: 5px;
}

.commentBtn {
  padding: 8px 12px;
  background-color: #218838;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100px;
  margin-left: auto !important;
  margin-bottom: 30px;
  &:hover {
    background-color: #218838;
  }
}

.view-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 1000;
}

.view-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  position: relative;
}

.view-modal-content {
  padding-top: 60px;
}

.view-modal-close {
  padding-top: 50px;
}

.view-model-description-label {
  margin-top: 10px;
}

.view-model-description {
  margin: 5px 0 10px 10px;
}

.view-model-add-comment-label {
  margin-top: 10px;
}

.comment-box {
  border: 1px solid #ccc;
  padding: 0 10px 10px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.comment-header {
  display: flex;
  justify-content: left;
  margin-bottom: 5px;
  align-items: center;
  padding: 10px 0;
  background-color: #f9f9f9;
}

.comment-user {
  font-size: 12px !important;
  font-weight: bold;
}

.comment-time {
  font-size: 0.7rem;
  color: #666;
  margin-left: 10px;
}

.comment-content {
  margin: 0;
  padding: 5px 0;
}

.comment-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-task-btn {
  margin-top: 20px;
}

.backBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 20px;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
}
