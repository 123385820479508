@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';


.my-profile {
  font-family: 'Montserrat', sans-serif;
  max-width: 1200px;  
  margin: 2rem auto;  
  padding: 2rem;
  border-radius: 12px;
 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 
              0 4px 6px rgba(0, 0, 0, 0.1);  
  text-align: center;
  transition: transform 0.3s ease;  

  &:hover {
      transform: translateY(-5px);  
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);  
  }




  .passwordbox {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background: #ffffff;  
    border-radius: 12px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.3), 
                0 4px 8px rgba(0, 0, 0, 0.06);  
    text-align: left;

    form {
        display: flex;
        flex-direction: column;

        label {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: #333333;  
        }

        input {
            padding: 0.65rem;
            margin-bottom: 1.5rem;
            border: 1px solid #c8c9ca;
            border-radius: 8px;
            font-size: 1rem;
            transition: box-shadow 0.3s ease, border 0.3s ease;

            &:focus {
                outline: none;
                border: 1px solid #6920b0;  
                box-shadow: 0 4px 10px rgba(105, 32, 176, 0.2);  
            }
        }

        .error-message {
            color: #dc3545;
            margin-bottom: 1rem;
            font-size: 0.9rem;
        }

        .success-message {
            color: #34c759;
            margin-bottom: 1rem;
            font-size: 0.9rem;
        }

        .update-button {
            background-color: var(--primary-color); 
            color: #fff;
            padding: 0.75rem;
            width: 200px;  
            margin: 0 auto;  
            border: none;
            border-radius: 8px;
            font-size: 1rem;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            box-shadow: 0 6px 12px rgba(105, 32, 176, 0.2);
            transition: all 0.3s ease;

            &:hover {
                background-color: var(--primary-color);  
                box-shadow: 0 8px 16px rgba(105, 32, 176, 0.3);
                transform: translateY(-3px);  
            }

            &:active {
                transform: translateY(0);  
                box-shadow: 0 4px 8px rgba(105, 32, 176, 0.2);
            }
        }
    }
}










  h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 0rem;
  }

  .error-message,
  .success-message {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #dc3545;  
    &.success-message {
      color: #28a745;  
    }
  }

  .profile-section {
    background: rgba(255, 255, 255, 0.774); 
    margin-bottom: 2rem;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    text-align: center;

    h3 {
      font-size: 1.3rem;
      color: var(--primary-color);
      margin-bottom: 1rem;
      font-weight: 600;
      border-bottom: 2px solid #e0e0e0;
      display: inline-block;
      padding-bottom: 0.5rem;
    }

    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      font-size: 1rem;
      color: #555;

      input,
      textarea {
        width: 100%;
        max-width: 500px;
        margin-top: 0.5rem;
        padding: 0.75rem;
        border: 1px solid #c8c9ca;
   
        border-radius: 8px;
        font-size: 1rem;
        transition: all 0.3s ease;

        &:focus {
          outline: none;  
    border-color: #6920b0;  
    box-shadow: 0 0 5px rgba(105, 32, 176, 0.4);
           
        }
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

    .language-select {
      width: 100%;
      max-width: 500px;
      margin-top: 0.5rem;
      padding: 0.75rem;
      border: 1px solid #c8c9ca;
      border-radius: 8px;
      font-size: 1rem;
      transition: all 0.3s ease;
      background-color: white;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: #6920b0;
        box-shadow: 0 0 5px rgba(105, 32, 176, 0.4);
      }

      &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
      }
    }
  }

  
  .profile-picture-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;

    h3 {
      margin-bottom: 0.5rem;
    }

    .profile-picture {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
      border: 3px solid var(--secondary-color);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    .no-profile-picture {
      color: #777;
      font-size: 1rem;
      margin-top: 0.5rem;
    }

    .profile-picture-upload {
      margin-top: 1rem;

      .profile-picture-button {
        background-color: #007BFF;
        color: white;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 8px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;

        &:hover {
          background-color: darken(#007BFF, 10%);
          transform: translateY(-2px);
        }
      }
    }
  }

  
  .save-profile-button,
  .edit-profile-button,
  .cancel-button,
  .delete-account-button,
  .confirm-delete-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    &.save-profile-button,
    &.edit-profile-button {
      background-color: var(--primary-color);

      &:hover {
         
        transform: translateY(-2px);
      }
    }

    &.cancel-button {
      background-color: #6c757d;

      &:hover {
        background-color: darken(#6c757d, 10%);
        transform: translateY(-2px);
      }
    }

    &.delete-account-button,
    &.confirm-delete-button {
      background-color: #dc3545;

      &:hover {
        background-color: darken(#dc3545, 10%);
        transform: translateY(-2px);
      }
    }

    display: block;
    margin: 1rem auto;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  
  @media (max-width: 768px) {
    padding: 1rem;

    .profile-section,
    .profile-picture-section {
      padding: 1rem;
    }

    .profile-picture {
      width: 100px;
      height: 100px;
    }
  }
}
