@import '../../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.assessment-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #ffffff;

  .assessment-header {
    text-align: left;
    background: linear-gradient(to right, #4F46E5, #6366F1);
    margin: -2rem -2rem 3rem -2rem;
    padding: 3rem 2rem;
    color: white;
    border-bottom: none;

    h1 {
      color: white;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }

    .purpose {
      color: #4a5568;
      font-size: 1.1rem;
      max-width: 800px;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
    }

    .meta-info {
      display: flex;
      justify-content: center;
      gap: 2rem;
      color: #718096;
    }

    .header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      .invite-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.5rem;
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: white;
        border-radius: 8px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.25);
          transform: translateY(-2px);
        }

        .icon {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }

  .results-section {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 2rem 0 3rem;

    h2 {
      color: #1F2937;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .results-table {
      border: none;
      width: 100%;
      
      .table-header {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
        gap: 1rem;
        background: #F8FAFC;
        border-radius: 8px 8px 0 0;
        font-weight: 600;
        padding: 1rem;
        border-bottom: 1px solid #E2E8F0;
      }

      .table-row {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
        gap: 1rem;
        padding: 1rem;
        border-bottom: 1px solid #E2E8F0;
        align-items: center;
        transition: background-color 0.2s;

        &:hover {
          background: #F8FAFC;
        }

        .analysis-btn {
          background: #4F46E5;
          color: white;
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.3);
          }
        }
      }

      .no-results {
        padding: 3rem;
        text-align: center;
        color: #6B7280;
        font-style: italic;
        grid-column: 1 / -1;
      }
    }
  }

  .section {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    h2 {
      color: #2d3748;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .question-block {
      background: #F8FAFC;
      border: 1px solid #E2E8F0;
      border-radius: 12px;
      padding: 2rem;
      margin-bottom: 2rem;
      transition: transform 0.2s, box-shadow 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.1);
      }

      h3 {
        color: #2d3748;
        margin-bottom: 1.5rem;
      }

      .options {
        display: grid;
        gap: 1rem;
        margin-top: 1.5rem;

        .option {
          background: white;
          border: 1px solid #E2E8F0;
          border-radius: 8px;
          padding: 1rem;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            background: #F1F5F9;
            border-color: #CBD5E1;
          }

          input[type="radio"] {
            accent-color: #4F46E5;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .challenge-block {
    background: white;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    padding: 2rem;
    margin: 2rem 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

    .requirements {
      background: #F8FAFC;
      border-left: 4px solid #4F46E5;
      padding: 1.5rem;
      margin: 2rem 0;

      h4 {
        color: #2d3748;
        margin-bottom: 0.75rem;
      }

      ul {
        list-style-type: disc;
        padding-left: 1.5rem;
        
        li {
          color: #4a5568;
          margin-bottom: 0.5rem;
          line-height: 1.5;
        }
      }
    }

    .io-examples {
      margin: 1.5rem 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      .sample-input,
      .sample-output {
        padding: 1rem;
        background-color: #f8f9fa;
        border-radius: 8px;

        h4 {
          color: #2d3748;
          margin-bottom: 0.75rem;
        }

        pre {
          background-color: #f1f3f5;
          padding: 0.75rem;
          border-radius: 4px;
          font-family: 'Courier New', Courier, monospace;
          font-size: 0.9rem;
          white-space: pre-wrap;
          word-break: break-word;
        }
      }
    }

    h3 {
      color: #1a365d;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    .challenge-text {
      color: #4a5568;
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }

    .initial-code {
      margin: 1rem 0;
      padding: 1rem;
      background-color: #f1f3f5;
      border-radius: 4px;

      pre {
        margin: 0;
        white-space: pre-wrap;
        font-family: 'Courier New', Courier, monospace;
      }
    }

    .examples {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      margin: 2rem 0;

      .example-block {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        h4 {
          background: #f8fafc;
          color: #2d3748;
          font-size: 1rem;
          padding: 1rem;
          margin: 0;
          border-bottom: 1px solid #e2e8f0;
        }

        .sample-data {
          padding: 1.25rem;
          font-family: 'Courier New', monospace;
          font-size: 0.95rem;
          line-height: 1.6;
          color: #2d3748;
          white-space: pre-wrap;
          background: #ffffff;
        }
      }
    }

    .time-limit {
      display: inline-flex;
      align-items: center;
      background: #edf2f7;
      padding: 0.75rem 1.25rem;
      border-radius: 6px;
      color: #2d3748;
      font-weight: 500;
      margin: 1.5rem 0;
      border: 1px solid #e2e8f0;

      span {
        margin-left: 0.5rem;
      }
    }

    .code-editor {
      margin-top: 2rem;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      overflow: hidden;

      h4 {
        background: #f8fafc;
        padding: 1rem;
        margin: 0;
        border-bottom: 1px solid #e2e8f0;
        color: #2d3748;
      }

      .code-textarea {
        width: 100%;
        min-height: 250px;
        padding: 1.25rem;
        background: #ffffff;
        border: none;
        font-family: 'Courier New', monospace;
        font-size: 0.95rem;
        line-height: 1.6;
        color: #2d3748;
        resize: vertical;

        &:focus {
          outline: none;
          background: #f8fafc;
        }
      }
    }

    .test-cases {
      margin-top: 1.5rem;

      h4 {
        margin-bottom: 0.5rem;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 0.5rem;
          margin: 0.5rem 0;
          background-color: #e9ecef;
          border-radius: 4px;
          font-family: 'Courier New', Courier, monospace;
          font-size: 0.9rem;
        }
      }
    }

    .time-limit {
      display: inline-block;
      background: #f7fafc;
      padding: 0.5rem 1rem;
      border-radius: 6px;
      color: #2d3748;
      font-size: 0.9rem;
      margin: 1rem 0;
      border: 1px solid #e2e8f0;
    }

    .code-editor {
      margin-top: 1.5rem;

      h4 {
        color: #2d3748;
        margin-bottom: 0.75rem;
        font-weight: 500;
      }

      .code-textarea {
        width: 100%;
        min-height: 200px;
        padding: 1rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-family: 'Courier New', monospace;
        font-size: 0.9rem;
        line-height: 1.5;
        color: #2d3748;
        resize: vertical;

        &:focus {
          outline: none;
          border-color: #4F46E5;
          box-shadow: 0 0 0 1px #4F46E5;
        }
      }
    }
  }

  .closing-message {
    text-align: center;
    padding: 2rem;
    background: #f7fafc;
    border-radius: 8px;
    color: #4a5568;
    font-style: italic;
    margin-top: 2rem;
  }

  .loading {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }

  .error-message {
    color: #e53e3e;
    text-align: center;
    padding: 1rem;
    background: #fff5f5;
    border-radius: 8px;
    margin: 2rem 0;
  }

  .code-challenge {
    .challenge-text {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 1.5rem;
      color: #2d3748;
    }

    .code-editor {
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #2d3748;
      background: #1a202c;

      .code-header {
        background: #2d3748;
        padding: 0.75rem 1rem;
        color: #e2e8f0;
        font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
        font-size: 0.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .code-textarea {
        width: 100%;
        min-height: 300px;
        padding: 1rem;
        background: #1a202c;
        color: #e2e8f0;
        border: none;
        font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
        font-size: 0.95rem;
        line-height: 1.5;
        resize: vertical;

        &::placeholder {
          color: #4a5568;
        }

        &:focus {
          outline: none;
          box-shadow: inset 0 0 0 2px #4299e1;
        }
      }
    }
  }

  .header-actions {
    margin-top: 2rem;

    .invite-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.75rem 1.5rem;
      background: #4299e1;
      color: white;
      border: none;
      border-radius: 8px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background: #3182ce;
      }

      svg {
        stroke: currentColor;
      }
    }
  }

  .assessment-footer {
    margin-top: 3rem;
    text-align: center;

    .submit-button {
      padding: 1rem 2rem;
      background: #48bb78;
      color: white;
      border: none;
      border-radius: 8px;
      font-weight: 600;
      font-size: 1.1rem;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background: #38a169;
      }
    }
  }

  .analysis-section {
    margin-top: 4rem;
    padding-top: 3rem;
    border-top: 2px solid #e2e8f0;

    h2 {
      text-align: center;
      color: #2d3748;
      margin-bottom: 2rem;
    }

    .analysis-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
    }

    .analysis-card {
      background: white;
      border-radius: 12px;
      padding: 1.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      h3 {
        color: #2d3748;
        margin-bottom: 1rem;
      }

      .score {
        font-size: 3rem;
        font-weight: 700;
        color: #4299e1;
        text-align: center;
        margin-bottom: 1rem;
      }

      .score-breakdown {
        .breakdown-item {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 0;
          border-bottom: 1px solid #e2e8f0;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .quality-metrics {
        .metric {
          margin-bottom: 1rem;

          span {
            display: block;
            margin-bottom: 0.5rem;
          }

          .progress-bar {
            height: 8px;
            background: #e2e8f0;
            border-radius: 4px;
            overflow: hidden;

            .progress {
              height: 100%;
              background: #4299e1;
              border-radius: 4px;
              transition: width 0.3s ease;
            }
          }
        }
      }
    }
  }

  .assessment-details {
    margin-bottom: 3rem;
    
    .purpose {
      color: #4a5568;
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
      line-height: 1.6;
    }

    .meta-info {
      display: flex;
      gap: 2rem;
      color: #718096;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }

  .modal-content {
    background: white;
    border-radius: 12px;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow: hidden;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .invite-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: #4F46E5;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #4338CA;
      transform: translateY(-1px);
    }

    .icon {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

// Add syntax highlighting colors
.code-textarea {
  &::selection {
    background: rgba(66, 153, 225, 0.3);
  }
}

@media (max-width: 768px) {
  .assessment-page {
    padding: 1rem;

    .assessment-header {
      padding: 2rem 1rem;
      
      h1 {
        font-size: 2rem;
      }

      .header-top {
        flex-direction: column;
        gap: 1rem;
        text-align: center;

        .invite-btn {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .section {
      padding: 1.5rem;
    }

    .examples {
      grid-template-columns: 1fr !important;
    }

    .results-table {
      .table-header {
        grid-template-columns: 1fr;
        gap: 0.5rem;
      }
    }

    .modal-content {
      width: 95%;
      margin: 1rem;
    }
  }

  .code-editor {
    .code-textarea {
      min-height: 200px;
    }
  }

  .challenge-block {
    .examples {
      grid-template-columns: 1fr;
    }
  }
}

// Add modal-specific styles
.send-assessment-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;

    h2 {
      font-size: 1.5rem;
      color: #1f2937;
    }
  }

  .modal-body {
    max-height: 60vh;
    overflow-y: auto;
    margin-bottom: 1.5rem;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;

    button {
      padding: 0.5rem 1rem;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;

      &.cancel-btn {
        background: #f3f4f6;
        border: 1px solid #d1d5db;
        color: #374151;

        &:hover {
          background: #e5e7eb;
        }
      }

      &.submit-btn {
        background: #4F46E5;
        border: none;
        color: white;

        &:hover {
          background: #4338CA;
        }

        &:disabled {
          background: #9ca3af;
          cursor: not-allowed;
        }
      }
    }
  }
}
