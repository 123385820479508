@import "../Variables.scss";
@import "/src/styles/breakpoints.scss";

.dashboard-layout {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  background-color: $background-color;
  display: flex;  
  min-height: 100vh;
  
  .sidebar {
    width: 250px; 
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--primary-color);
    color: #fff;
    padding: 20px;
    z-index: 999; 
  }

  .dashboard-content {
    margin-left: 250px; 
    padding: 40px 20px;
    flex-grow: 1; 
    transition: margin-left 0.3s ease; 

    .welcome-message {
      font-size: 2.5em;
      margin-bottom: 30px;
      color: var(--secondary-color);
      text-align: center;
    }

    .dashboard-summary {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px;
      margin-bottom: 40px;
    }

    .user-listing,
    .subscription-listing {
      background-color: $background-color;
      border-radius: 10px;
      padding: 25px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      h2 {
        font-size: 1.4em;
        color: var(--secondary-color);
        margin-bottom: 15px;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          font-size: 1.1em;
        }
      }
    }
  }

  @include tablet {
    .dashboard-content {
      padding: 30px 15px;

      .welcome-message {
        font-size: 2.2em;
      }

      .dashboard-summary {
        gap: 20px;
      }
    }
  }

  @include mobile {
    flex-direction: column; 
    
    .sidebar {
      position: relative;
      width: 100%;
      padding: 10px;
    }

    .dashboard-content {
      margin-left: 0;
      padding: 20px 10px;
    }

    .dashboard-summary {
      grid-template-columns: 1fr;
    }

    .user-listing,
    .subscription-listing {
      padding: 20px;
    }
  }

  .no-data {
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    text-align: center;
    color: #666;
    font-style: italic;
  }
}
