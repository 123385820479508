@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.candidate-pipeline {
  padding: 32px;

  h2 {
    color: var(--primary-color);
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .pipeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .search-bar {
    margin-bottom: 24px;

    input {
      width: 100%;
      padding: 10px;
      font-size: 1em;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
      
      &::placeholder {
        color: #9CA3AF;
      }

      &:focus {
        outline: none;
        border-color: #000;
        box-shadow: 0 0 0 3px rgba(139, 61, 255, 0.1);
      }
    }
  }

  .pipeline-table-container {
    .pipeline-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;

      th {
        background-color: var(--primary-color);
        color: white;
        padding: 16px;
        text-align: center;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }

      td {
        padding: 16px;
        border-bottom: 1px solid #E5E7EB;
        color: #374151;
        font-size: 16px;
        text-align: center;

        a {
          color: var(--primary-color);
          text-decoration: none;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }

      tr:hover td {
        background-color: #F9FAFB;
      }

      .actions-column {
        .action-select {
          width: 100%;
          padding: 8px 12px;
          border: 1px solid #E5E7EB;
          border-radius: 6px;
          background-color: white;
          color: #374151;
          font-size: 16px;
          cursor: pointer;
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6L8 10L12 6' stroke='%239CA3AF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 12px center;
          padding-right: 36px;

          &:focus {
            outline: none;
            border-color: var(--primary-color);
            box-shadow: 0 0 0 3px rgba(139, 61, 255, 0.1);
          }

          option {
            padding: 8px;

            &[value="delete"] {
              color: var(--error-color);
            }
          }
        }
      }
    }
  }

  .no-results {
    text-align: center;
    padding: 48px 0;
    color: #6B7280;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding: 16px;

    h2 {
      font-size: 24px;
    }

    .pipeline-header {
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }

    .pipeline-table {
      th, td {
        padding: 12px;
        font-size: 14px;
      }
    }
  }
}
