@import "./Variables.scss";
@import "/src/styles/breakpoints.scss";

.not-found-page {
  text-align: center;
  padding: 50px;
}

.main-content {
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #6920b0;
    font-weight: 200;
    font-family: "Montserrat", sans-serif;
  }

  p {
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
    color: #333333;
  }

  .home-link {
    font-size: 1.2rem;
    color: #6920b0;
    text-decoration: none;
    border: 1px solid #6920b0;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: "Montserrat", sans-serif;

    &:hover {
      background-color: #2f00ffd0;
      color: white;
    }
  }

  .login-link {
    font-size: 1.2rem;
    color: #fa41cc;
    text-decoration: none;
    border: 1px solid #fa41cc;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: "Montserrat", sans-serif;

    &:hover {
      background-color: #fa41cc;
      color: white;
    }
  }
}

.logo-404-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -5px 0;
}

.logo-404 {
  max-width: 1000px;
  height: auto;
  border-radius: 10px;
}

// langage
.logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo-link {
  z-index: 2;
  .logo {
    height: 100px !important;
    width: auto !important;
  }
}
.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 1rem;
  height: 100px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.language-selector {
  position: relative;

  .lang-toggle {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: $primary-color;
    font-weight: 600;
    cursor: pointer;
    padding: 0.25rem 0.5rem;

    .flag-icon {
      width: 20px;
      height: auto;
      margin-right: 0.5rem;
    }
  }

  .lang-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;

    &.open {
      display: block;
    }

    li {
      margin: 0;

      button {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.5rem 1rem;
        border: none;
        background: none;
        cursor: pointer;
        font-weight: 600;

        img {
          width: 20px;
          height: auto;
          margin-right: 0.5rem;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

////////

@media screen and (max-width: 768px) {
  .main-content {
    padding: 20px;
    text-align: center;
  }

  .logo-404-container {
    margin: 20px 0;
  }

  .logo-404 {
    max-width: 350px;
  }

  .home-link {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-content {
    padding: 40px;
    text-align: center;
  }

  .logo-404-container {
    margin: 15px 0;
  }

  .logo-404 {
    max-width: 600px;
  }

  .home-link {
    font-size: 16px;
    padding: 12px 20px;
  }
}
