@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.job-openings {
  padding: 2rem;
  background-color: $background-color;

  .job-openings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .header-content {
      h2 {
        font-size: 1.8rem;
        color: var(--primary-color);
        margin: 0;
      }

      p {
        color: $text-color-light;
        margin-top: 0.5rem;
      }
    }

    .header-actions {
      display: flex;
      gap: 1rem;

      .create-button,
      .ai-generate-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        padding: 0.85rem 1.75rem;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        min-width: 160px;
        position: relative;
        overflow: hidden;

        i {
          font-size: 1.1rem;
          transition: transform 0.3s ease;
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

          i {
            transform: scale(1.1);
          }
        }

        &:active {
          transform: translateY(1px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }

      .create-button {
        background: linear-gradient(135deg, var(--primary-color), var(--primary-color-dark));
        color: white;

        &:hover {
          background: linear-gradient(135deg, var(--primary-color-dark), var(--primary-color));
        }
      }

      .ai-generate-button {
        background: linear-gradient(135deg, var(--secondary-color), var(--secondary-color-dark));
        color: white;
        
        &:hover {
          background: linear-gradient(135deg, var(--secondary-color-dark), var(--secondary-color));
        }

        i {
          animation: pulse 2s infinite;
        }
      }
    }
  }

  .search-filters {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 20px;

    .search-box {
      flex: 1;
      position: relative;
      margin-right: 20px;

      input {
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 2.5rem;
        border: 1px solid $border-color;
        border-radius: 8px;
        font-size: 0.9rem;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: var(--primary-color);;
          box-shadow: 0 0 0 2px rgbavar(--primary-color,0.1);;
        }
      }

      i {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: $text-color-light;
      }
    }

    .filter-container {
      width: 200px;

      .filter-select {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid $border-color;
        border-radius: 8px;
        background-color: white;
        font-size: 0.9rem;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
        }
      }
    }
  }

  .job-sections {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .job-section {
      h3 {
        font-size: 1.2rem;
        color: $text-color;
        margin-bottom: 1rem;
        font-weight: 600;
        position: sticky;
        top: 0;
        background-color: $background-color;
        padding: 1rem 0;
        z-index: 1;
      }

      .job-cards-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 1.5rem;
        align-items: stretch;
      }

      // adding table styles for list mode en dashboard
      .job-list-table {
        width: 100%;
        table-layout: fixed; // Forcer les colonnes à avoir une largeur fixe
        border-collapse: collapse;
        margin-top: 1rem;

        th,
        td {
          padding: 0.75rem;
          word-wrap: break-word; // Forcer le texte à rester dans les colonnes
          text-align: left;
          border-bottom: 1px solid $border-color;
        }

        th {
          background-color: var(--primary-color);
          color: white;
          font-weight: bold;
          text-transform: uppercase;
        }
        th:last-child,
        td:last-child {
          text-align: right;
        }

        tr:hover {
          background-color: rgba(#808080, 0.1);
        }

        .details-button {
          background-color: var(--primary-color);
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          border-radius: 8px;
          font-size: 0.9rem;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: var(--text-color);
           
          }
        }
      }

      .see-more-jobs {
        .see-more-jobs-button {
          background-color: var(--primary-color);
          color: white;
          border: none;
          padding: 0.75rem 1.5rem;
          border-radius: 8px;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          transition: all 0.3s ease;
          margin: 20px 0px;

          &:hover {
            background-color: #333333;
          }

          i {
            font-size: 0.9rem;
          }
        }
      }
      .empty-state {
        grid-column: 1 / -1;
        background: white;
        border-radius: 12px;
        padding: 3rem 2rem;
        text-align: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        .empty-state-content {
          max-width: 400px;
          margin: 0 auto;
        }

        .empty-state-icon {
          width: 80px;
          height: 80px;
          background-color: rgba(#808080, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 1.5rem;

          i {
            font-size: 2.5rem;
            color: var(--primary-color);
          }
        }

        h2 {
          color: $text-color;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        p {
          color: $text-color-light;
          font-size: 1.1rem;
          margin-bottom: 0.5rem;
          line-height: 1.5;
        }

        .sub-message {
          font-size: 0.9rem;
          color: $text-color-light;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .job-card {
    position: relative;
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .job-card-header {
      margin-bottom: 1rem;

      .title-with-badge {
        display: flex;
        align-items: center;
        gap: 12px;

        h4 {
          font-size: 1.1rem;
          color: $text-color;
          margin: 0;
        }

        .status-badge {
          padding: 0.25rem 0.75rem;
          border-radius: 20px;
          font-size: 0.8rem;
          font-weight: 500;
          white-space: nowrap;
          height: fit-content;

          &.published {
            background-color: rgba($success-color, 0.1);
            color: $success-color;
          }

          &.draft {
            background-color: rgba($warning-color, 0.1);
            color: $warning-color;
          }
        }
      }
    }

    .job-card-content {
      flex: 1;

      .job-info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;

        .contract-type,
        .role-level {
          font-size: 0.9rem;
          color: $text-color-light;
          padding: 0.25rem 0.5rem;
          background-color: rgba($accent-color, 0.1);
          border-radius: 4px;
          white-space: nowrap;
          display: inline-block;
          width: fit-content;
        }

        .role-level {
          background-color: rgba(#808080, 0.1);
          color: var(--primary-color);
        }
      }

      .job-meta {
        font-size: 0.8rem;
        color: var(--secondary-color);
        margin-top: 0.5rem;

        .created-date {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }

    .job-card-actions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: auto;
      padding-top: 1rem;

      .action-buttons-row {
        display: flex;
        gap: 0.5rem;
        width: 100%;
      }

      .details-button,
      .edit-button,
      .delete-button {
        flex: 1;
        padding: 0.5rem;
        border-radius: 6px;
        font-size: 0.9rem;
        text-align: center;
        transition: all 0.2s ease;
      }

      .details-button {
        background-color: var(--primary-color);
        color: white;
        text-decoration: none;
        border: none;

        &:hover {
          background-color: #333333;
        }
      }

      .edit-button {
        background-color: transparent;
        border: 1px solid $border-color;
        color: $text-color;

        &:hover {
          background-color: rgba($background-color, 0.5);
        }
      }

      .delete-button {
        background-color: transparent;
        border: 1px solid $border-color;
        color: $danger-color;

        &:hover {
          background-color: rgba($danger-color, 0.1);
          border-color: $danger-color;
        }
      }

      .publish-button {
        width: 100%;
        padding: 0.75rem;
        border-radius: 6px;
        font-size: 0.9rem;
        text-align: center;
        transition: all 0.2s ease;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        margin-top: 0.5rem;

        // Default state (publish)
        background-color: $success-color;
        color: white;

        i {
          font-size: 0.9rem;
        }

        &:hover {
          background-color: darken($success-color, 10%);
        }

        // Unpublish state
        &.unpublish {
          background-color: $warning-color;

          &:hover {
            background-color: darken($warning-color, 10%);
          }
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
          background-color: $text-color-light;
        }
      }

      .close-button {
        width: 100%;
        padding: 0.75rem;
        border-radius: 6px;
        font-size: 0.9rem;
        text-align: center;
        transition: all 0.2s ease;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        margin-top: 0.5rem;
        background-color: #e74c3c;
        color: white;

        &.reopen {
          background-color: #95a5a6 !important;
        }

        i {
          margin-right: 0.5rem;
        }

        &:hover {
          filter: brightness(0.9);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }

      .ai-interview-button {
        width: 100%;
        padding: 0.75rem;
        border-radius: 6px;
        font-size: 0.9rem;
        text-align: center;
        transition: all 0.2s ease;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        margin-top: 0.5rem;
        background-color: var(--secondary-color);
        color: white;

        &.active {
          background-color: #95a5a6 !important;
          
          &:hover {
            background-color: #7f8c8d !important;
          }
        }

        i {
          font-size: 1.1rem;
        }

        &:hover {
          filter: brightness(0.9);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    .ai-modal {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      cursor: default;

      .ai-modal-content {
        position: relative;
        background: white;
        padding: 2rem;
        border-radius: 12px;
        width: 90%;
        max-width: 500px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        cursor: default;

        h3 {
          color: #333;
          margin-bottom: 1.5rem;
          font-size: 1.5rem;
          text-align: center;
        }

        select {
          width: 100%;
          padding: 12px;
          border: 1px solid #ddd;
          border-radius: 8px;
          margin-bottom: 1.5rem;
          font-size: 1rem;
          background-color: #f8f9fa;
          
          &:focus {
            outline: none;
            border-color: #007bff;
            box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
          }
        }

        .modal-actions {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          margin-top: 1rem;

          button {
            padding: 10px 20px;
            border-radius: 6px;
            font-weight: 500;
            transition: all 0.2s;
            
            &:first-child {
              background-color: #f8f9fa;
              border: 1px solid #ddd;
              color: #666;
              
              &:hover {
                background-color: #e9ecef;
              }
            }
            
            &:last-child {
              background-color: #007bff;
              border: none;
              color: white;
              
              &:hover {
                background-color: #0056b3;
              }
              
              &:disabled {
                background-color: #b3d7ff;
                cursor: not-allowed;
              }
            }
          }
        }

        .no-questionnaires {
          text-align: center;
          padding: 1rem;
          
          p {
            color: #666;
            margin-bottom: 1rem;
          }
          
          button {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border-radius: 6px;
            border: none;
            
            &:hover {
              background-color: #0056b3;
            }
          }
        }
      }
    }
  }

  .no-jobs-message {
    text-align: center;
    padding: 2rem;
    color: $text-color-light;
    background: rgba($background-color, 0.5);
    border-radius: 8px;
  }

  @include tablet {
    padding: 1.5rem;

    .search-filters {
      flex-direction: column;
      align-items: stretch;

      .search-box {
        margin-right: 0;
        margin-bottom: 15px;
      }

      .filter-container {
        width: 100%;
      }
    }

    .job-cards-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

  @include mobile {
    padding: 1rem;

    .search-filters {
      flex-direction: column;
      align-items: stretch;

      .search-box {
        margin-right: 0;
        margin-bottom: 15px;
      }

      .filter-container {
        width: 100%;
      }
    }

    .job-openings-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;

      .header-actions {
        flex-direction: column;
        gap: 0.5rem;

        .create-button,
        .ai-generate-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// Style for loading state
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  
  .spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ai-interview-section {
  margin: 10px 0;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  display: block; // Force display
  width: 100%; // Full width

  .selected-questionnaire {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background: white;
    border-radius: 4px;
    margin: 0;

    .questionnaire-name {
      font-size: 0.9em;
      color: #495057;
      font-weight: 500;
      margin-right: 10px;
    }

    .questionnaire-controls {
      display: flex;
      gap: 8px;
      margin-left: auto;

      button {
        padding: 4px 8px;
        background: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.edit-btn {
          color: #4a90e2;
          &:hover {
            background: rgba(74, 144, 226, 0.1);
          }
        }

        &.deactivate-btn {
          color: #dc3545;
          &:hover {
            background: rgba(220, 53, 69, 0.1);
          }
        }

        i {
          font-size: 14px;
        }
      }
    }
  }
}

// Update modal styles if needed
.modal-overlay {
  .modal-content {
    .no-questionnaires {
      text-align: center;
      padding: 20px;
      
      p {
        margin-bottom: 15px;
        color: #6c757d;
      }
      
      button {
        padding: 8px 16px;
        background-color: #6c757d;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        
        &:hover {
          background-color: #5a6268;
        }
      }
    }
  }
}

.ai-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .ai-modal-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

    h3 {
      color: #333;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      text-align: center;
    }

    .questionnaire-select {
      width: 100%;
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-bottom: 1.5rem;
      font-size: 1rem;
      background-color: #f8f9fa;
      
      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      button {
        padding: 10px 20px;
        border-radius: 6px;
        font-weight: 500;
        transition: all 0.2s;
        
        &.cancel-button {
          background-color: #f8f9fa;
          border: 1px solid #ddd;
          color: #666;
          
          &:hover {
            background-color: #e9ecef;
          }
        }
        
        &.confirm-button {
          background-color: #007bff;
          border: none;
          color: white;
          
          &:hover {
            background-color: #0056b3;
          }
          
          &:disabled {
            background-color: #b3d7ff;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
