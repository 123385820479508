@import '../../../Variables.scss';

.assessment-page {
  padding: 2rem;

  max-width: 1200px;
  margin: 0 auto;

  .start-section {
    text-align: center;
    margin: 4rem auto;
    max-width: 800px;

    h1 {
      margin-bottom: 2rem;
      color: #333;
      font-size: 2.2rem;
      font-weight: 600;
      background: linear-gradient(45deg, #6920b0, #4F46E5);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      white-space: nowrap;

      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      padding: 0 1rem; 
    }

    .email-instruction {
      margin: 2rem 0 3rem;
      color: #666;
      font-size: 1.1rem;
      line-height: 1.5;
      padding: 1.5rem;
      background: #f8f9fa;
      border-radius: 8px;
      border-left: 4px solid #6920b0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    .email-form {
      display: flex;
      gap: 1rem;
      justify-content: center;

      input {
        padding: 1rem 1.5rem;
        border: 2px solid #e2e8f0;
        border-radius: 8px;
        width: 350px;
        font-size: 1.1rem;
        transition: all 0.3s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        &:focus {
          outline: none;
          border-color: #6920b0;
          box-shadow: 0 0 0 3px rgba(105, 32, 176, 0.1);
        }

        &::placeholder {
          color: #94a3b8;
        }
      }

      .start-button {
        background: linear-gradient(45deg, #6920b0, #4F46E5);
        color: white;
        border: none;
        padding: 1rem 2rem;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1.1rem;
        font-weight: 500;
        transition: all 0.3s ease;
        box-shadow: 0 2px 4px rgba(105, 32, 176, 0.2);

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(105, 32, 176, 0.3);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }

  .questions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

    .assessment-section {
      margin-bottom: 30px;
      padding: 25px;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h2 {
        color: #333;
        border-bottom: 2px solid #6920b0;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
      }

      .question {
        margin-bottom: 25px;
        padding: 15px;
        border: 1px solid #dee2e6;
        border-radius: 6px;
        background: #f8f9fa;

        h3 {
          color: #6920b0;
          margin-bottom: 1rem;
          font-size: 1.1rem;
        }

        .question-content {
          margin-bottom: 1rem;
          line-height: 1.5;
          color: #333;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .option {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 8px 12px;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
              background: #f0f0f0;
            }

            input[type="radio"] {
              cursor: pointer;
            }
          }
        }
      }

      .passage {
        margin-bottom: 25px;
        padding: 20px;
        background-color: #f8f9fa;
        border: 1px solid #dee2e6;
        border-radius: 6px;
        line-height: 1.6;
        color: #333;
      }

      .writing-task, .scenario-task {
        p {
          margin-bottom: 1rem;
          line-height: 1.6;
          color: #333;
        }

        .word-limit {
          color: #666;
          font-style: italic;
          margin-bottom: 1rem;
        }

        textarea {
          width: 100%;
          min-height: 200px;
          padding: 15px;
          border: 1px solid #dee2e6;
          border-radius: 6px;
          font-size: 1rem;
          line-height: 1.5;
          resize: vertical;
          transition: border-color 0.2s;

          &:focus {
            outline: none;
            border-color: #6920b0;
            box-shadow: 0 0 0 2px rgba(105, 32, 176, 0.1);
          }

          &::placeholder {
            color: #adb5bd;
          }
        }

        .article-textarea, .scenario-textarea {
          margin-top: 1rem;
        }
      }

      .code-snippet {
        background: #1e1e1e;
        color: #d4d4d4;
        padding: 1rem;
        border-radius: 4px;
        margin: 1rem 0;
        overflow-x: auto;
        font-family: 'Consolas', 'Monaco', monospace;
      }

      .code-textarea {
        width: 100%;
        min-height: 200px;
        padding: 1rem;
        background: #1e1e1e;
        color: #d4d4d4;
        border: 1px solid #404040;
        border-radius: 4px;
        font-family: 'Consolas', 'Monaco', monospace;
        resize: vertical;
        margin-top: 1rem;

        &:focus {
          outline: none;
          border-color: #6920b0;
          box-shadow: 0 0 0 2px rgba(105, 32, 176, 0.1);
        }

        &::placeholder {
          color: #666;
        }
      }
    }
  }

  .section-title {
    color: #333;
    border-bottom: 2px solid #6920b0;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .submit-section {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;

    .submit-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      background: #4F46E5;
      color: white;
      border: none;
      border-radius: 8px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #4338CA;
        transform: translateY(-1px);
      }

      &:disabled {
        background: #9ca3af;
        cursor: not-allowed;
        transform: none;
      }

      .icon {
        font-size: 1.2rem;
      }
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal-content {
      background: white;
      padding: 2rem;
      border-radius: 8px;
      text-align: center;
      max-width: 500px;
      width: 90%;

      h2 {
        color: #333;
        margin-bottom: 1rem;
      }

      p {
        color: #666;
        line-height: 1.6;
      }
    }
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    padding: 3rem;
  }

  .error-message {
    color: #dc3545;
    text-align: center;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    margin: 2rem 0;
  }

  .success-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal-content {
      background: white;
      padding: 2.5rem;
      border-radius: 8px;
      text-align: center;
      max-width: 500px;
      width: 90%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      h2 {
        color: #333;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
      }

      p {
        color: #666;
        line-height: 1.6;
        margin-bottom: 2rem;
      }

      .close-button {
        background: #6920b0;
        color: white;
        border: none;
        padding: 0.75rem 2rem;
        border-radius: 4px;
        font-size: 1.1rem;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background: #551a8b;
        }
      }
    }
  }

  .assessment-content {
    .assessment-header {
      background: white;
      padding: 2rem;
      margin-bottom: 2rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h2 {
        color: #333;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #6920b0;
        padding-bottom: 0.5rem;
      }

      .assessment-description {
        color: #666;
        line-height: 1.6;
        margin-bottom: 1rem;
      }

      .time-limit {
        color: #6920b0;
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
  }
}
