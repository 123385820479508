@import "../../Variables.scss";

.candidate-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.candidate-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}
