@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.job-offer-details {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  h2 {
    font-size: 2em;
    color:  var(--primary-color);
    margin-bottom: 20px;
  }

  .job-offer-section {
    background-color: lighten($accent-color, 15%);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;

    h3 {
      font-size: 1.5em;
      color: var(--secondary-color);
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 10px;
    }

    strong {
      font-weight: bold;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;
    }
  }

  .back-button {
    display: inline-block;
    background-color:  var(--primary-color);
    color: $background-color;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333333;
    }
  }

  @include tablet {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }

    .job-offer-section {
      h3 {
        font-size: 1.3em;
      }
    }
  }

  @include mobile {
    padding: 20px 10px;

    h2 {
      font-size: 1.6em;
    }

    .job-offer-section {
      padding: 15px;

      h3 {
        font-size: 1.2em;
      }
    }
  }
}

