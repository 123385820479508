@import '../../components/Variables.scss';
@import '/src/styles/breakpoints.scss';

.payment-blocked-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 20rem;

  &-title {
    color: var(--primary-color);
  }


  &-content {
    width: 50%;
    height: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &-button {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 50%;
    margin: 0 auto;
  }

}