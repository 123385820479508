@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.structured-interviews-list {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      color: var(--primary-color);
      margin: 0;
    }

    .header-buttons {
      display: flex;
      gap: 1rem;
      align-items: center;

      .generate-ai-button {
        padding: 0.75rem 1.5rem;
        background: var(--secondary-color);
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &:hover {
          opacity: 0.9;
          transform: translateY(-2px);
        }

        &::before {
          content: "🤖"; // AI icon
          font-size: 1.2em;
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid var(--border-color);
    }

    th {
      background: var(--primary-color);
      color: white;
      font-weight: 500;
    }

    tr:hover {
      background: var(--hover-color);
    }

    .view-profile-link {
      color: var(--primary-color);
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }

    .action-select {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      background: white;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: var(--primary-color);
      }
    }
  }
}

.delete-confirmation-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .delete-confirmation-dialog {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    text-align: center;

    p {
      margin-bottom: 1.5rem;
    }

    .dialog-actions {
      display: flex;
      gap: 1rem;
      justify-content: center;

      button {
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;

        &.cancel-button {
          background: var(--background-secondary);
          color: var(--text-color);
        }

        &.delete-button {
          background: var(--error-color);
          color: white;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

@include mobile {
  .structured-interviews-list {
    padding: 1rem;

    table {
      font-size: 0.9rem;

      th, td {
        padding: 0.75rem;
      }
    }
  }
} 