@import "../../Variables.scss";
@import "/src/styles/breakpoints.scss";

.tracking-board {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  // background-color: $background-color;
  padding: 20px;
  border-radius: 10px;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100vh;
  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  // }

  .tracking-board-title {
    text-align: center;
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 30px;
  }

  .boards-request-list {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $text-color;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 0.9rem;
      background: var(--primary-color);
      padding: 8px 12px;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }
    }

    .card_Btn {
      margin-right: 10px;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.2rem;
      color: $error-color;
      transition: transform 0.3s ease, color 0.3s ease;

      &:hover {
        transform: scale(1.1);
        color: darken($error-color, 15%);
      }
    }
  }

  .see-more-boards {
    .see-more-boards-button {
      display: block;
      margin: 20px auto;
      padding: 10px 20px;
      background: var(--primary-color);
      color: white;
      font-size: 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  }
}
