@import '../../Variables.scss';
@import '/src/styles/breakpoints.scss';

.support-ticket-details {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
  background-color: $background-color;
  padding: 40px 20px;

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h2 {
      font-size: 2em;
      color:  var(--primary-color);
      margin: 0;
    }

    .back-button {
      background-color: var(--secondary-color);
      color: $background-color;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #333333;
      }
    }
  }

  .ticket-content {
    background-color: lighten($accent-color, 15%);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .ticket-header {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;

      .ticket-id {
        font-size: 1.2em;
        font-weight: bold;
        color:  var(--primary-color);
      }

      .status-badge,
      .priority-badge {
        padding: 5px 15px;
        border-radius: 15px;
        font-size: 0.9em;
        min-width: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .status-badge {
        &.open { background-color: #e3f2fd; color: #1976d2; }
        &.inprogress { background-color: #fff3e0; color: #f57c00; }
        &.resolved { background-color: #e8f5e9; color: #388e3c; }
        &.closed { background-color: #f5f5f5; color: #616161; }
      }

      .priority-badge {
        &.high { background-color: #ffebee; color: #c62828; }
        &.medium { background-color: #fff3e0; color: #f57c00; }
        &.low { background-color: #e8f5e9; color: #388e3c; }
      }
    }

    .ticket-info {
      margin-bottom: 30px;

      h3 {
        font-size: 1.5em;
        margin-bottom: 20px;
        color:  var(--primary-color);
      }

      .metadata {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: $text-color-light;
        font-size: 0.9em;
        margin-top: 15px;

        span {
          display: block;
          white-space: nowrap;
          line-height: 1.6;
          padding: 5px 0;
          margin-right: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .ticket-description {
      margin-bottom: 30px;

      h4 {
        color:  var(--primary-color);
        margin-bottom: 10px;
      }

      p {
        line-height: 1.6;
      }
    }

    .ticket-attachments {
      margin-bottom: 30px;

      h4 {
        color:  var(--primary-color);
        margin-bottom: 10px;
      }

      .attached-files {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .file-item {
          background-color: $background-color;
          padding: 8px 15px;
          border-radius: 5px;

          a {
            color:  var(--primary-color);
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .ticket-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .delete-ticket-btn {
        background-color: #c62828;
        color: $background-color;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken(#c62828, 10%);
        }
        margin-right: 10px;
      }

      .close-ticket-btn {
        background-color: #c62828;
        color: $background-color;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken(#c62828, 10%);
        }
      }

      .disabled-btn {
        background-color: #ccc; /* Grey background */
        color: #666; /* Grey text */
        cursor: not-allowed;
        pointer-events: none; /* Disable click events */
        opacity: 0.6; /* Dim the button */
      }
    }
  }

  .confirm-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .confirm-content {
      background-color: $background-color;
      padding: 30px;
      border-radius: 8px;
      text-align: center;

      p {
        margin-bottom: 20px;
      }

      .confirm-actions {
        display: flex;
        justify-content: center;
        gap: 15px;

        button {
          padding: 8px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &.confirm-yes {
            background-color: #c62828;
            color: $background-color;

            &:hover {
              background-color: darken(#c62828, 10%);
            }
          }

          &.confirm-no {
            background-color: $accent-color;
            color: $text-color;

            &:hover {
              background-color: darken($accent-color, 10%);
            }
          }
        }
      }
    }
  }

  @include tablet {
    padding: 30px 15px;

    .header-section h2 {
      font-size: 2em;
    }
  }
}
